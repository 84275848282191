export const URLS = {
  ROOT: {
    BASE_URL: `${process.env.REACT_APP_MAGAI_APP_BASE_API_URL}`,
    WS_URL: `${process.env.REACT_APP_MAGAI_APP_WS_API_URL}`,
  },
  AUTH: {
    //POST
    LOGIN: "/auth/token",
    //POST
    SOCIAL_LOGIN: "/auth/convert-token/",
    //GET
    PROFILE: "/main/user/profile/",
    //POST
    SEND_VERIFICATION_EMAIL: "/main/send-verification-email/",
    //POST
    VERIFY_EMAIL: "/main/verify-email/",
  },
  SUBSCRIPTION_PLAN: {
    //GET
    INDEX: "/subscriptions/subscription-plans/",
    //POST
    CREATE: "/subscriptions/subscription-plans/",
    //POST
    UPDATE: (id) => `/subscriptions/subscription-plans/${id}/`,
    //GET
    SUPPORT_PRIORITY_TYPE_CHOICES: "/subscriptions/support-priority-type-choices/",
    //GET
    BILLING_INTERVAL_TYPE_CHOICES: "/subscriptions/billing-interval-choices/",
    //POST
    PRICING_CREATE: "/subscriptions/subscription-plan-pricings/",
    // POST
    GET_DEFAULT_PLAN: "/subscriptions/subscription-plans/default/",
    //PATCH
    PRICING_UPDATE: (id) => `/subscriptions/subscription-plan-pricings/${id}`,
    //GET
    PRICING_INDEX: "/subscriptions/subscription-plan-pricings/",
    // POST
    SUBSCRIBE_TO_PLAN: "/subscriptions/subscribe/",
    //GET
    SUBSCRIPTION_LIST_FILTERED: (billing_interval) =>
      "/subscriptions/subscription-plan-pricings/?billing_interval=" + billing_interval,
    //PATCH
    MAKE_AS_DEFAULT: (id) => `/subscriptions/subscription-plans/${id}/set-default/`,
    //DELETE
    DELETE: (id) => `/subscriptions/subscription-plans/${id}/`,
  },
  PAYMENT: {
    INVOICES: "/payment/invoices/",
    INVOICE_TEMPLATE: (id) => `/payment/invoices/${id}/email/`,
  },
  WORKSPACE: {
    //GET
    INDEX: "/ai/workspaces/",
    //POST
    CREATE: "/ai/workspaces/create/",
    //GET
    DETAILS: (id) => `/ai/workspaces/${id}/`,
    //PATCH
    UPDATE: (id) => `/ai/workspaces/${id}/`,
  },
  CHATBOT: {
    //GET
    INDEX: (workspaceId) => `/ai/workspaces/${workspaceId}/brains/`,
    //POST
    CREATE: "/ai/brains/",
    //GET
    DETAILS: (brainId) => `/ai/brains/${brainId}/`,
    //PATCH
    UPDATE: (brainId) => `/ai/brains/${brainId}/`,
    //DELETE
    DELETE: (brainId) => `/ai/brains/${brainId}/`,
    // PATCH
    INTEGRATION_SETTINGS: (brainId, type) => `/ai/brains/${brainId}/${type}-settings/`,

    TRAIN: {
      //GET
      INDEX: `/files/documents/`,

      //POST
      UPLOAD_DOC: "/files/documents/upload/",
      //POST
      UPLOAD_WEB: "/files/website/upload/",
      //DELETE
      DELETE_UPLOAD_CONTENT: (docId) => `/files/documents/${docId}/`,
      //POST
      WEBPAGE_CHARACTERS: "/files/get_webpage_characters/",
      //POST
      DOC_SYNC: "/files/documents/sync/",
      //POST
      BATCH_WEBPAGE_UPLOAD: "/files/batch_webpage_process/",
      //GET
      EXTERNAL_SOURCE_LIST:"/ai/get-external-source-list/",
      //POST
      ADD_EXTERNAL_SOURCE:"/ai/brain-external-sources/",
      //PATCH
      UPDATE_EXTERNAL_SOURCE:(externalSourceId) => `/ai/brain-external-sources/${externalSourceId}/`, 
      //DELETE
      DELETE_EXTERNAL_SOURCE:(externalSourceId) => `/ai/brain-external-sources/${externalSourceId}/`, 
      //GET
      EXTERNAL_SOURCE_INDEX:"/ai/brain-external-sources/",
      

    },
    CONVERSATION: {
      //GET
      CHAT_INDEX: `ai/chats/`,
      //POST
      ASK_QUESTION: (chatId) => `/ai/chats/${chatId}/question`,
      //GET
      CHAT_HISTORY: (chatId) => `/ai/chatmessages/?chat_id=${chatId}`,
      //PATCH
      UPDATE_CONVERSATION: (conversationId) => `/chat_widget/chatmessages/${conversationId}/`,
    
      
    },
    PUBLISH: {
      //POST
      WIDGET: `/ai/brains/publish/`,
    },
    SETTINGS: {
      //GET
      CHAT_SETTINGS: (brainId) => `/ai/brains/${brainId}/chat-settings/`,
      //GET
      AI_MODEL_LIST: `/ai/dropdown/supported-model-list/`,
      //PATCH
      REFRESH_API_KEY: (brainId) => `/ai/brains/${brainId}/magnus-ai-api-key/`,
    },
    LEAD: {
      //GET
      LEAD_FIELD_LIST: (brainId) => `/chat_widget/capturefields/?brain_id=${brainId}`,
      //CREATE
      LEAD_FIELD_CREATE: `/chat_widget/capturefields/`,
      //GET
      LEAD_FIELD_DETAILS: (fieldId) => `/chat_widget/capturefields/${fieldId}/`,
      //PATCH
      LEAD_FIELD_UPDATE: (fieldId) => `/chat_widget/capturefields/${fieldId}/`,
      //DELETE
      LEAD_FIELD_DELETE: (fieldId) => `/chat_widget/capturefields/${fieldId}/`,
      //GET
      LEAD_CAPTURE_DATA_LIST:(brainId)=>`/chat_widget/get_capture_data/?brain_id=${brainId}`
    },
    INTEGRATE: {
      //POST
      ZAPIER: (brainId) => `/ai/brains/${brainId}/test-zap/`,
    },
    LOGS:{
      //GET
      INDEX: `/main/admin-notification/`,
    }
  },
};
