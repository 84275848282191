import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Col,
  UserAvatar,
} from "../../../components/Component";
import { useSelector } from "react-redux";
import { getWorkspaceData } from "../../../redux";
import { httpClient } from "../../../services";
import { LOCAL_STORAGE_ITEMS, TOAST, URLS } from "../../../constants";
import { notify } from "../../../services/toastService";
import SettingsInput from "../../../components/input/SettingsInput";

import { Card, Label, TabContent, TabPane } from "reactstrap";
import { findUpper, consoleLog } from "../../../utils/Utils";

const Settings = () => {
  const TAB_LIST = ["api_key", "workspace", "domain"];
  const workspaceInfo = useSelector(getWorkspaceData);
  const [workspaceDetails, setWorkspaceDetails] = useState(workspaceInfo);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  let tabValue = urlParams.get("tab") === null ? TAB_LIST[0] : urlParams.get("tab").toString();
  useEffect(() => {
    setActiveTab(tabValue);
  }, [tabValue]);
  const [activeTab, setActiveTab] = useState(tabValue);

  // useEffect(() => {
  //   if (urlParams.get('tab') === null) {
  //     // window.location.href = `${window.location.origin}/settings?tab=${activeTab}`
  //     // history.pushState({}, null, `${window.location.origin}/settings?tab=${activeTab}`);
  //   }
  // }, [activeTab])

  useEffect(() => {
    httpClient.get(URLS.WORKSPACE.DETAILS(workspaceInfo.id)).then((res) => setWorkspaceDetails(res.data));
  }, []);

  const updateFormData = (key, value) => {
    setWorkspaceDetails((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    updateFormData(name, value);
  };

  function onhandleSave(key, callBack) {
    if (loading) return;
    setLoading(true);
    const url = URLS.WORKSPACE.UPDATE(workspaceDetails.id);
    const saveFormData = new FormData();
    saveFormData.append(key, workspaceDetails[key]);
    httpClient
      .patch(url, saveFormData)
      .then((res) => {
        localStorage.setItem(LOCAL_STORAGE_ITEMS.WORKSPACE, res.data ? JSON.stringify(res.data) : null);
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        setTimeout(() => {
          if (key === "name") {
            window.location.reload();
          }
        }, 1000);
        callBack();
      })
      .catch((err) => {
        consoleLog("settings-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => setLoading(false));
  }
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span></span>
          </div>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="page-title">Settings</BlockTitle>
              <BlockDes></BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            {/* <Nav tabs className="nav-tabs-s1 px-4">
              <NavItem>
                <NavLink tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === TAB_LIST[0] })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleTab(TAB_LIST[0]);
                  }}>API Keys</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === TAB_LIST[1] })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleTab(TAB_LIST[1]);
                  }}>Workspace</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === TAB_LIST[2] })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleTab(TAB_LIST[2]);
                  }}>Domain</NavLink>
              </NavItem>
            </Nav> */}
            <div className="card-inner">
              <TabContent activeTab={activeTab} className="mt-0">
                <TabPane tabId={TAB_LIST[0]}>
                  <Col lg={12}>
                    <SettingsInput
                      title={"OpenAI API Key"}
                      subTitle={
                        <p className="text-muted sub-text-sm">
                          Click{" "}
                          <span className="text-primary fw-medium">
                            <u>
                              <a href="https://beta.openai.com/account/api-keys" target="_blank" rel="noreferrer">
                                here
                              </a>
                            </u>
                          </span>{" "}
                          to get your API key.
                        </p>
                      }
                      input_name={"open_ai_api_key"}
                      input_placeholder={"sk-xxxxxxxxxxxxxxxx"}
                      input_value={workspaceDetails.open_ai_api_key}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("open_ai_api_key", callBack);
                      }}
                    />
                    <p className="text-xs text-gray-500 -mt-3">
                      {" "}
                      You can provide your own OpenAI API key e.g. if you need more monthly GPT-4 messages or because to
                      your company policies.
                    </p>
                    {/* <SettingsInput
                      title={"Open Router API Key"}
                      input_name={"open_router_api_key"}
                      input_placeholder={'sk-xxxxxxxxxxxxxxxx'}
                      input_value={workspaceDetails.open_router_api_key}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("open_router_api_key", callBack);
                      }}
                    />
                    <SettingsInput
                      title={"Claude API Key"}
                      input_name={"claude_api_key"}
                      input_placeholder={'sk-xxxxxxxxxxxxxxxx'}
                      input_value={workspaceDetails.claude_api_key}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("claude_api_key", callBack);
                      }}
                    /> */}
                  </Col>
                </TabPane>
                <TabPane tabId={TAB_LIST[1]}>
                  <Col lg={12}>
                    <SettingsInput
                      title={"Name"}
                      subTitle={
                        <p className="text-muted sub-text-sm">Manage your workspace and invite collaborators.</p>
                      }
                      input_name={"name"}
                      input_placeholder={"sk-xxxxxxxxxxxxxxxx"}
                      input_value={workspaceDetails.name}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("name", callBack);
                      }}
                    />

                    <SettingsInput
                      title={"Widget Url"}
                      subTitle={<p className="text-muted sub-text-sm">Enter widget url.</p>}
                      input_name={"chat_script_widget_url"}
                      input_placeholder={"https://widget.magnushub.ai"}
                      input_value={workspaceDetails.chat_script_widget_url}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("chat_script_widget_url", callBack);
                      }}
                    />

                    <div className="my-2">
                      <Label htmlFor="default-0" className="form-label">
                        Members
                      </Label>

                      <div className="d-flex justify-content-between my-2 ">
                        <div className="user-card">
                          <UserAvatar
                            theme="purple"
                            text={findUpper(
                              `${workspaceDetails?.user?.first_name} ${workspaceDetails?.user?.last_name}`
                            )}
                          />
                          <div className="user-info">
                            <span className="lead-text">{`${workspaceDetails?.user?.first_name} ${workspaceDetails?.user?.last_name}`}</span>
                            <span className="sub-text">{workspaceDetails?.user?.user_type_display_text}</span>
                          </div>
                        </div>
                        <div>
                          <p>{}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </TabPane>
                <TabPane tabId={TAB_LIST[2]}>
                  <Col lg={12}>
                    <SettingsInput
                      title={"Domain Name"}
                      subTitle={
                        <p className="text-muted sub-text-sm">
                          Instead of using your main domain (eg: <strong>yourdomain.com</strong>),We strongly recommend
                          using a subdomain (eg: <strong>ai.yourdomain.com</strong> or{" "}
                          <strong>chat.yourdomain.com</strong>)
                        </p>
                      }
                      input_name={"custom_domain"}
                      input_placeholder={"xyz.example.com"}
                      input_value={workspaceDetails.custom_domain}
                      onChange={handleInputChange}
                      onSubmit={(callBack) => {
                        onhandleSave("custom_domain", callBack);
                      }}
                    />
                  </Col>
                </TabPane>
              </TabContent>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Settings;
