import React, { useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../../../components/Component";
import Emitter from "../../../../services/eventEmitterService";
import { ChatbotsConfig } from "../../../../config/chatbots.config";
import { XTable, XTableRow, XTableRowItem } from "../../../../components/xtable";
import { Card, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchData, getData, getWorkspaceData, store } from "../../../../redux";
import { TOAST, URLS } from "../../../../constants";
import AddEditChatbot from "./AddEditChatbot";
import { httpClient } from "../../../../services";
import { notify } from "../../../../services/toastService";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../../utils/Utils";

const ChatbotList = () => {
  const navigate = useNavigate();
  const resData = useSelector(getData);
  const workspaceId = useSelector(getWorkspaceData).id;
  const [isListView, setIsListView] = useState(true);
  const tableHeaders = [
    { text: "Name", width: "10%", sort: null },
    { text: "Description", width: "40%", sort: null },
    { text: "Action", width: "10%", sort: null },
  ];
  function refreshPage() {
    store.dispatch(fetchData());
  }
  function add() {
    Emitter.emit(ChatbotsConfig.events.editingData, {});
    dispatchEvent(new Event(ChatbotsConfig.events.sorToggle));
  }

  function edit(data) {
    Emitter.emit(ChatbotsConfig.events.editingData, data);
    dispatchEvent(new Event(ChatbotsConfig.events.sorToggle));
  }

  function chatbotDelete(id) {
    httpClient
      .delete(URLS.CHATBOT.DELETE(id))
      .then((res) => {
        consoleLog("chatbot-delete-res", res);

        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        refreshPage();
      })
      .catch((err) => {
        consoleLog("chatbot-delete-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }
  return (
    <React.Fragment>
      <AddEditChatbot />
      <Head title="Chatbots" />
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent className="d-flex align-items-end justify-content-between g-3">
            <BlockTitle>Chatbots</BlockTitle>
            {/* <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className="toggle btn-icon "
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  add();
                }}
              >
                <Icon name="plus"></Icon>
              </Button>
            </div> */}
            <div className="d-flex flex-row">
              <div
                style={{
                  marginRight: 20,
                }}
              >
                <label className="react-switch-2">
                  <input
                    checked={isListView}
                    onChange={(_) => {
                      consoleLog("sumittign", isListView);
                      setIsListView((l) => !l);
                    }}
                    className="react-switch-checkbox"
                    type="checkbox"
                  />
                  <div className="react-switch-button-2 align-center justify-center">
                    <Icon
                      name={isListView ? "list" : "grid"}
                      className={isListView ? "fs-6" : "fs-14px"}
                      style={{ color: "white" }}
                    />
                  </div>
                  <div className="react-switch-labels">
                    <span className="swicth-txt">
                      <Icon name="grid" className="fs-14px swicth-txt" />
                    </span>
                    <span className="swicth-txt">
                      <Icon name="list" className="fs-6 swicth-txt" />
                    </span>
                  </div>
                </label>
              </div>
              <div className="sor-footer d-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    add();
                  }}
                  className="sor-action-button position-relative"
                  form="chatbot-addedit-form"
                >
                  Add
                </Button>
              </div>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <XTable xtId="chatbots" headers={tableHeaders} endpoint={URLS.CHATBOT.INDEX(workspaceId)}>
            {isListView ? (
              resData &&
              resData.map((item) => {
                return (
                  <XTableRow key={item.id}>
                    <XTableRowItem>
                      <Link to={`${process.env.PUBLIC_URL}/chats/${item.id}/train`} className="link">
                        <span>{item.name}</span>
                      </Link>
                    </XTableRowItem>
                    <XTableRowItem>
                      <span className="bot-description" style={{ maxWidth: "40vw" }}>
                        {item.description}
                      </span>
                    </XTableRowItem>
                    <XTableRowItem className="flex items-center justify-start">
                      <Button
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`${process.env.PUBLIC_URL}/chats/${item.id}/train`);
                        }}
                        className="sor-action-button position-relative"
                        form="chatbot-addedit-form"
                      >
                        Configure
                      </Button>
                      <div className="">
                        <UncontrolledDropdown>
                          <DropdownToggle className="bg-transparent border-0">
                            <Icon name="more-h" className="text-dark"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    edit(item);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    chatbotDelete(item.id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      {/* <div>
                        <Link to={`${process.env.PUBLIC_URL}/chats/${item.id}/train`} className="link">
                          <Icon name="arrow-right-round-fill"></Icon>
                        </Link>
                      </div> */}
                    </XTableRowItem>
                  </XTableRow>
                );
              })
            ) : (
              <div className="d-flex flex-row">
                {resData &&
                  resData.map((item) => {
                    return (
                      <Col key={item.id} style={{ marginTop: 10 }}>
                        <Card className="bot-card" style={{ paddingBottom: 20, marginRight: 20 }}>
                          <div className="p-4">
                            <h4 className="card-title title">{item.name}</h4>
                            <p style={{ height: 80 }} className="bot-description fw-normal">
                              {item.description}
                            </p>
                          </div>
                          <div className="d-flex flex-row">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`${process.env.PUBLIC_URL}/chats/${item.id}/train`);
                              }}
                              className={"btn btn-primary btn-block ms-2"}
                            >
                              Configure
                            </button>
                            <div>
                              <UncontrolledDropdown>
                                <DropdownToggle className="bg-transparent border-0" style={{ paddingLeft: 10 }}>
                                  <Icon name="more-v" className="text-dark"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        Edit
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          chatbotDelete(item.id);
                                        }}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </div>
            )}
          </XTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ChatbotList;
