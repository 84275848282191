export const XTABLE = {
  STORE: {
    XTID: "xtId",
    ENDPOINT: "endpoint",

    QSO: {
      KEY: "qso",
      VALUE: {
        PREPEND: "prepend",
        APPEND: "append",
      },
    },
    QSC: {
      KEY: "qsc",
      VALUE: {
        PAGE: "page",
        LIMIT: "limit",
        OFFSET: "offset",
        SEARCH: "search",
      },
    },
    REQUEST: {
      KEY: "req",
      VALUE: {
        BUSY: "busy",
      },
    },
    RESPONSE: {
      KEY: "res",
      VALUE: {
        DATA: "data",
        META: "meta",
      },
    },
    RESET_ON: "resetOn",
    RESET_STATUS: "resetStatus",
    QUERY: "query",
  },
};
