import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import "./assets/scss/index.scss";
import { Providers } from "./redux/providers";

import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Providers>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_PROVIDER__CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </Providers>
    </BrowserRouter>
  </>
);
reportWebVitals();
