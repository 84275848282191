
export const AUTH = {
  STORE: {
    LOADED: "loaded",
    LOGGED_IN: "loggedIn",
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    USER: "user"
  }
}
