import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { formBody, httpClient } from "../../../services";
import { URLS } from "../../../constants";

const InstallationChatbotSnippet = () => {
  const { brainId } = useParams();
  const [popupChatWidgetWebsite, setPopupChatWidgetWebsite] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copied, setCopied] = useState(false);

  const contentData = [
    {
      id: 1,
      type: "JavaScript",
      title: "Javascript code snippet",
      subtitle:
        "Embed this code snippet in the head section of your website to display your chatbot as a floating icon.",
      code: `<script src="${popupChatWidgetWebsite}" defer ></script>`,
    },
    {
      id: 2,
      type: "iframe",
      title: "iframe code snippet",
      subtitle: "Paste this code snippet where you want to display your chatbot as an embedded widget.",
      code: `<iframe src="${process.env.REACT_APP_MAGAI_WIDGET_URL}/?brainId=${brainId}" width="100%" height="500"></iframe>`,
    },
    {
      id: 3,
      type: "Link",
      title: "Chatbot URL",
      subtitle: "You can simply share your chatbot using a link.",
      code: `${process.env.REACT_APP_MAGAI_WIDGET_URL}/?brainId=${brainId}`,
    },
    {
      id: 4,
      type: "DD Link (Beta)",
      title: "Data-Driven Chatbot URL",
      subtitle: "You can simply share your chatbot using a link.",
      code: `${process.env.REACT_APP_MAGAI_WIDGET_CSV_URL}/?brainId=${brainId}&csv`,
    },
  ];

  const handleNavButtonClick = (index) => {
    setCurrentIndex(index);
    setCopied(false); // Reset the copied state when switching content
  };

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
      })
      .catch((error) => {
        console.error("Unable to copy code to clipboard", error);
      });
  };

  useEffect(() => {
    if (brainId) {
      httpClient.post(URLS.CHATBOT.PUBLISH.WIDGET, formBody({ brain: brainId })).then((res) => {
        setPopupChatWidgetWebsite(res.data.publish_url);
      });
    }
  }, [brainId]);

  const highlightKeywords = (code) => {
    // Split the code into parts based on keywords
    const parts = code.split(/(<script|<\/script>|<iframe|<\/iframe>|src|width|height)/g);
    return parts.map((part, index) => {
      switch (part) {
        case "<script":
        case "</script>":
        case "<iframe":
        case "</iframe>":
          return (
            <span key={index} style={{ color: "blue" }}>
              {part}
            </span>
          );
        case "src":
        case "width":
        case "height":
          return (
            <span key={index} style={{ color: "green" }}>
              {part}
            </span>
          );
        default:
          return part;
      }
    });
  };

  return (
    <div>
      <p className="mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
        Install chatbot
      </p>
      <p className="text-xs text-gray-500 mb-6">
        You can install your chatbot as a widget by copying and pasting the code below into the head section of your
        website or as an iframe where it should be displayed.
      </p>
      <header className="bg-gray-200 p-1" style={{ borderRadius: "5px" }}>
        <nav>
          <div className="flex">
            {contentData.map((content, index) => (
              <button
                key={content.id}
                onClick={() => handleNavButtonClick(index)}
                className={`py-2 px-4 rounded ${
                  index === currentIndex ? "bg-white text-black shadow" : ""
                } w-full md:w-1/3 `}
              >
                {content.type}
              </button>
            ))}
          </div>
        </nav>
      </header>

      <div style={{ width: "100%", margin: "auto", overflow: "hidden", minHeight: "80vh" }}>
        {contentData.map((content, index) => (
          <div
            key={content.id}
            style={{
              display: index === currentIndex ? "block" : "none",
              boxSizing: "border-box",
              paddingTop: "20px",
              marginTop: "10px",
              paddingBottom: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid #ccc",
              borderRadius: "10px",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <p className="mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
              {content.title}
            </p>
            <p className="text-xs text-gray-500 ">{content.subtitle}</p>
            <div
              className="text-left mb-4"
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                minHeight: "100px",
                overflowY: "auto",
                color: "purple",
              }}
            >
              {highlightKeywords(content.code)}
            </div>
            <button
              className="bg-gray-200 text-black px-2 py-1 rounded"
              onClick={() => handleCopy(content.code)}
              style={{ position: "absolute", bottom: "20px", right: "18px" }}
            >
              {copied ? "✅ Copied!" : "Copy Code"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstallationChatbotSnippet;
