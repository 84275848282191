import React, { useEffect, useState } from "react";
import { Button, Icon } from "../../../../../../components/Component";

import { useForm } from "react-hook-form";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { TrainConfig } from "../../../../../../config/train.config";
import { getTrainWebModel } from "./helper";
import { TOAST, URLS } from "../../../../../../constants";
import { useParams } from "react-router";
import { formBody, formatNumber, httpClient } from "../../../../../../services";
import { notify } from "../../../../../../services/toastService";
import { Card, Spinner } from "reactstrap";
import { fetchData, store } from "../../../../../../redux";
import { httpClientConfig } from "../../../../../../config/httpClient.config";
import { consoleLog } from "../../../../../../utils/Utils";

const AddEditTrainWeb = () => {
  const [sorId, setSorId] = useState(TrainConfig.events.web.sorId);
  const [isPullLoading, setPullLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNoteShow, setNoteShow] = useState(false);
  const [pulledWebsites, setPulledWebsites] = useState([]);
  const [formData, setFormData] = useState(getTrainWebModel);

  const { brainId } = useParams();

  useEffect(() => {
    if (formData.web_url && formData.web_url.length > 0) {
      setNoteShow(false);
    } else {
      setNoteShow(true);
    }
  }, [formData.web_url]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function close() {
    resetData();
    dispatchEvent(new Event(TrainConfig.events.web.sorToggle));
    consoleLog("close");
  }

  function resetData() {
    setFormData(getTrainWebModel);
    reset(getTrainWebModel);
    setPulledWebsites([]);
  }

  async function onFormSubmit() {
    setLoading(true);
    const batchWebPagesLink = pulledWebsites.map((item) => item.web_page_url);
    const trainWebUploadUrl = URLS.CHATBOT.TRAIN.BATCH_WEBPAGE_UPLOAD;
    const trainWebUploadMethod = "POST";
    const trainWebUploadFormData = {
      brain_id: `${brainId}`,
      website_urls: batchWebPagesLink,
    };
    const modifiedHeaders = {
      ...httpClientConfig.options.headers,
      "Content-Type": "application/json", // Change the Content-Type for this specific request
    };
    await httpClient({
      method: trainWebUploadMethod,
      url: trainWebUploadUrl,
      data: JSON.stringify(trainWebUploadFormData),
      headers: modifiedHeaders,
    })
      .then((res) => {
        consoleLog("train-upload-web-res", res);
        store.dispatch(fetchData());
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("train-upload-web-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData(name, value);
  };

  function handlePullWebpageCharacters() {
    if (formData.web_url.length > 0) {
      setPullLoading(true);
      httpClient
        .post(URLS.CHATBOT.TRAIN.WEBPAGE_CHARACTERS, formBody({ website_url: formData.web_url }))
        .then((res) => {
          setPulledWebsites(res.data.data);
        })
        .finally(() => {
          setPullLoading(false);
        });
    }
  }

  function handleRemovePulledWebpage(indexToRemove) {
    const proxyData = [...pulledWebsites];
    let newProxyData = proxyData.filter((_, index) => index !== indexToRemove);
    setPulledWebsites(newProxyData);
  }

  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={false} editType="upload-web">
        <div>
          <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
          <div className="sor-header">
            <h6>Train Website</h6>
          </div>
          <div className="sor-body-header-footer-backbtn ">
            <form id="chatbot-train-web-form" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label">Website Url</label>
                  <div className="flex ">
                    <div className="form-control-wrap w-100 me-1">
                      <input
                        type="text"
                        className="form-control"
                        {...register("web_url", {
                          required: "This field is required",
                        })}
                        value={formData.web_url}
                        onChange={handleInputChange}
                      />
                      {errors.web_url && <span className="invalid">{errors.web_url.message}</span>}
                    </div>
                    <div className="position-relative">
                      <Button
                        color="primary"
                        onClick={(ev) => {
                          ev.preventDefault();
                          handlePullWebpageCharacters();
                        }}
                      >
                        {isPullLoading ? <Spinner size="sm" color="light" /> : "Pull"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {pulledWebsites.length > 0 && (
                <div className="mt-4">
                  <Card>
                    <table className="table table-middle mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="tb-col tb-col-md">
                            <div className="fs-13px text-base">Webpage</div>
                          </th>
                          <th className="tb-col tb-col-md"></th>
                          <th className="tb-col tb-col-md"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pulledWebsites.map((item, index) => (
                          <tr key={`leadConfig-${index}`}>
                            <td className="tb-col tb-col-bg">
                              <strong>{item.web_page_url}</strong>
                            </td>
                            <td className="tb-col tb-col-bg">
                              <span className=" text-xs" title={`${item.page_content_character_count} Characters`}>
                                {formatNumber(item.page_content_character_count)}
                              </span>
                            </td>

                            <td className="tb-col tb-col-bg">
                              <strong
                                style={{
                                  color: "#733aea",
                                }}
                                className="link cursor-pointer fs-10"
                              >
                                <Icon
                                  name="trash"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    handleRemovePulledWebpage(index);
                                  }}
                                />
                              </strong>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card>
                </div>
              )}

              {isNoteShow && (
                <div className="w-100 my-3 bg-green-100 rounded-md p-2">
                  <p className=" text-xs mb-1">
                    <strong>Notes</strong>
                  </p>
                  <ul>
                    <li className="list-item text-xs">Enter the URL of your external support content.</li>
                    <li className="list-item text-xs">
                      We fetch all pages from the provided website URL, including subdomains.
                    </li>
                    <li className="list-item text-xs">
                      Use top-level domains for best outcomes (e.g., use <strong>https://myhelpcenter.com </strong>
                      instead of
                      <strong>https://myhelpcenter.com/home</strong>).
                    </li>
                  </ul>
                </div>
              )}
              <Button color="primary" type="submit" className="d-none"></Button>
            </form>
          </div>
          <div className="sor-footer d-flex align-items-center justify-content-end">
            <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" className="sor-action-button position-relative" form="chatbot-train-web-form">
              {isLoading ? <Spinner size="sm" color="light" /> : "Train"}
            </Button>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditTrainWeb;
