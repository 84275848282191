import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";
import { ChatItem } from "./ChatPartials";
import { CONVERSATION, URLS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatListData,
  getChatListData,
  getChatListReqBusyStatus,
  resetConversationStore,
  setBrainId,
  setChatListEndpoint,
} from "../../../redux";
import Loading from "../../../components/loading/Loading";
import { useParams } from "react-router";
import whatsapp from "../../../images/chatbot/whatsapp.png";
import telegram from "../../../images/chatbot/telegram.png";
import twilio from "../../../images/chatbot/twilio.png";
import messenger from "../../../images/chatbot/messenger.png";
import widget from "../../../images/chatbot/widget.png";
import instagram from "../../../images/chatbot/instagram.png";
export const ChatAsideBody = () => {
  const dispatch = useDispatch();
  const chatListReqBusyStatus = useSelector(getChatListReqBusyStatus);
  const chatListData = useSelector(getChatListData);
  const { brainId } = useParams();

  useEffect(() => {
    dispatch(setBrainId({ [CONVERSATION.STORE.BRAIN_ID]: brainId }));
    dispatch(setChatListEndpoint({ [CONVERSATION.STORE.CHAT_LIST_ENDPOINT]: URLS.CHATBOT.CONVERSATION.CHAT_INDEX }));
    fetchChatList();
    return () => {
      dispatch(resetConversationStore());
    };
  }, []);

  function fetchChatList() {
    dispatch(fetchChatListData());
  }
  function getChatProducerTypeImg(typeId) {
    const typeImg = {
      0: widget,
      1: whatsapp,
      2: telegram,
      3: messenger,
      4: twilio,
      5: instagram,
    };
    return typeImg[typeId];
  }
  function getChatProducerTypeImgTitle(typeId) {
    const typeImgTitle = {
      0: "Widget",
      1: "Whatsapp",
      2: "Telegram",
      3: "Messenger",
      4: "Twilio",
      5: "Instagram",
    };
    return typeImgTitle[typeId];
  }

  return (
    <SimpleBar className="nk-chat-aside-body">
      {chatListReqBusyStatus && <Loading height={65} width={65} isLoading={chatListReqBusyStatus} />}
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Messages</h6>
        {!chatListReqBusyStatus && (
          <ul className="chat-list">
            {chatListData.length !== 0 ? (
              chatListData.map((item, idx) => {
                return (
                  <ChatItem
                    key={idx}
                    item={item}
                    imgSrc={getChatProducerTypeImg(item.chat_producer)}
                    imgTitle={getChatProducerTypeImgTitle(item.chat_producer)}
                  />
                );
              })
            ) : (
              <p className="m-3">No Chat found</p>
            )}
          </ul>
        )}
      </div>
    </SimpleBar>
  );
};
