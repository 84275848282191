export const getTrainDocModel = {
  file_obj: null,
  title: null,
};

export const getTrainWebModel = {
  web_url: null,
};

export const fakeTableSource={
  tables: [
      "trip_notification",
      "core_systemchangelog",
      "otovoucher_ridervoucherfleetm2m",
      "core_user_organization_roles",
      "trip_testbikesimulationsession",
      "trip_rentarea",
      "core_organizationstripesubscriptioninfo",
      "analytics_vehiclepowertimelinerecord",
      "django_session",
      "core_userbalanceadjustmentrecord",
      "marketplace_buyertripcurrencysettlement",
      "trip_report",
      "core_organizationvehicleiottypewisesettings",
      "django_migrations",
      "trip_organizationtripaction",
      "trip_organizationvehicleaction",
      "core_behaviorpoint",
      "trip_riderappnotification",
      "auth_group_permissions",
      "buyer_payout_buyerpayout",
      "otopass_package",
      "trip_trippauselockrecord",
      "core_documentinfo",
      "core_emailtemplate",
      "trip_dashboardnotification",
      "core_userinvitationrecord",
      "top_up_offer_topupoffer_assigned_fleets",
      "rider_payment_paymentrecord",
      "core_systemversion",
      "core_versionreleasenote",
      "trip_rentaddon",
      "core_organizationvehicletypewisesettings",
      "core_organizationusergroup",
      "core_appreport",
      "top_up_offer_topupoffersubscription",
      "top_up_offer_topupoffer",
      "operation_chargestatus",
      "django_flatpage",
      "trip_bikecontrolactionretrylog",
      "core_appinstructionsetting",
      "core_invoicecurrencysettlement",
      "trip_parkingimage",
      "core_invoicetransaction",
      "silk_profile_queries",
      "operation_reserveparkingspot",
      "trip_edgebox",
      "silk_response",
      "otopass_packagewisefacility",
      "otocampaign_campaignpushrecord",
      "otocampaign_ridercampaignassignmentnew",
      "operation_taskwage",
      "trip_rate",
      "trip_sunpod",
      "operation_reportimage",
      "core_exportrecord",
      "auth_permission",
      "trip_reportimage",
      "core_rules",
      "otopass_facility",
      "core_organizationoperatorgeneralsettings",
      "trip_vehiclepowerlevelrecord",
      "rider_payment_riderbalanceexpirationrecord",
      "operation_rangerfleet",
      "operation_rangernotification",
      "core_geofencecategoriesenabled",
      "trip_lockconfiguration",
      "core_userfraudactivitylog",
      "operation_rangerreport",
      "core_userprofile",
      "core_customnotification",
      "trip_payment",
      "trip_ticket",
      "trip_promotion_assigned_fleets",
      "silk_profile",
      "silk_request",
      "core_organizationbehaviorpoint",
      "core_usersetting",
      "django_cron_cronjoblog",
      "otopass_subscription",
      "trip_slowzonearea",
      "core_location",
      "django_admin_log",
      "trip_vehicleheartbeatrecord",
      "operation_rangerreportstatusupdaterecord",
      "trip_rentalpricingplan",
      "core_emailtemplategroup",
      "operation_reportvehiclepart",
      "core_language",
      "marketplace_vehiclemodel",
      "trip_reportbikepart",
      "core_emailtemplatedetail",
      "trip_ratedetail",
      "core_region",
      "rider_payment_paymentmethod",
      "core_emailtemplatenew",
      "marketplace_marketplacefleetactivemonths",
      "marketplace_marketplacefleet",
      "core_emailtemplateplaceholder",
      "marketplace_buyeroder",
      "trip_vehiclemodel",
      "core_identitycard",
      "trip_bikeoperationalflag",
      "core_transaction",
      "trip_speedlimit",
      "core_user_user_permissions",
      "core_user_groups",
      "trip_bikecontrolflag",
      "core_invoice",
      "core_appsetting",
      "core_organizationuseractionpermission",
      "core_entitynote",
      "core_financialaccount",
      "core_institution",
      "trip_pricingplan",
      "trip_bikemanufacturer",
      "analytics_aggregatedentitycountrecord",
      "core_userbehaviorpointrecord",
      "core_userpointrecord",
      "core_usercontact",
      "rider_payment_refundapplicationresult",
      "core_city",
      "core_paymentinfo",
      "core_organizationtaskwagesettings",
      "operation_reportcategory",
      "rider_payment_refundapplication",
      "trip_userpromotion",
      "silk_sqlquery",
      "core_entitytag",
      "authtoken_token",
      "trip_organizationvehiclemodel",
      "trip_taxplan",
      "trip_bike",
      "django_flatpage_sites",
      "trip_rentpricingorgvehiclemodelm2m",
      "core_currency",
      "trip_bikefault",
      "rider_payment_checkout",
      "core_emaillog",
      "otorental_rentaddonassignment",
      "core_organizationvehiclesettings",
      "core_organizationsubscriptioninvoice",
      "core_country",
      "core_otp",
      "core_subscription",
      "core_userprofile_assigned_fleets",
      "trip_sunpodarea",
      "core_organizationuserrole",
      "auth_group",
      "trip_trip",
      "trip_bikegeneralflag",
      "trip_lock",
      "marketplace_buyerbilling",
      "core_organizationeventsettings",
      "health_check_db_testmodel",
      "django_site",
      "axes_accesslog",
      "core_reportingcategoriesenabled",
      "core_entityfile",
      "trip_sunpodsession",
      "django_content_type",
      "rider_payment_paymentgatewayconfig",
      "trip_promotion",
      "core_entitylog",
      "trip_trippauserecord",
      "core_policypenalty",
      "trip_restrictedarea",
      "core_fleetpolicypenalty",
      "operation_rangertask",
      "core_organizationbusinesshoursettings",
      "trip_parkingarea",
      "marketplace_passwordresettoken",
      "operation_operatortemporaryphoto",
      "core_requestauditlog",
      "trip_lockmanufacturer",
      "marketplace_bankdetails",
      "core_user",
      "marketplace_payout",
      "marketplace_transactions",
      "axes_accessattempt",
      "core_rewardpoint",
      "otovoucher_ridervoucherrewardpointcategorym2m",
      "otovoucher_ridervoucherassignment",
      "otovoucher_ridervoucher",
      "core_trippolicypenaltyrecord",
      "core_emailotp",
      "rider_payment_paymentgateway",
      "operation_taskinvoice",
      "trip_scooterextras",
      "trip_geofence",
      "trip_fleet",
      "core_organization",
      "trip_rentpackage",
      "easyaudit_loginevent",
      "easyaudit_requestevent",
      "easyaudit_crudevent",
      "otorental_vehiclerental",
      "otorental_rentpackagesubscription",
      "marketplace_marketplaceinvoice",
      "otocampaign_ridercampaignfleetm2m",
      "otocampaign_ridercampaignassignment",
      "otocampaign_ridercampaign",
      "marketplace_marketplaceinvoicesatelment"
  ]
}
