/* Core */
import { configureStore } from "@reduxjs/toolkit";

/* Instruments */
import { middleware } from "./middleware";
import { reducer } from "./rootReducer";

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === "development" ? getDefaultMiddleware().concat(middleware) : getDefaultMiddleware();
  },
});
