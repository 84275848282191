import React, { useEffect, useRef, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Icon, PreviewCard } from "../../components/Component";
import { Spinner, Alert } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AUTH_SLICE_FIELDS, login, setAuthData, setWorkspaceData } from "../../redux";
import { formBody, httpClient } from "../../services";
import { AUTH, LOCAL_STORAGE_ITEMS, TOAST, URLS } from "../../constants";
import { notify } from "../../services/toastService";
import { useGoogleLogin } from "@react-oauth/google";
import googleIcon from "../../assets/images/login/google.svg";
import { authFormData } from "./helper";
import useAuth from "../../hooks/useAuth";
import { consoleLog } from "../../utils/Utils";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showAuthBox, setShowAuthBox] = useState(true);
  const [googleLoading, setGoogleLoading] = useState(false);

  const [errorVal, setError] = useState("");
  const continueWithMailRef = useRef(false);
  const [formData, setFormData] = useState(authFormData);
  const [showEmailInput, setShowEmailInput] = useState(null);
  const [showEmailSent, setShowEmailSent] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    if (auth) {
      navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
    }
  }, [auth]);

  function resetAll() {
    setShowAuthBox(true);
    setError("");
    continueWithMailRef.current = false;
    setFormData(authFormData);
    setShowEmailInput(null);
    setShowEmailSent(null);
  }

  function sendEmailForVerification() {
    setLoading(true);
    httpClient
      .post(URLS.AUTH.SEND_VERIFICATION_EMAIL, formBody({ email: formData.email }))
      .then((res) => {
        setShowEmailInput(false);
        setShowEmailSent(true);
      })
      .catch((err) => {
        setTimeout(() => {
          consoleLog("sendEmailForVerification-err", { err });
          setError(err?.response?.data?.error_description || "Failed");
        }, 1000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (gRes) => {
      setGoogleLoading(true);
      consoleLog(
        "glogin-",
        formBody({
          client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
          grant_type: "convert_token",
          backend: "google-oauth2",
          token: gRes.access_token,
        })
      );

      httpClient
        .post(
          URLS.AUTH.SOCIAL_LOGIN,
          formBody({
            client_id: `${process.env.REACT_APP_CLIENT_ID}`,
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
            grant_type: "convert_token",
            backend: "google-oauth2",
            token: gRes.access_token,
          })
        )
        .then((serverRes) => {
          httpClient.get(URLS.AUTH.PROFILE).then((res) => {
            dispatch(setAuthData({ key: AUTH.STORE.USER, value: res.data }));
            localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_TYPE, res.data.user_type);
          });

          localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, serverRes.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN]);
          localStorage.setItem(
            LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP,
            new Date().getTime() + serverRes.data.expires_in * 1000 - 60000
          ); // having 1 minute(60000 ms) as threshold
          localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, serverRes.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN]);
          const flag = {
            [AUTH_SLICE_FIELDS.LOADED]: true,
            [AUTH_SLICE_FIELDS.LOGGED_IN]: true,
            [AUTH_SLICE_FIELDS.ACCESS_TOKEN]: serverRes.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN],
            [AUTH_SLICE_FIELDS.REFRESH_TOKEN]: serverRes.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN],
          };
          //NEED_REFACTOR
          httpClient
            .get(URLS.WORKSPACE.INDEX)
            .then((res) => {
              consoleLog("res-full", res);
              if (res.data.data[0]) {
                localStorage.setItem(
                  LOCAL_STORAGE_ITEMS.WORKSPACE,
                  res.data.data ? JSON.stringify(res.data.data[0]) : null
                );
                dispatch(setWorkspaceData({ id: res.data.data[0].id, name: res.data.data[0].name }));
                dispatch(login(flag));
                setLoading(false);
                navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
              }
            })
            .catch((err) => {
              notify(TOAST.TYPE.ERROR, {
                title: `${err.response.data.error.code} [${err.response.status}]`,
                message: `${err.response.data.error.message}`,
              });
            });
        })
        .catch((e) => {
          setTimeout(() => {
            consoleLog("glogin-err", { e });
            setError(e?.response?.data?.error_description || "Failed");
          }, 1000);
        })
        .finally(() => {
          setGoogleLoading(false);
        });
    },
  });

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

        <PreviewCard className="shadow-lg" bodyClass="card-inner-lg">
          {showAuthBox && (
            <div>
              <BlockHead>
                <BlockContent>
                  <BlockTitle className="text-center" tag="h4">
                    Start Your Journey
                  </BlockTitle>
                </BlockContent>
              </BlockHead>
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> Unable to login with credentials{" "}
                  </Alert>
                </div>
              )}

              {!showEmailInput && (
                <div>
                  <div>
                    <Button
                      size="lg"
                      className=" btn-block"
                      color="primary"
                      onClick={(ev) => {
                        ev.preventDefault();
                        googleLogin();
                      }}
                    >
                      {googleLoading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        <>
                          <img src={googleIcon} alt="google_icon" />
                          <span class="ps-2">Continue with Google</span>
                        </>
                      )}
                    </Button>
                  </div>
                  <div className="text-center pt-2 pb-2">
                    <h6 className="overline-title overline-title-sap">
                      <span>OR</span>
                    </h6>
                  </div>

                  <div>
                    <Button
                      size="lg"
                      className="btn-dim btn-block"
                      color="primary"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowAuthBox(false);
                        continueWithMailRef.current = true;
                        setShowEmailInput(true);
                      }}
                    >
                      <Icon name="mail-fill"></Icon>
                      <span class="ps-2">Continue with Email</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

          {showAuthBox === false && continueWithMailRef.current === true && showEmailInput === true && (
            <div>
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> Unable to send email
                  </Alert>
                </div>
              )}
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData((pd) => {
                        return { ...pd, email: e.target.value };
                      })
                    }
                  />
                </div>
              </div>

              <Button
                size="lg"
                className="btn-block"
                color="primary"
                onClick={(ev) => {
                  ev.preventDefault();
                  if (formData.email) {
                    sendEmailForVerification();
                  }
                }}
              >
                {loading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  <>
                    <span>Continue</span>
                  </>
                )}
              </Button>
            </div>
          )}

          {showEmailSent === true && (
            <div>
              <div className="flex justify-center mb-3">
                <div className="text-center">
                  <h4 className="mb-2">Check your inbox</h4>
                  <p>
                    Click the link we sent to
                    <span className="font-semibold ms-1">{formData.email}</span> to sign in.
                  </p>
                </div>
              </div>

              <div>
                <Button
                  size="lg"
                  className="btn-block"
                  color="primary"
                  onClick={(ev) => {
                    ev.preventDefault();
                    resetAll();
                  }}
                >
                  <span>Ok</span>
                </Button>
              </div>
            </div>
          )}
        </PreviewCard>
      </Block>
    </>
  );
};
export default Login;
