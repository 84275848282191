export const CONVERSATION = {
  STORE: {
    PID: "pId",
    CHAT_LIST_ENDPOINT: "endpoint",
    QSC: {
      KEY: "qsc",
      VALUE: {
        TOTAL: "total",
        LIMIT: "limit",
        OFFSET: "offset",
      },
    },
    REQUEST: {
      KEY: "req",
      VALUE: {
        BUSY: "busy",
      },
    },
    IS_CONCAT_DATA: "isConcatData",
    CHAT_LIST_DATA: "chatList",
    BRAIN_ID: "brain_id",
    SELECTED_CHAT_INFO: "selectedChatInfo",
    MOBILE_VIEW: "mobileView",
    CHAT_PRODUCE_TYPE: "chat_producer",
    INTEGRATION_DATA: (type) => `${type}_data`,
  },
};
