import React, { useState, useEffect, useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Col, Row, Icon, Button } from "../../../components/Component";


import { userData } from "./data/user";
import { Link } from "react-router-dom";
import { Card,  TabContent, TabPane, Modal, ModalBody,  } from "reactstrap";
import { useSelector } from "react-redux";
import { httpClient } from "../../../services/httpClient";
import { AUTH, URLS } from "../../../constants";
import { useDispatch } from "react-redux";
import { setsubscriptionData } from "../../../redux/slices/subscription.store";
import { SUBSCRIPTION } from "../../../constants/subscription.constant";
import generatePDF from "react-to-pdf";
import { formatDate } from "@fullcalendar/core";
import SettingsInput from "../../../components/input/SettingsInput";

const Profile = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let tabValue = urlParams.get('tab') === null ? "profile" : urlParams.get('tab').toString();
  const { auth } = useSelector(state => state.auth)
  const { subscription } = useSelector(state => state)
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(tabValue);

  const [val, setVal] = useState(``)

  const htmlRef = useRef(null)

  useEffect(() => {
    if (val.length) {
      generatePDF(htmlRef, { filename: 'invoice.pdf' })
        .then(res => setVal(''))
    }
  }, [val])


  useEffect(() => {
    setActiveTab(tabValue);
  }, [tabValue]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const [modalTab, setModalTab] = useState(0);
  const [userInfo, setUserInfo] = useState(userData);
  const [formData, setFormData] = useState({
    name: "Abu Bin Ishtiak",
    displayName: "Ishtiak",
    phone: "818474958",
    dob: "1980-08-10",
    address: "2337 Kildeer Drive",
    address2: "",
    state: "Kentucky",
    country: "Canada",
  });
  const [modalChangePlan, setModalChangePlan] = useState(false);
  const [modalCancelSubscription, setModalCancelSubscription] = useState(false);
  const [promoAlert, setPromoAlert] = useState(false);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(_ => {
    if (auth[AUTH.STORE.USER].subscription?.subscription_plan?.is_default)
      setPromoAlert(true)
  }, [auth[AUTH.STORE.USER]])

  useEffect(_ => {
    if (auth.user.id) {
      const { first_name, last_name, user_type_display_text } = auth.user
      let flagUser = { ...userData } // userdata is the format this theme is using
      flagUser.name = first_name
      flagUser.displayName = last_name
      flagUser.role = user_type_display_text
      setFormData({
        name: first_name,
        displayName: last_name,
        phone: "818474958",
        dob: "1980-08-10",
        address: "2337 Kildeer Drive",
        address2: "",
        state: "Kentucky",
        country: "Canada",
      })
      setUserInfo(flagUser)
    }
  }, [auth.user])

  useEffect(() => {
    httpClient.get(URLS.PAYMENT.INVOICES)
      .then(res => dispatch(setsubscriptionData({ key: SUBSCRIPTION.STORE.INVOICES, value: res.data.data })))
  }, [])

  const submitForm = () => {
    let submitData = {
      ...formData,
    };
    setUserInfo(submitData);
    setModalTab(0);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setUserInfo(info => ({ ...info, [name]: value }));
  };

  return (
    <React.Fragment>
      
      <Head title="Profile"></Head>
      <Content>
        <BlockHead size="md">
          <div className="nk-block-head-sub"><span></span></div>
          <BlockBetween>
            <BlockHeadContent>
            <BlockTitle className="page-title">{
                activeTab === 'profile' ?
                  "Personal Information"
                  : "Payment and Billing"
              }</BlockTitle>
              <BlockDes>
                {/* <p>Basic info, like your name and address, that you use on Nio Platform.</p> */}
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            {/* <Nav tabs className="nav-tabs-s1 px-4">
              <NavItem>
                <NavLink tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === "profile" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleTab("profile");
                  }}>Profile</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag="a"
                  href="#tab"
                  className={classnames({ active: activeTab === "billing" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleTab("billing");
                  }}>Payment &amp; Billing</NavLink>
              </NavItem>
            </Nav> */}
            <div className="card-inner">
              <TabContent activeTab={activeTab} className="mt-0">
                <TabPane tabId="profile">
                  <div className="nk-data data-list">
                    {/* <div className="data-head">
                      <h6 className="overline-title">Basics</h6>
                    </div> */}

                    {/* <div className="data-item" onClick={() => setModalTab(1)}>
                      <div className="data-col">
                        <span className="data-label">Full Name</span>
                        <span className="data-value">{userInfo.name}</span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more">
                          <Icon name="forward-ios"></Icon>
                        </span>
                      </div>
                    </div> */}
                    <SettingsInput
                      title={"First Name"}
                      input_name={"name"}
                      input_placeholder={'first name'}
                      input_value={userInfo.name}
                      onChange={handleInputChange}
                      subTitle={''}
                      onSubmit={(callBack) => {
                        callBack() // method for closing edit view
                        // call API for profile update
                      }}
                    />
                    {/* <div className="data-item" onClick={() => setModalTab(1)}>
                      <div className="data-col">
                        <span className="data-label">Last Name</span>
                        <span className="data-value">{userInfo.displayName}</span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more">
                          <Icon name="forward-ios"></Icon>
                        </span>
                      </div>
                    </div> */}
                    <SettingsInput
                      title={"Last Name"}
                      input_name={"displayName"}
                      input_placeholder={'Last name'}
                      input_value={userInfo.displayName}
                      onChange={handleInputChange}
                      subTitle={''}
                      onSubmit={(callBack) => {
                        callBack() // method for closing edit view
                        // call API for profile update
                      }}
                    />
                    {/* <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Email</span>
                        <span className="data-value">info@softnio.com</span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more disable">
                          <Icon name="lock-alt"></Icon>
                        </span>
                      </div>
                    </div> */}
                    {/* <SettingsInput
                      title={"Email"}
                      input_name={"email"}
                      input_placeholder={'Email'}
                      input_value={userInfo.email}
                      onChange={handleInputChange}
                      subTitle={''}
                      onSubmit={(callBack) => {
                        callBack() // method for closing edit view
                        // call API for profile update
                      }}
                    /> */}
                    {/* <div className="data-item" onClick={() => setModalProfile(true)}>
                      <div className="data-col">
                        <span className="data-label">Phone Number</span>
                        <span className="data-value text-soft">{userInfo.phone}</span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more">
                          <Icon name="forward-ios"></Icon>
                        </span>
                      </div>
                    </div>
                    <div className="data-item" onClick={() => setModalProfile(true)}>
                      <div className="data-col">
                        <span className="data-label">Date of Birth</span>
                        <span className="data-value">{userInfo.dob}</span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more">
                          <Icon name="forward-ios"></Icon>
                        </span>
                      </div>
                    </div>
                    <div className="data-item" onClick={() => setModalProfile(true)}>
                      <div className="data-col">
                        <span className="data-label">Address</span>
                        <span className="data-value">
                          {userInfo.address},
                          <br />
                          {userInfo.state}, {userInfo.country}
                        </span>
                      </div>
                      <div className="data-col data-col-end">
                        <span className="data-more">
                          <Icon name="forward-ios"></Icon>
                        </span>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="nk-data data-list">
                    <div className="data-head">
                      <h6 className="overline-title">Password</h6>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Password</span>
                        <span className="data-value">**********</span>
                      </div>
                      <div className="data-col data-col-end">
                        <a
                          href="#language"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab(2)
                          }}
                          className="link link-primary"
                        >
                          Change Password
                        </a>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Date Format</span>
                        <span className="data-value">MM/DD/YYYY</span>
                      </div>
                      <div className="data-col data-col-end">
                        <a
                          href="#link"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="link link-primary"
                        >
                          Change
                        </a>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Timezone</span>
                        <span className="data-value">Bangladesh (GMT +6)</span>
                      </div>
                      <div className="data-col data-col-end">
                        <a
                          href="#link"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="link link-primary"
                        >
                          Change
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <Modal isOpen={modalTab > 0} className="modal-dialog-centered" size="lg" toggle={() => setModalTab(0)}>
                    <a
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setModalTab(0);
                      }}
                      className="close"
                    >
                      <Icon name="cross-sm"></Icon>
                    </a>
                    <ModalBody>
                      <div className="p-2">
                        <h5 className="title">Update Profile</h5>
                        <ul className="nk-nav nav nav-tabs">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${modalTab === 1 && "active"}`}
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModalTab(1);
                              }}
                              href="#personal"
                            >
                              Personal
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${modalTab === 2 && "active"}`}
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModalTab(2);
                              }}
                              href="#address"
                            >
                              Password
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          
                          <div className={`tab-pane ${modalTab === 1 ? "active" : ""}`} id="personal">
                            <Row className="gy-4">
                              <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="first-name">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    id="first-name"
                                    className="form-control"
                                    name="name"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.name}
                                    placeholder="Enter Full name"
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="last-name">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    id="last-name"
                                    className="form-control"
                                    name="displayName"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.displayName}
                                    placeholder="Enter display name"
                                  />
                                </div>
                              </Col>
                              {/* <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone-no">
                                    Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    id="phone-no"
                                    className="form-control"
                                    name="phone"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.phone}
                                    placeholder="Phone Number"
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="birth-day">
                                    Date of Birth
                                  </label>
                                  <DatePicker
                                    selected={new Date(formData.dob)}
                                    className="form-control"
                                    onChange={(date) => setFormData({ ...formData, dob: getDateStructured(date) })}
                                    maxDate={new Date()}
                                  />
                                </div>
                              </Col>
                              <Col size="12">
                                <div className="custom-control custom-switch">
                                  <input type="checkbox" className="custom-control-input" id="latest-sale" />
                                  <label className="custom-control-label" htmlFor="latest-sale">
                                    Use full name to display{" "}
                                  </label>
                                </div>
                              </Col> */}
                              <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button
                                      color="primary"
                                      size="lg"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        submitForm();
                                      }}
                                    >
                                      Update Profile
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setModalTab(0);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                          <div className={`tab-pane ${modalTab === 2 ? "active" : ""}`} id="address">
                            <Row className="gy-4">
                              <Col md="12">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="address-l1">
                                    Current Password
                                  </label>
                                  <input
                                    type="password"
                                    id="address-l1"
                                    name="address"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.address}
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                              {/* <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="address-l2">
                                    Address Line 2
                                  </label>
                                  <input
                                    type="text"
                                    id="address-l2"
                                    name="address2"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.address2}
                                    className="form-control"
                                  />
                                </div>
                              </Col> */}
                              <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="address-st">
                                    New Password
                                  </label>
                                  <input
                                    type="password"
                                    id="address-st"
                                    name="state"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.state}
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <label className="form-label" htmlFor="address-county">
                                    Confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    id="address-st"
                                    name="state"
                                    onChange={(e) => onInputChange(e)}
                                    defaultValue={formData.state}
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                              <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button color="primary" size="lg" onClick={() => submitForm()}>
                                      Update Password
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setModalTab(0);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </TabPane>
                <TabPane tabId="billing">
                  <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                   
                    <h5 className="mb-0">Your Subscription</h5>
                    <ul className="d-flex gx-4">
                      {/* <li>
                        <a className="link link-danger fw-normal" onClick={() => setModalCancelSubscription(true)} href="#cancelSubscriptionModal">Cancel Subscription</a>
                      </li> */}
                      <li>
                        <a className="link link-primary fw-normal" onClick={() => setModalChangePlan(true)} href="#changePlanModal">Change Plan</a>
                      </li>
                    </ul>
                  </div>
                  {/* <Alert color="warning" className="alert-dismissible mb-4 rounded-6" closeClassName="d-none" isOpen={promoAlert} toggle={() => setPromoAlert(false)}>
                    <p className="small mb-0">Save big up to 75% on your upgrade to our <strong><a className="alert-link" href="#">Enterprise plan</a></strong> and enjoy premium features at a fraction of the cost!</p>
                    <div className="d-inline-flex position-absolute end-0 top-50 translate-middle-y me-2">
                      <Button size="xs" color="warning" className="btn-icon rounded-pill" onClick={() => setPromoAlert(false)}>
                        <em className="icon ni ni-cross"></em>
                      </Button>
                    </div>
                  </Alert> */}
                  <Row className="g-gs">
                    <Col xl="3" sm="6">
                      <Card>
                        <div className="card-inner">
                          <div className="text-base mb-1">Plan</div>
                          <h5>{
                            auth.user.subscription?.subscription_plan ?
                              auth.user.subscription.subscription_plan.name
                              : "Free Plan"
                          }</h5>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="3" sm="6">
                      <Card>
                        <div className="card-inner">
                          <div className="text-base mb-1">Recurring Payment</div>
                          <h5>{
                            auth[AUTH.STORE.USER].subscription?.subscription_plan?.billing_cycle_text?.length ?
                              auth[AUTH.STORE.USER].subscription?.subscription_plan?.billing_cycle_text
                              : "--"
                          }</h5>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="3" sm="6">
                      <Card>
                        <div className="card-inner">
                          <div className="text-base mb-1">Next Due Date</div>
                          <h5>{
                            auth.user.subscription?.expiry_date ?
                              new Date(auth.user.subscription?.expiry_date).toDateString()
                              : "--"
                          }</h5>
                        </div>
                      </Card>
                    </Col>
                    <Col xl="3" sm="6">
                      <Card>
                        <div className="card-inner">
                          <div className="text-base mb-1">Payment Method</div>
                          <h5>{
                            auth[AUTH.STORE.USER].subscription?.payment_method?.display_text?.length ?
                              auth[AUTH.STORE.USER].subscription?.payment_method?.display_text
                              : "--"
                          }</h5>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <div className="mt-4 pt-1 pb-1 mb-3">
                    <h5>Billing History</h5>
                  </div>
                  <Card>
                    <table className="table table-middle mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="tb-col tb-col-md">
                            <div className="fs-13px text-base">Subscription</div>
                          </th>
                          <th className="tb-col tb-col-md">
                            <div className="fs-13px text-base">Due Date</div>
                          </th>
                          <th className="tb-col tb-col-md">
                            <div className="fs-13px text-base">Payment Date</div>
                          </th>
                          <th className="tb-col tb-col-sm">
                            <div className="fs-13px text-base">Total</div>
                          </th>
                          <th className="tb-col tb-col-sm">
                            <div className="fs-13px text-base">Status</div>
                          </th>
                          <th className="tb-col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          subscription[SUBSCRIPTION.STORE.INVOICES].map((data, index) =>
                            <tr
                              key={index}
                            >
                              <td className="tb-col tb-col-bg">
                                <div className="caption-text">{data.subscription_plan_pricing?.subscription_plan?.name} <div className="d-sm-none dot bg-success"></div></div>
                              </td>
                              <td className="tb-col tb-col-md">
                                <div className="fs-6 text-base d-inline-flex flex-wrap gap gx-2"><span>
                                  {
                                    data.due_date ?
                                      formatDate(new Date(data.due_date), {
                                        month: 'short',
                                        year: 'numeric',
                                        day: 'numeric',
                                        timeZoneName: 'short',
                                        locale: 'en'
                                      }).split('GMT')[0]
                                      : ""
                                  }
                                </span></div>
                              </td>
                              <td className="tb-col tb-col-md">
                                <div className="fs-6 text-base d-inline-flex flex-wrap gap gx-2"><span>{`${formatDate(new Date(data.payment_date), {
                                  month: 'short',
                                  year: 'numeric',
                                  day: 'numeric',
                                  timeZoneName: 'short',
                                  locale: 'en'
                                }).split('GMT')[0]}`} </span></div>
                              </td>
                              <td className="tb-col tb-col-sm">
                                <div className="fs-6 text-base">${data.amount}</div>
                              </td>
                              <td className="tb-col tb-col-sm">
                                <div className="badge bg-success-dim text-success rounded-pill">{data.status_display_text}</div>
                              </td>
                              <td className="tb-col tb-col-end">
                                <a href="#" onClick={_ => {
                                  httpClient.get(URLS.PAYMENT.INVOICE_TEMPLATE(data.id))
                                    .then(async res => {
                                      setVal(res.data)
                                    })
                                }} className="link">Download Invoice</a>

                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </Card>
                  <div
                    ref={htmlRef}
                    style={{
                      top: -10000
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: val
                      }}
                    />
                  </div>

                  <Modal isOpen={modalChangePlan} toggle={() => setModalChangePlan(false)}>
                    <ModalBody className="p-4">
                      <div className="user-avatar md text-primary bg-primary-dim mb-3">
                        <em className="icon ni ni-spark-fill"></em>
                      </div>
                      <h4>Change Subscription</h4>
                      <p>Are you sure you want to change your subscription? Your paid plan is valid till <span className="text-dark">23 Mar, 2023</span>.</p>
                      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
                        <div className="card-body">
                          <h5>What you will lose after changing your active subscription?</h5>
                          <ul className="list gy-2 mt-2">
                            <li>You won’t have a dedicated account manager</li>
                            <li>No custom tools will be existed for AI content generations.</li>
                            <li>You’ll lose access to advance integrations.</li>
                            <li>No prioritized support will be provided for you.</li>
                            <li>Server response rate will be standard than faster.</li>
                            <li>Regular model updates instead of special features early access.</li>
                          </ul>
                        </div>
                      </div>
                      <Row tag="ul" className="gx-4 mt-4">
                        <Col tag="li" size="6"><button className="btn btn-outline-light btn-block" onClick={() => setModalChangePlan(false)}>Keep Plan</button></Col>
                        <Col tag="li" size="6"><Link to={`${process.env.PUBLIC_URL}/subscription`} className="btn btn-primary btn-block">Change Plan</Link></Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modalCancelSubscription} toggle={() => setModalCancelSubscription(false)}>
                    <ModalBody className="p-4">
                      <div className="user-avatar md text-danger bg-danger-dim mb-3">
                        <em className="icon ni ni-spark-fill"></em>
                      </div>
                      <h4>Cancel Subscription</h4>
                      <p>Are you sure you want to cancel your subscription? Your paid plan is valid till  <span className="text-dark">23 Mar, 2023</span>.</p>
                      <p>Don't forget to review our other <a href="@@URL/copywriter/pricing-plans.html">pricing plans</a> before cancelling your current subscription. If you still want to cancel, your account will change to a free plan.</p>
                      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
                        <div className="card-body">
                          <h5>What you will lose after cancelling you subscription?</h5>
                          <ul className="list gy-2 mt-2">
                            <li>All your generated in Copygen will be removed.</li>
                            <li>You will lose access to unlimited content generation.</li>
                            <li>You will get regular updates exclusive only for our premium users.</li>
                            <li>Server response may become slow as we highly prioritized our premium users.</li>
                            <li>There will be no recovery options once you cancel subscription.</li>
                          </ul>
                        </div>
                      </div>
                      <Row tag="ul" className="gx-4 mt-4">
                        <Col tag="li" size="6"><button className="btn btn-outline-light btn-block" onClick={() => setModalCancelSubscription(false)}>Keep Plan</button></Col>
                        <Col tag="li" size="6"><Link to={`${process.env.PUBLIC_URL}/copywriter/pricing-plans`} className="btn btn-danger btn-block">I Understand, Cancel</Link></Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </TabPane>
              </TabContent>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Profile;
