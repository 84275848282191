import React, { useEffect, useState } from "react";
import { Button, Icon } from "../../../../../../components/Component";
import { httpClient } from "../../../../../../services";
import { TOAST, URLS } from "../../../../../../constants";
import { useParams } from "react-router";
import { notify } from "../../../../../../services/toastService";
import { zapierPayloadModel } from "./helper";
import { ZapierIntegrationConfig } from "../../../../../../config/zapierIntegration.config";
import { consoleLog } from "../../../../../../utils/Utils";

const ZapierAccrodion = () => {
  const { brainId } = useParams();
  const [isOpen, setIsOpen] = useState(1);
  const [payloadTransferSuccessful, setPayloadTransferSuccessful] = useState(false);
  const [zapPayloads, setZapPayloads] = useState(zapierPayloadModel);

  const toggleCollapse = (param) => {
    setIsOpen(param);
  };
  useEffect(() => {
    window.addEventListener(ZapierIntegrationConfig.events.refresh, () => {
      toggleCollapse(1);
    });
  }, []);

  useEffect(() => {
    if (isOpen === 1) {
      reset();
    }
  }, [isOpen]);

  function reset() {
    setPayloadTransferSuccessful(false);
    setZapPayloads((preData) => {
      const resetData = preData.map((data) => {
        return {
          ...data,
          value: "",
        };
      });
      return resetData;
    });
  }

  useEffect(() => {
    httpClient.get(URLS.CHATBOT.LEAD.LEAD_FIELD_LIST(brainId)).then((res) => {
      const leadConfigRes = res.data.data.map((lData, index) => {
        const filteredData = {
          id: lData.id,
          key: `${lData.name}`,
          value: "",
        };
        return filteredData;
      });
      if (leadConfigRes.length) {
        setZapPayloads(leadConfigRes);
      }
    });
  }, [brainId]);
  function handleZapierTest() {
    const zapPayloadProxy = [...zapPayloads];
    const zapPayloadFormData = new FormData();
    zapPayloadProxy.forEach((payload) => {
      zapPayloadFormData.append(payload.key, payload.value);
    });
    httpClient
      .post(URLS.CHATBOT.INTEGRATE.ZAPIER(brainId), zapPayloadFormData)
      .then((res) => {
        consoleLog("handleZapierTest-success", res);
        if (res.data.is_sent) {
          notify(TOAST.TYPE.SUCCESS, { title: "Success" });
          setPayloadTransferSuccessful(true);
          setZapPayloads((preData) => {
            const resetData = preData.map((data) => {
              return {
                ...data,
                value: "",
              };
            });
            return resetData;
          });
        }
      })
      .catch((err) => {
        consoleLog("handleZapierTest-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }
  return (
    <React.Fragment>
      <div className="py-3" style={{ borderTop: "1px solid #dbdfea" }}>
        <div className="flex items-center justify-between" onClick={() => toggleCollapse(1)}>
          <div className="flex items-center gap-2">
            {/* <Icon name="chevron-down" className="text-2xl" /> */}
            <Icon name="chevron-right" className="text-2xl" />
            <h6 className="title">Create Zap</h6>
          </div>
          <div>
            {isOpen === 1 && <Icon name="circle" className="text-2xl" />}
            {isOpen === 2 && <Icon name="check-circle" className="text-2xl text-green-700" />}
          </div>
        </div>

        {isOpen === 1 && (
          <div className="zapier">
            <div className="w-100 my-2">
              <ol>
                <li>
                  Follow{" "}
                  <a href="#">
                    <u>this link</u>
                  </a>{" "}
                  to open Zapier and enable the MagnusAI integration.
                </li>
                <li>
                  <strong>Create a new Zap</strong> and choose MagnusAI as the trigger.
                </li>
                <li>
                  Choose <strong>’Skill Triggered’</strong> as event.
                </li>
                <li>
                  Authorize Zapier to access MagnusAI using{" "}
                  <a href={`${process.env.PUBLIC_URL}/chats/${brainId}/settings`} target="_blank" rel="noreferrer">
                    <u>your API Key</u>
                  </a>
                  .
                </li>
                <li>Finally, choose the the chatbot from the dropdown and reach the testing step.</li>
              </ol>
            </div>
            <div className="flex items-center justify-end mt-3">
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  toggleCollapse(2);
                }}
                className=" flex justify-around items-center me-5"
              >
                <span>Ready to Test</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="py-3" style={{ borderTop: "1px solid #dbdfea", borderBottom: "1px solid #dbdfea" }}>
        <div className="flex items-center justify-between" onClick={() => toggleCollapse(1)}>
          <div className="flex items-center ">
            {/* <Icon name="chevron-down" className="text-2xl" /> */}
            <Icon name="chevron-right" className="text-2xl" />
            <div>
              <h6 className="title ms-3">Test Zap</h6>
            </div>
          </div>
          <div>
            {payloadTransferSuccessful ? (
              <Icon name="check-circle" className="text-2xl text-green-700" />
            ) : (
              <Icon name="circle" className="text-2xl" />
            )}
          </div>
        </div>
        {isOpen === 2 && (
          <div>
            <div>
              <p className="text-xs mt-2 mb-4 ms-10 pe-10">
                Describe the values that the chatbot should send to the Zap. For example: ’first_name’ or ’email’. These
                will be the values used by the service you integrate your chatbot with.
              </p>
            </div>

            <div>
              <div className="px-4 ms-3" key={`refresh-${isOpen}`}>
                <p>
                  <strong>Zap Payload</strong>
                </p>

                {zapPayloads.map((item, index) => (
                  <div key={`lct-${index}`} className="my-3 ">
                    <div className="flex  items-center ">
                      <div className="form-control-wrap w-100 me-1">
                        <input
                          type="text"
                          placeholder="Key"
                          name="key"
                          className="form-control"
                          disabled
                          value={item.key}
                        />
                      </div>
                      <div className="form-control-wrap w-100 ms-1">
                        <input
                          type="text"
                          placeholder="Value"
                          name="value"
                          className="form-control"
                          value={item.value}
                          onChange={(e) =>
                            setZapPayloads((preData) => {
                              preData[index].value = e.target.value;
                              return [...preData];
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-end mt-3">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleZapierTest();
                  }}
                  className=" flex justify-around items-center me-4"
                >
                  <span>Test</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ZapierAccrodion;
