import React, { useEffect, useState } from "react";
import SlideOverX from "../../../../components/sor/SlideOverX";
import { SubscriptionPlanConfig } from "../../../../config/subscriptionPlan.config";
import { Button, Icon, RSelect } from "../../../../components/Component";
import Emitter from "../../../../services/eventEmitterService";
import { booleanOptions, getPricingDataModel, getSubscriptionDataModel } from "./helper";
import { httpClient } from "../../../../services";
import { TOAST, URLS } from "../../../../constants";
import { notify } from "../../../../services/toastService";
import { Spinner } from "reactstrap";
import { fetchData, store } from "../../../../redux";
import { consoleLog } from "../../../../utils/Utils";

const AddEditSubscriptionPlan = () => {
  const [sorId, setSorId] = useState(SubscriptionPlanConfig.events.sorId);
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(getSubscriptionDataModel);
  const [pricingData, setPricingData] = useState(getPricingDataModel);

  const [supportPriorityTypeChoices, setSupportPriorityTypeChoices] = useState([]);
  const [billingIntervalTypeChoices, setBillingIntervalTypeChoices] = useState([]);

  const [submitting, setsubmitting] = useState(false);

  useEffect(() => {
    fetchInitData();

    Emitter.on(SubscriptionPlanConfig.events.editingData, (data) => {
      if (data.id) {
        setEdit(true);
        setSubscriptionId(data.id);
        const existingData = {
          name: data.name,
          number_of_allowed_published_chatbots: data.number_of_allowed_published_chatbots,
          number_of_allowed_personal_chatbots: data.number_of_allowed_personal_chatbots,
          number_of_allowed_messages_per_chatbot: data.number_of_allowed_messages_per_chatbot,
          number_of_allowed_users_per_chatbot: data.number_of_allowed_users_per_chatbot,
          number_of_allowed_file_uploads_per_month: data.number_of_allowed_file_uploads_per_month,
          number_of_allowed_workspaces: data.number_of_allowed_workspaces,
          train_with_website_crawling_enabled: data.train_with_website_crawling_enabled,
          chat_widget_customization_enabled: data.chat_widget_customization_enabled,
          branding_removal_enabled: data.branding_removal_enabled,
          custom_domain_and_white_labeling_enabled: data.custom_domain_and_white_labeling_enabled,
          support_priority_type: data.support_priority_type,
          email_and_chat_support_enabled: data.email_and_chat_support_enabled,
          is_magnus_ai_api_key_enabled: data.is_magnus_ai_api_key_enabled,
        };
        setSubscriptionData({ ...existingData });
      } else {
        resetData();
      }
    });
    return () => {
      Emitter.off(SubscriptionPlanConfig.events.editingData, {});
    };
  }, []);

  async function fetchInitData() {
    const supportPriorityTypeChoicesReq = await httpClient.get(URLS.SUBSCRIPTION_PLAN.SUPPORT_PRIORITY_TYPE_CHOICES);
    const billingIntervalTypeChoicesReq = await httpClient.get(URLS.SUBSCRIPTION_PLAN.BILLING_INTERVAL_TYPE_CHOICES);
    const requests = [supportPriorityTypeChoicesReq, billingIntervalTypeChoicesReq];
    await httpClient.all(requests).then(
      httpClient.spread((...responses) => {
        consoleLog("responses-sp", responses);
        setSupportPriorityTypeChoices(responses[0].data);
        setBillingIntervalTypeChoices(responses[1].data);
      })
    );
  }

  function close() {
    if (isLoading) return;
    resetData();
    dispatchEvent(new Event(SubscriptionPlanConfig.events.sorToggle));
  }

  function resetData() {
    setsubmitting(false);
    setSubscriptionData(getSubscriptionDataModel);
    setPricingData(getPricingDataModel);
    setEdit(false);
  }
  function refreshPage() {
    store.dispatch(fetchData());
  }

  async function onFormSubmit(rawData) {
    setsubmitting(true);
    if (isLoading) return;
    const subscriptionUrl = isEdit ? URLS.SUBSCRIPTION_PLAN.UPDATE(subscriptionId) : URLS.SUBSCRIPTION_PLAN.CREATE;
    const subscriptionMethod = isEdit ? "PATCH" : "POST";

    const subscriptionDataProxy = { ...subscriptionData };
    const subscriptionFormData = new FormData();
    for (const key in subscriptionDataProxy) {
      subscriptionFormData.append(key, subscriptionDataProxy[key]);
    }
    setLoading(true);

    await httpClient({ method: subscriptionMethod, url: subscriptionUrl, data: subscriptionFormData })
      .then((res) => {
        consoleLog("subscription-plan-res", res);
        if (!isEdit) {
          const currentSubscriptionId = res.data.id;
          const pricingPlanUrl = isEdit
            ? URLS.SUBSCRIPTION_PLAN.PRICING_UPDATE(currentSubscriptionId)
            : URLS.SUBSCRIPTION_PLAN.PRICING_CREATE;
          const pricingPlanMethod = isEdit ? "PATCH" : "POST";
          let pricingRequests = [];
          billingIntervalTypeChoices.forEach((billingPriceChoice) => {
            const pricingFormData = new FormData();
            pricingFormData.append("billing_interval", billingPriceChoice.value);
            pricingFormData.append("subscription_plan", currentSubscriptionId);
            if (billingPriceChoice.text === "Monthly") {
              pricingFormData.append("amount", pricingData.monthly_amount);
            }
            if (billingPriceChoice.text === "Yearly") {
              pricingFormData.append("amount", pricingData.yearly_amount);
            }
            const pricingReq = httpClient({ method: pricingPlanMethod, url: pricingPlanUrl, data: pricingFormData });
            pricingRequests.push(pricingReq);
          });

          httpClient.all(pricingRequests).then(
            httpClient.spread((...responses) => {
              consoleLog("subscription-plan-pricing-res", responses);
              refreshPage();
              notify(TOAST.TYPE.SUCCESS, { title: "Success" });
            })
          );
        }
      })
      .catch((err) => {
        consoleLog("subscription-plan-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }

  return (
    <React.Fragment>
      <SlideOverX
        onClose={resetData}
        id={sorId}
        isOverlayVisible={true}
        haveOverlayToggle={true}
        editType="subscription"
      >
        <div>
          <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
          <div className="sor-header">
            <h5>{isEdit ? "Edit" : "Add"} Subscription Plan</h5>
          </div>
          <div className="sor-body-header-footer-backbtn w-100">
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="name">
                  Name *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    placeholder="Plan A"
                    className="form-control"
                    value={subscriptionData.name}
                    onChange={(e) => setSubscriptionData({ ...subscriptionData, name: e.target.value })}
                  />
                  {submitting && !subscriptionData.name.length?.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_published_chatbots">
                  Number of Published Chatbot *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="1"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_published_chatbots}
                    onChange={(e) =>
                      setSubscriptionData({
                        ...subscriptionData,
                        number_of_allowed_published_chatbots: e.target.value,
                      })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_published_chatbots.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_personal_chatbots">
                  Number of Personal Chatbot *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="3"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_personal_chatbots}
                    onChange={(e) =>
                      setSubscriptionData({
                        ...subscriptionData,
                        number_of_allowed_personal_chatbots: e.target.value,
                      })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_personal_chatbots.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_messages_per_chatbot">
                  Number of Messages Per Chatbot *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="500"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_messages_per_chatbot}
                    onChange={(e) =>
                      setSubscriptionData({
                        ...subscriptionData,
                        number_of_allowed_messages_per_chatbot: e.target.value,
                      })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_messages_per_chatbot.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_users_per_chatbot">
                  Number of Users Per Chatbot *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="2"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_users_per_chatbot}
                    onChange={(e) =>
                      setSubscriptionData({
                        ...subscriptionData,
                        number_of_allowed_users_per_chatbot: e.target.value,
                      })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_users_per_chatbot.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_file_uploads_per_month">
                  Number of File Uploads Per Month *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="100"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_file_uploads_per_month}
                    onChange={(e) =>
                      setSubscriptionData({
                        ...subscriptionData,
                        number_of_allowed_file_uploads_per_month: e.target.value,
                      })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_file_uploads_per_month.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="number_of_allowed_workspaces">
                  Number of Allowed Workspace *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="2"
                    min={0}
                    className="form-control"
                    value={subscriptionData.number_of_allowed_workspaces}
                    onChange={(e) =>
                      setSubscriptionData({ ...subscriptionData, number_of_allowed_workspaces: e.target.value })
                    }
                  />
                  {submitting && !subscriptionData.number_of_allowed_workspaces.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="train_with_website_crawling_enabled">
                  Enable Trained by Website Crawling *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"train_with_website_crawling_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find((d) => d.value === subscriptionData.train_with_website_crawling_enabled)}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, train_with_website_crawling_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.train_with_website_crawling_enabled !== "boolean" && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="chat_widget_customization_enabled">
                  Enable Chat Widget Customization *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"chat_widget_customization_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find((d) => d.value === subscriptionData.chat_widget_customization_enabled)}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, chat_widget_customization_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.chat_widget_customization_enabled !== "boolean" && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="branding_removal_enabled">
                  Enable Branding Removal *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"branding_removal_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find((d) => d.value === subscriptionData.branding_removal_enabled)}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, branding_removal_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.branding_removal_enabled !== "boolean" && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="custom_domain_and_white_labeling_enabled">
                  Enable Custom Domain and White Labeling *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"custom_domain_and_white_labeling_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find(
                      (d) => d.value === subscriptionData.custom_domain_and_white_labeling_enabled
                    )}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, custom_domain_and_white_labeling_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.custom_domain_and_white_labeling_enabled !== "boolean" && (
                    <span className="error-message">
                      {submitting && subscriptionData.custom_domain_and_white_labeling_enabled}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="support_priority_type">
                  Support Priority Type *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"support_priority_type"}
                    options={supportPriorityTypeChoices}
                    value={supportPriorityTypeChoices.find((d) => d.value === subscriptionData.support_priority_type)}
                    getOptionLabel={(option) => option.text}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, support_priority_type: e.value });
                    }}
                  />
                  {submitting && subscriptionData.support_priority_type === null && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>

            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="email_and_chat_support_enabled">
                  Enable Email and Chat Support *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"email_and_chat_support_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find((d) => d.value === subscriptionData.email_and_chat_support_enabled)}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, email_and_chat_support_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.email_and_chat_support_enabled !== "boolean" && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="is_magnus_ai_api_key_enabled">
                  Enable API key *
                </label>

                <div className="form-control-wrap">
                  <RSelect
                    name={"is_magnus_ai_api_key_enabled"}
                    options={booleanOptions}
                    value={booleanOptions.find((d) => d.value === subscriptionData.is_magnus_ai_api_key_enabled)}
                    onChange={(e) => {
                      setSubscriptionData({ ...subscriptionData, is_magnus_ai_api_key_enabled: e.value });
                    }}
                  />
                  {submitting && typeof subscriptionData.is_magnus_ai_api_key_enabled !== "boolean" && (
                    <span className="error-message">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>

            <h6 className="mt-4">Pricing</h6>
            <hr className="hr mt-2 border-gray " />
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="monthly_amount">
                  Monthly Amount
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="2"
                    min={0}
                    className="form-control"
                    value={pricingData.monthly_amount}
                    onChange={(e) => setPricingData({ ...pricingData, monthly_amount: e.target.value })}
                  />
                  {submitting && !pricingData.monthly_amount.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 mb-2">
              <div className="form-group">
                <label className="form-label" htmlFor="yearly_amount">
                  Yearly Amount
                </label>
                <div className="form-control-wrap">
                  <input
                    type="number"
                    placeholder="2"
                    min={0}
                    className="form-control"
                    value={pricingData.yearly_amount}
                    onChange={(e) => setPricingData({ ...pricingData, yearly_amount: e.target.value })}
                  />
                  {submitting && !pricingData.yearly_amount.length && (
                    <span className="invalid">This Field is Required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="sor-footer d-flex align-items-center justify-content-end">
            <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="sor-action-button position-relative"
              form="subscription-plan-form"
              onClick={onFormSubmit}
            >
              {isLoading ? <Spinner size="sm" color="light" /> : isEdit ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditSubscriptionPlan;
