import React from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, BlockHeadContent, Icon, Button } from "../../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge } from "reactstrap";
import { XTable, XTableRow, XTableRowItem } from "../../../../components/xtable";
import { TOAST, URLS } from "../../../../constants";
import { useSelector } from "react-redux";
import { fetchData, getData, store } from "../../../../redux";
import { getUTCAwareTime } from "../../../../utils/datetime/getUTCAwareTime";
import AddEditSubscriptionPlan from "./AddEditSubscriptionPlan";
import { SubscriptionPlanConfig } from "../../../../config/subscriptionPlan.config";
import Emitter from "../../../../services/eventEmitterService";
import { httpClient } from "../../../../services";
import { notify } from "../../../../services/toastService";
import { consoleLog } from "../../../../utils/Utils";

const SubscriptionManagement = () => {
  const resData = useSelector(getData);
  const tableHeaders = [
    { text: "Id", width: "10%", sort: null },
    { text: "Name", width: "10%", sort: null },
    { text: "Created At", width: "10%", sort: null },
    { text: "Action", width: "10%", sort: null },
  ];

  function add() {
    Emitter.emit(SubscriptionPlanConfig.events.editingData, {});
    dispatchEvent(new Event(SubscriptionPlanConfig.events.sorToggle));
  }
  function edit(data) {
    Emitter.emit(SubscriptionPlanConfig.events.editingData, data);
    dispatchEvent(new Event(SubscriptionPlanConfig.events.sorToggle));
  }

  function refreshPage() {
    store.dispatch(fetchData());
  }
  function makeAsDefault(id) {
    httpClient
      .patch(URLS.SUBSCRIPTION_PLAN.MAKE_AS_DEFAULT(id))
      .then((res) => {
        consoleLog("subscription-default-res", res);
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        refreshPage();
      })
      .catch((err) => {
        consoleLog("subscription-default-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }

  function deleteSubscription(id) {
    httpClient
      .delete(URLS.SUBSCRIPTION_PLAN.DELETE(id))
      .then((res) => {
        consoleLog("subscription-delete-res", res);

        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        refreshPage();
      })
      .catch((err) => {
        consoleLog("subscription-delete-err", { err });
        notify(TOAST.TYPE.ERROR, { title: `Error [${err.response.status}]` });
      });
  }

  return (
    <React.Fragment>
      <AddEditSubscriptionPlan />
      <Head title="Subscription" />
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent className="d-flex align-items-center g-3">
            <BlockTitle>Subscription</BlockTitle>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className="toggle btn-icon "
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  add();
                }}
              >
                <Icon name="plus"></Icon>
              </Button>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <XTable xtId="subscription-management" headers={tableHeaders} endpoint={URLS.SUBSCRIPTION_PLAN.INDEX}>
            {resData &&
              resData.map((item) => {
                return (
                  <XTableRow key={item.id}>
                    <XTableRowItem>{item.id}</XTableRowItem>
                    <XTableRowItem className="d-flex justify-content  align-center gap-1">
                      <span>{item.name}</span>
                      {item.is_default && (
                        <Badge className="badge-dim" color="outline-primary" pill>
                          Free
                        </Badge>
                      )}
                    </XTableRowItem>
                    <XTableRowItem>{getUTCAwareTime(item.created_at)}</XTableRowItem>
                    <XTableRowItem>
                      <div className="">
                        <UncontrolledDropdown>
                          <DropdownToggle className="bg-transparent border-0">
                            <Icon name="more-h" className="text-dark"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    edit(item);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    deleteSubscription(item.id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    makeAsDefault(item.id);
                                  }}
                                >
                                  Make as Default
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </XTableRowItem>
                  </XTableRow>
                );
              })}
          </XTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default SubscriptionManagement;
