import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_ITEMS } from "../../constants";
import { WORKSPACE } from "../../constants/workspace.constant";

export const getWorkspaceData = ({ workspace }) => {
  return workspace;
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    id: localStorage.getItem(LOCAL_STORAGE_ITEMS.WORKSPACE)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.WORKSPACE)).id
      : "",
    name: localStorage.getItem(LOCAL_STORAGE_ITEMS.WORKSPACE)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.WORKSPACE)).name
      : "",
    [WORKSPACE.STORE.WORKSPACES_LIST]: []
  },
  reducers: {
    setWorkspaceData(state, { payload }) {
      state.id = payload.id;
      state.name = payload.name;
    },
    setWorkspaceList(state, { payload }) {
      state[WORKSPACE.STORE.WORKSPACES_LIST] = payload;
    },
  },
});

export const { setWorkspaceData, setWorkspaceList } = workspaceSlice.actions;

export default workspaceSlice.reducer;
