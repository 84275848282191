import React, { useRef, useState } from "react";

const UploadAvatar = ({ previousAvatar, current, onChange }) => {
  const [previewAvatar, setPreviewAvatar] = useState(previousAvatar);
  const fileInputRef = useRef(null);

  function selectAvatar() {
    fileInputRef.current.click();
  }
  function handleAvatarSelection() {
    let input = fileInputRef;
    let file = input.current.files;
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPreviewAvatar(e.target.result);
      };
      reader.readAsDataURL(file[0]);
      onChange(file[0]);
    }
  }
  return (
    <div>
      <div>
        <div onClick={selectAvatar} className="relative w-14 h-14 p-px bg-gray-300 border-0 rounded-md cursor-pointer">
          <div
            className="absolute z-10 flex items-center justify-center w-5 h-5 bg-gray-800 rounded-full place-items-center"
            style={{ top: "70%", right: "-3%" }}
          >
            <svg
              className="w-4 h-4 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              ></path>
            </svg>
          </div>
          {current && !previewAvatar && (
            <img src={current} className="object-cover w-14 h-14 overflow-hidden rounded-md" alt="currentAvatar" />
          )}
          {previewAvatar && (
            <img
              src={previewAvatar}
              className="object-cover w-14 h-14 overflow-hidden rounded-md"
              alt="previewAvatar"
            />
          )}
        </div>
        <input ref={fileInputRef} className="hidden" type="file" onInput={handleAvatarSelection} />
      </div>
      <input ref={fileInputRef} className="hidden" type="file" accept=".png" onInput={handleAvatarSelection} />
    </div>
  );
};

export default UploadAvatar;
