export const TOAST = {
  TYPE: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
    DEFAULT: "default",
  },
  POSITION: {
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right",
    TOP_CENTER: "top-center",
  },
  SUCCESS_TIME: 3000,
  ERROR_TIME: 5000,
};
