import React from "react";
import { Link } from "react-router-dom";

export const XTableRowItem = ({ className = "", to = "", ...props }) => {
  const fallbackText = "--";
  const content = props.children ?? fallbackText;
  return (
    <React.Fragment>
      <td className="xtable-body-row-item">
        <div className={className}>{to ? <Link to={to}>{content}</Link> : content}</div>
      </td>
    </React.Fragment>
  );
};
