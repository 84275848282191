export const IntegrationsConfig = {
  events: {
    name: "integrations",
    // refresh-index-data
    refresh: `rid-chatbots`,
    // slideover-right
    sorId: "integrations",
    sorToggle: "sor-toggle-integrations",
    // editing-data
    editingData: "edit-chatbots-data",
  },
  dataFormats: {
    whatsapp: {
      header: "Connect Whatsapp",
      fields: [
        {
          title: "WhatsApp Business Account ID",
          data: "whatsapp_business_account_id",
        },
        {
          title: "System Access Token",
          data: "whatsapp_system_access_token",
        },
        {
          title: "Phone Number ID",
          data: "whatsapp_phone_number_id",
        },
        {
          title: "Secret Key",
          data: "whatsapp_secret_key",
        },
      ],
    },
    twilio: {
      header: "Connect Twilio",
      fields: [
        {
          title: "Twilio Account SID",
          data: "twilio_account_sid",
        },
        {
          title: "Auth Token",
          data: "twilio_auth_token",
        },
        {
          title: "Twilio Phone Number",
          data: "twilio_phone_number",
        },
        {
          title: "Twilio Phone Number SID",
          data: "twilio_phone_number_sid",
        },
      ],
    },
    telegram: {
      header: "Connect Telegram",
      fields: [
        {
          title: "Telegram Bot Access Token",
          data: "telegram_bot_access_token",
        },
      ],
    },
    messenger: {
      header: "Connect Messenger",
      fields: [
        {
          title: "Page Access Token",
          data: "messenger_page_access_token",
        },
        {
          title: "Page ID",
          data: "messenger_page_id",
        },
        {
          title: "Messenger Secret Key",
          data: "messenger_secret_key",
        },
      ],
    },
    instagram: {
      header: "Connect Instagram",
      fields: [
        {
          title: "Instagram Page Id",
          data: "instagram_page_id",
        },
        {
          title: "Page Access Token",
          data: "instagram_page_access_token",
        },
        {
          title: "Instagram App Secret Key",
          data: "instagram_app_secret_key",
        },
      ],
    },
    zapier: {},
  },
};
