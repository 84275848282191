import React, { useEffect, useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown, Badge, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { findUpper } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { getWorkspaceData, setWorkspaceList } from "../../../../redux";
import { AUTH, LOCAL_STORAGE_ITEMS, URLS } from "../../../../constants";
import { formBody, httpClient } from "../../../../services";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { profileType } from "../../../../config/profile.config";
import { WORKSPACE } from "../../../../constants/workspace.constant";
import { copywritermenu } from "../../../menu/MenuData";

const User = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    httpClient.get(URLS.WORKSPACE.INDEX)
      .then(res => {
        dispatch(setWorkspaceList(res.data.data))
      })
  }, [])


  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const workspaceInfo = useSelector(getWorkspaceData);
  const { auth } = useSelector(state => state.auth)
  const [showAlert, setShowAlert] = useState(false)
  const { workspace } = useSelector(state => state)
  function signOut() {
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_ITEMS.WORKSPACE);
  }
  const [workspaceForm, setWorkspaceForm] = useState(false);
  const toggleWorkspaceForm = () => setWorkspaceForm(w => !w);
  const [nameInput, setnameInput] = useState('')
  function addWorkspace() {
    if (!nameInput.length)
      return
    httpClient.post(URLS.WORKSPACE.INDEX, formBody({ name: nameInput }))
      .then(reposne => {
        httpClient.get(URLS.WORKSPACE.INDEX)
          .then(res => {
            dispatch(setWorkspaceList(res.data.data))
          })
        toggleWorkspaceForm()
        setnameInput('')
      })
  }

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar text={findUpper(workspaceInfo.name)} className="sm" theme="primary"></UserAvatar>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md">
       
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{findUpper(workspaceInfo.name)} </span>
            </div>
            <div className="user-info">
              <span className="lead-text">{workspaceInfo.name} Workspace</span>
              {
                auth.user.subscription ?
                  <Badge pill color="primary" className="badge-dim">
                    {auth.user.subscription.subscription_plan.name}
                  </Badge>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="dropdown-inner">
          <LinkList>
            {
              auth[AUTH.STORE.USER]?.user_type === profileType["Workspace admin"] && workspace[WORKSPACE.STORE.WORKSPACES_LIST].map((item, index) =>
                <li
                  className="link-list link-list-opt dropdown-link"
                  onClick={() => {
                    toggle();
                  }}
                  key={index}
                >
                  <strong
                    className="d-flex flex-row"
                  >
                    {
                      item.is_default ?
                        <Icon name="check" className="pe-1 fs-6" />
                        : <div style={{ width: 27 }} />
                    }
                    <span>{item.name} Workspace</span>
                  </strong>
                </li>
              )
            }
            {
              auth[AUTH.STORE.USER]?.user_type === profileType["Workspace admin"] ?
                <li
                  className="link-list link-list-opt dropdown-link link-bdr-t"
                  onClick={() => {
                    toggle();
                    setWorkspaceForm(!workspaceForm);
                  }}
                >
                  <strong>
                    <Icon name="plus-circle" className="pe-1 fs-6" /> <span>New Workspace</span>
                  </strong>
                </li>
                : null
            }
            {
              copywritermenu.filter(i => auth[AUTH.STORE.USER]?.user_type === profileType.Admin ? true : i.link !== "/subscription-management").map((item, index) =>
                <LinkItem className="d-sm-none" key={index} link={item.link} icon={item.icon} onClick={toggle}>
                  {item.text}
                </LinkItem>
              )
            }

          </LinkList>
          <Modal
            onMouseEnter={_ => setShowAlert(true)}
            onMouseLeave={_ => setShowAlert(false)}
            isOpen={workspaceForm}
            toggle={toggleWorkspaceForm}
          >
            <ModalHeader
              toggle={toggleWorkspaceForm}
              
              close={<div />}
            >
              Create a Workspace
            </ModalHeader>
            <ModalBody>
              <form>
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Workspace Name
                  </label>
                  <div className="form-control-wrap">
                    <input value={nameInput} onChange={e => setnameInput(e.target.value)} type="text" className="form-control" id="whatsapp-business-id" placeholder="Personal" />
                  </div>
                </div>

                <div className="text-center">
                
                  <div className="sor-footer d-flex align-items-center justify-content-end">
                    <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={toggleWorkspaceForm}>
                      Cancel
                    </Button>
                    <Button color="primary" onClick={addWorkspace} className="sor-action-button position-relative" form="chatbot-addedit-form">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </ModalBody>
            {
              showAlert && auth[AUTH.STORE.USER]?.subscription?.subscription_plan?.number_of_allowed_personal_chatbots >= workspace[WORKSPACE.STORE.WORKSPACES_LIST].length ?
                <div
                  className="position-absolute start-0 h-100 w-100 top-0 alert-bg align-center justify-center"
                >
                  <Button
                    color="primary"
                    onClick={_ => {
                      toggleWorkspaceForm()
                      navigate(`${process.env.PUBLIC_URL}/subscription`);
                      setShowAlert(false)
                    }}
                  >
                    Upgrade Plan
                  </Button>
                </div>
                : null
            }
          </Modal>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/auth-login" icon="signout" onClick={signOut}>
              Sign Out
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
