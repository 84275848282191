import React, { useEffect, useState } from "react";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { Button } from "../../../../../../components/Component";
import { Spinner } from "reactstrap";
import Emitter from "../../../../../../services/eventEmitterService";
import { CONVERSATION, TOAST, URLS } from "../../../../../../constants";
import { IntegrationsConfig } from "../../../../../../config/integrations.config";
import { notify } from "../../../../../../services/toastService";
import { fetchData, store, updateConversationData } from "../../../../../../redux";
import { formBody, httpClient } from "../../../../../../services";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AddEditIntegration = () => {
  const [sorId, setSorId] = useState(IntegrationsConfig.events.sorId);
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [format, setFormat] = useState({
    header: "",
    fields: [],
    url: "",
    type: "",
  });

  const dispatch = useDispatch();
  const { brainId: brain_id } = useParams();
  const [submitting, setsubmitting] = useState(false);

  const { conversation } = useSelector((state) => state);
  useEffect(() => {
    if (
      format.type.length &&
      conversation[CONVERSATION.STORE.INTEGRATION_DATA(format.type)]?.[`is_${format.type}_integrated`]
    ) {
      setFormData(conversation[CONVERSATION.STORE.INTEGRATION_DATA(format.type)]);
    }
  }, [conversation, format]);

  useEffect(() => {
    Emitter.on(IntegrationsConfig.events.editingData, (data) => {
      if (data.type) {
        setFormat({ ...IntegrationsConfig.dataFormats[data.type], type: data.type });
      }
    });
    return () => {
      Emitter.off(IntegrationsConfig.events.editingData, {});
    };
  }, []);
  function close() {
    setsubmitting(false);
    if (isLoading) return;
    resetData();
    dispatchEvent(new Event(IntegrationsConfig.events.sorToggle));
  }
  function resetData() {
    setFormData({});
  }

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData(name, value);
  };

  function refreshPage() {
    store.dispatch(fetchData());
  }

  function onFormSubmit() {
    setsubmitting(true);
    if (Object.keys(formData).length < format.fields.length || isLoading) return;
    const integrateFormData = new FormData();
    for (const key in formData) {
      integrateFormData.append(key, formData[key]);
    }
    integrateFormData.append(`is_${format.type}_integrated`, true);
    setLoading(true);

    httpClient
      .patch(
        URLS.CHATBOT.INTEGRATION_SETTINGS(brain_id, format.type),
        formBody({ ...formData, [`is_${format.type}_integrated`]: true })
      )
      .then((res) => {
        dispatch(updateConversationData({ type: CONVERSATION.STORE.INTEGRATION_DATA(format.type), data: res.data }));
        refreshPage();
        if (!res.data[`is_${format.type}_integrated`] && res.data[`${format.type}_integration_failure_reason`]?.length)
          notify(TOAST.TYPE.ERROR, { title: res.data[`${format.type}_integration_failure_reason`] });
        else notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        if (format.type === "twilio") notify(TOAST.TYPE.ERROR, { title: err.response.data.detail });
        else
          notify(TOAST.TYPE.ERROR, {
            title: `${err.response.data.error.code} [${err.response.status}]`,
            message: `${err.response.data.error.message}`,
          });
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }
  return (
    <React.Fragment>
      <SlideOverX
        id={sorId}
        isOverlayVisible={true}
        haveOverlayToggle={true}
        onClose={(_) => {
          setsubmitting(false);
          setFormat({
            header: "",
            fields: [],
            url: "",
            type: "",
          });
          setFormData({});
        }}
        editType="subscription"
      >
        <div>
          <div>
            {/* <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} /> */}
            <div className="sor-header">
              <h5>{format.header}</h5>
            </div>
            <div className="sor-body-header-footer-backbtn w-100">
              {format.fields.map((item, index) => (
                <div key={index} className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      {item.title} *
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        placeholder={`Enter ${item.title}`}
                        className="form-control"
                        name={item.data}
                        value={formData?.[item.data]}
                        onChange={handleInputChange}
                      />
                      {submitting && !formData[item.data] && <span className="invalid">{item.title} is required</span>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" className="sor-action-button position-relative" onClick={onFormSubmit}>
                {isLoading ? <Spinner size="sm" color="light" /> : isEdit ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditIntegration;
