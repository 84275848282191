import { useDispatch } from "react-redux";
import { AUTH, LOCAL_STORAGE_ITEMS, URLS } from "../constants";
import { httpClient } from "../services";
import { setAuthData } from "../redux/slices/auth.store";
import { useSelector } from "react-redux";

export default function useAuth() {
  const dispatch = useDispatch()
  const auth = localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN) ? true : false;
  const { user, loggedIn } = useSelector(state => state.auth.auth)
  if (auth && !loggedIn) { // local storage has token, but user is not logged in
    dispatch(setAuthData({ key: AUTH.STORE.LOGGED_IN, value: true }))
  }
  if ((auth || loggedIn) && user.user_type === undefined) { // local storage has token or user is not logged in but there is no user data
    httpClient.get(URLS.AUTH.PROFILE)
      .then(res => {
        dispatch(setAuthData({ key: AUTH.STORE.USER, value: res.data }))
        localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_TYPE, res.data.user_type)
      })
      .catch(_ => {

      })
  }
  return auth;
}
