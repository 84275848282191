import React from "react";
import Head from "../../../../../layout/head/Head";
import { Col, Row } from "../../../../../components/Component";
import whatsapp from "../../../../../images/chatbot/whatsapp.png";
import telegram from "../../../../../images/chatbot/telegram.png";
import twilio from "../../../../../images/chatbot/twilio.png";
import messenger from "../../../../../images/chatbot/messenger.png";
import instagram from "../../../../../images/chatbot/instagram.png";
import zapier from "../../../../../images/chatbot/zapier.png";
import AddEditIntegration from "../components/integrate/AddEditIntegration";
import IntegrationSection from "../../../../../components/card/IntegrationSection";
import AddEditZapierIntegration from "../components/integrate/AddEditZapierIntegration";

const ViewChatbotIntegration = () => {
  return (
    <React.Fragment>
      <Head title="Integrate"></Head>
      <div>
        <Row>
          <AddEditIntegration />
          <AddEditZapierIntegration />
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={whatsapp}
              cardTitle="Whatsapp"
              cardText="Connect your AI to respond to WhatsApp messages"
              type="whatsapp"
            />
          </Col>
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={twilio}
              cardTitle="Twilio"
              cardText="Connect your AI to respond to SMS messages"
              type="twilio"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={telegram}
              cardTitle="Telegram"
              cardText="Publish your AI as a Telegram bot"
              type="telegram"
            />
          </Col>
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={messenger}
              cardTitle="Messenger"
              cardText="Connect your AI to respond to Facebook messages"
              type="messenger"
            />
          </Col>
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={instagram}
              cardTitle="Instagram"
              cardText="Connect your AI to respond to Instagram messages"
              type="instagram"
            />
          </Col>
          <Col lg={12} className="mb-4">
            <IntegrationSection
              imgSrc={zapier}
              cardTitle="Zapier"
              cardText="Your chatbot can interact with one or more of the 6000+ services supported by Zapier. Create your Zaps here."
              type="zapier"
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ViewChatbotIntegration;
