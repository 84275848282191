import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONVERSATION } from "../../constants";
import { httpClient } from "../../services";

//ASYNC REQUEST
export const fetchChatListData = createAsyncThunk("conversation/fetchChatListData", async (qs, { getState }) => {
  const state = getState().conversation;
  let url = `${state[CONVERSATION.STORE.CHAT_LIST_ENDPOINT]}?limit=${
    state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.LIMIT]
  }&offset=${state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.OFFSET]}&brain_id=${
    state[CONVERSATION.STORE.BRAIN_ID]
  }`;
  if (state.chat_producer !== null) {
    url += `&chat_producer=${state.chat_producer}`;
  }
  const response = await httpClient.get(url);
  return response.data;
});

//GETTER
export const getChatListData = ({ conversation }) => {
  return conversation[CONVERSATION.STORE.CHAT_LIST_DATA];
};
export const getSelectedChatInfo = ({ conversation }) => {
  return conversation[CONVERSATION.STORE.SELECTED_CHAT_INFO];
};

export const getChatListReqBusyStatus = ({ conversation }) => {
  return conversation[CONVERSATION.STORE.REQUEST.KEY][CONVERSATION.STORE.REQUEST.VALUE.BUSY];
};

export const getChatMobileViewStatus = ({ conversation }) => {
  return conversation[CONVERSATION.STORE.MOBILE_VIEW];
};
export const getChatProducerType = ({ conversation }) => {
  return conversation[CONVERSATION.STORE.CHAT_PRODUCE_TYPE];
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    [CONVERSATION.STORE.PID]: "conversation",
    [CONVERSATION.STORE.BRAIN_ID]: "",
    [CONVERSATION.STORE.CHAT_LIST_ENDPOINT]: "",
    // query strings -> common
    [CONVERSATION.STORE.QSC.KEY]: {
      [CONVERSATION.STORE.QSC.VALUE.TOTAL]: 0,
      [CONVERSATION.STORE.QSC.VALUE.LIMIT]: 100,
      [CONVERSATION.STORE.QSC.VALUE.OFFSET]: 0,
    },
    // request
    [CONVERSATION.STORE.REQUEST.KEY]: {
      [CONVERSATION.STORE.REQUEST.VALUE.BUSY]: false,
    },
    // response
    [CONVERSATION.STORE.IS_CONCAT_DATA]: false,
    [CONVERSATION.STORE.CHAT_LIST_DATA]: [],
    [CONVERSATION.STORE.SELECTED_CHAT_INFO]: null,

    [CONVERSATION.STORE.MOBILE_VIEW]: false,
    [CONVERSATION.STORE.CHAT_PRODUCE_TYPE]: null,

    // whatsapp integration
    [CONVERSATION.STORE.INTEGRATION_DATA("whatsapp")]: {
      is_whatsapp_integrated: false,
    },
    [CONVERSATION.STORE.INTEGRATION_DATA("twilio")]: {
      is_twilio_integrated: false,
    },
    [CONVERSATION.STORE.INTEGRATION_DATA("telegram")]: {
      is_telegram_integrated: false,
    },
    [CONVERSATION.STORE.INTEGRATION_DATA("messenger")]: {
      is_messenger_integrated: false,
    },
    [CONVERSATION.STORE.INTEGRATION_DATA("zapier")]: {
      is_zapier_integrated: false,
    },
  },
  reducers: {
    setBrainId(state, { payload }) {
      state[CONVERSATION.STORE.BRAIN_ID] = payload[CONVERSATION.STORE.BRAIN_ID];
    },
    setChatListEndpoint(state, { payload }) {
      state[CONVERSATION.STORE.CHAT_LIST_ENDPOINT] = payload[CONVERSATION.STORE.CHAT_LIST_ENDPOINT];
    },
    setChatListData(state, { payload }) {
      state[CONVERSATION.STORE.CHAT_LIST] = payload[CONVERSATION.STORE.CHAT_LIST_DATA];
    },
    setSelectedChatInfo(state, { payload }) {
      state[CONVERSATION.STORE.SELECTED_CHAT_INFO] = payload[CONVERSATION.STORE.SELECTED_CHAT_INFO];
    },
    resetConversationStore(state, { payload }) {
      state[CONVERSATION.STORE.CHAT_LIST_ENDPOINT] = "";
      state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.TOTAL] = 0;
      state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.LIMIT] = 20;
      state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.OFFSET] = 0;
      state[CONVERSATION.STORE.CHAT_LIST_DATA] = [];
      state[CONVERSATION.STORE.SELECTED_CHAT_INFO] = null;
    },
    setDataConcatStatus(state, { payload }) {
      state[CONVERSATION.STORE.IS_CONCAT_DATA] = payload[CONVERSATION.STORE.IS_CONCAT_DATA];
    },
    setChatMobileViewStatus(state, { payload }) {
      state[CONVERSATION.STORE.MOBILE_VIEW] = payload[CONVERSATION.STORE.MOBILE_VIEW];
    },
    updateConversationData(state, { payload: { type, data } }) {
      state[type] = { ...state[type], ...data };
    },
    setChatProducerType(state, { payload }) {
      state[CONVERSATION.STORE.CHAT_PRODUCE_TYPE] = payload[CONVERSATION.STORE.CHAT_PRODUCE_TYPE];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatListData.pending, (state, action) => {
        state[CONVERSATION.STORE.REQUEST.KEY][CONVERSATION.STORE.REQUEST.VALUE.BUSY] = true;
      })
      .addCase(fetchChatListData.fulfilled, (state, action) => {
        state[CONVERSATION.STORE.REQUEST.KEY][CONVERSATION.STORE.REQUEST.VALUE.BUSY] = false;

        state[CONVERSATION.STORE.QSC.KEY][CONVERSATION.STORE.QSC.VALUE.TOTAL] = action.payload.meta.count.total;

        if (state[CONVERSATION.STORE.IS_CONCAT_DATA]) {
          state[CONVERSATION.STORE.CHAT_LIST_DATA] = state[CONVERSATION.STORE.CHAT_LIST_DATA].concat(
            action.payload.data
          );
        } else {
          state[CONVERSATION.STORE.CHAT_LIST_DATA] = action.payload.data;
        }

        state[CONVERSATION.STORE.SELECTED_CHAT_INFO] = state[CONVERSATION.STORE.SELECTED_CHAT_INFO]
          ? state[CONVERSATION.STORE.SELECTED_CHAT_INFO]
          : action.payload.data
          ? action.payload.data[0]
          : null;
      })
      .addCase(fetchChatListData.rejected, (state, action) => {
        state[CONVERSATION.STORE.REQUEST.KEY][CONVERSATION.STORE.REQUEST.VALUE.BUSY] = false;
      });
  },
});
export const {
  setBrainId,
  setChatListEndpoint,
  setChatListData,
  setSelectedChatInfo,
  resetConversationStore,
  setChatMobileViewStatus,
  updateConversationData,
  setChatProducerType,
} = conversationSlice.actions;
export default conversationSlice.reducer;
