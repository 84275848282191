import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon } from "../Component";
import Emitter from "../../services/eventEmitterService";
import { IntegrationsConfig } from "../../config/integrations.config";
import { useSelector } from "react-redux";
import { CONVERSATION, URLS } from "../../constants";
import { formBody, httpClient } from "../../services";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { updateConversationData } from "../../redux";
import { ZapierIntegrationConfig } from "../../config/zapierIntegration.config";

const IntegrationSection = ({ imgSrc = undefined, cardTitle = "", cardText = "", type = "" }) => {
  const { conversation } = useSelector((state) => state);
  const { brainId: brain_id } = useParams();
  const dispatch = useDispatch();

  return (
    <Card className="shadow-sm hover-border-indigo">
      <CardBody className="d-flex flex-row justify-between align-center">
        <div className="d-flex flex-row align-center">
          <img
            className="rounded-top me-1"
            style={{
              height: 20,
              width: 20,
            }}
            src={imgSrc}
            alt={cardTitle}
          />
          <h5
            style={{
              marginRight: 10,
              marginBottom: "0.1rem",
            }}
          >
            {cardTitle}
          </h5>
          <CardText
            style={{
              marginBottom: "-0.1rem",
            }}
          >
            {cardText}
          </CardText>
        </div>
        {conversation[CONVERSATION.STORE.INTEGRATION_DATA(type)]?.[`is_${type}_integrated`] ? (
          <div className="d-flex flex-row align-center">
            <div style={{ marginRight: -10, marginBottom: -5 }}>
              <Icon name="check-circle-fill" className="fs-6" style={{ color: "#20C997" }}></Icon>
            </div>
            <UncontrolledDropdown>
              <DropdownToggle className="bg-transparent border-0">
                <Icon name="more-h" className="text-dark"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(ev) => {
                        ev.preventDefault();
                        if (type === "zapier") {
                          Emitter.emit(ZapierIntegrationConfig.events.editingData, {});
                          dispatchEvent(new Event(ZapierIntegrationConfig.events.sorToggle));
                        } else {
                          Emitter.emit(IntegrationsConfig.events.editingData, {
                            type,
                          });
                          dispatchEvent(new Event(IntegrationsConfig.events.sorToggle));
                        }
                      }}
                    >
                      Edit
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      onClick={(ev) => {
                        ev.preventDefault();
                        httpClient
                          .patch(
                            URLS.CHATBOT.INTEGRATION_SETTINGS(brain_id, type),
                            formBody({ [`is_${type}_integrated`]: false })
                          )
                          .then((res) => {
                            dispatch(
                              updateConversationData({
                                type: CONVERSATION.STORE.INTEGRATION_DATA(type),
                                data: res.data,
                              })
                            );
                          });
                      }}
                    >
                      Disconnect
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : type === "zapier" ? (
          <Button
            color="primary"
            onClick={(_) => {
              Emitter.emit(ZapierIntegrationConfig.events.editingData, {});
              dispatchEvent(new Event(ZapierIntegrationConfig.events.sorToggle));
            }}
          >
            Integrate
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={(_) => {
              Emitter.emit(IntegrationsConfig.events.editingData, {
                type,
              });
              dispatchEvent(new Event(IntegrationsConfig.events.sorToggle));
            }}
          >
            Integrate
          </Button>
        )}
      </CardBody>
    </Card>
  );
};
export default IntegrationSection;
