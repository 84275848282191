import React from "react";
import { Button, Card, CardBody, CardText } from "reactstrap";

import { Icon } from "../../../../../../components/Component";
import Emitter from "../../../../../../services/eventEmitterService";
import { TrainConfig } from "../../../../../../config/train.config";

const TrainUploadItem = ({ iconClass = undefined, cardTitle = "", cardText = "", type = "" }) => {
  function handleUpload() {
    if (type === "document") {
      Emitter.emit(TrainConfig.events.document.editingData, {});
      dispatchEvent(new Event(TrainConfig.events.document.sorToggle));
    }

    if (type === "text") {
      Emitter.emit(TrainConfig.events.text.editingData, {});
      dispatchEvent(new Event(TrainConfig.events.text.sorToggle));
    }

    if (type === "web") {
      Emitter.emit(TrainConfig.events.web.editingData, {});
      dispatchEvent(new Event(TrainConfig.events.web.sorToggle));
    }
    if (type === "external-source") {
      Emitter.emit(TrainConfig.events.externalSource.editingData, {});
      dispatchEvent(new Event(TrainConfig.events.externalSource.sorToggle));
    }
  }

  function getButtonName() {
    if (type === "external-source") {
      return "Connect";
    } else {
      return "Upload";
    }
  }

  return (
    <Card className="shadow-sm hover-border-indigo">
      <CardBody className="d-flex flex-row justify-between align-center">
        <div className="d-flex flex-row align-center">
          <Icon className="fs-1 me-2" name={iconClass}></Icon>
          <h5
            style={{
              marginRight: 10,
              marginBottom: "0.1rem",
            }}
          >
            {cardTitle}
          </h5>
          <CardText
            style={{
              marginBottom: "-0.1rem",
            }}
          >
            {cardText}
          </CardText>
        </div>
        <Button color="primary" onClick={handleUpload}>
          {getButtonName()}
        </Button>
      </CardBody>
    </Card>
  );
};
export default TrainUploadItem;
