import React from 'react'
import classNames from "classnames"
import { useTheme } from '../../layout/provider/Theme';

function AppWrap({ className, ...props }) {
  const theme = useTheme();

  const compClass = classNames({
    "nk-wrap": true,
    "mobile-padding": theme.sidebarMobile || !theme.sidebarVisibility,
    "menu-padding": !theme.sidebarMobile && theme.sidebarVisibility,
    [`${className}`]: className,
  });
  return (
    <div className={compClass}>
      {props.children}
    </div>
  )
}

export default AppWrap