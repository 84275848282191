import axios from "axios";
import { LOCAL_STORAGE_ITEMS, URLS } from "../constants";
import { httpClientConfig } from "../config/httpClient.config";
import { profileType } from "../config/profile.config";
import { formBody } from "./convertFormData";

const httpClient = axios;

httpClient.defaults.baseURL = URLS.ROOT.BASE_URL;
httpClient.defaults.headers.common["Accept"] = httpClientConfig.options.headers["Accept"];
httpClient.defaults.headers.common["Content-Type"] = httpClientConfig.options.headers["Content-Type"];

httpClient.interceptors.request.use(
  // add data to the header
  async (config) => {
    const user_type = await localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_TYPE);
    const access_token_timestamp = await localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP);
    const refresh_token = await localStorage.getItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN);
    if (config?.url !== URLS.AUTH.LOGIN && access_token_timestamp !== null && new Date().getTime() > parseInt(access_token_timestamp)) {
      try {
        httpClient.post(
          URLS.AUTH.LOGIN,
          formBody(
            parseInt(user_type) === profileType.Admin ?
              {
                client_id: "P00cEFNeTza31alp0p44dYQZeVOt1oheb9tTmrTu",
                client_secret: "j4R0Ftjps6Wi7xXUQ6dDD2JqTB9g0ZI59uN1isTvC08wCmLnEHjvh951aOoEvsTyPM1AEKjkz0q9IM50gwyG8tSg4UfC3AzeODhkLHwfgTg8wCwf6F5TcYPP9KzEYv6N",
                grant_type: "refresh_token",
                refresh_token
              }
              : {
                client_id: "epaOFTViB0Fn3OJHCZHhqgVaVnpRogaeAJUevXpg",
                client_secret: "aJNChIniyMtuLhz8sAwSTbovaN0vd2K7pVczVUqpA9alY4TVGSpOshS38XxfQJJI31IUeJJMWw9ShO9jTcQD9zGD0WDZ28P9XjaKoWbI0RhsRpI3wK360SiJQtixIQmr",
                grant_type: "refresh_token",
                refresh_token
              }
          )
        )
          .then(res => {
            localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN]);
            localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN]);
            localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP, new Date().getTime() + res.data.expires_in * 1000 - 60000); // having 1 minute(60000 ms) as threshold

            config.headers.Authorization = "Bearer " + res.data.access_token;
            return config
          })
          .catch(e => {
            if (e.response.status === 401) {
              localStorage.clear()
              if (!window.location.href.includes('auth-login') && !window.location.href.includes('admin-login'))
                window.location.href = `${process.env.PUBLIC_URL}/${parseInt(user_type) === profileType.Admin ? 'admin' : 'auth'}-login`
            }
            // return config
          })
      } catch (error) {
        return config
      }
    } else {
      const access_token = await localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
      if (access_token) {
        config.headers.Authorization = "Bearer " + access_token;
      }
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const user_type = await localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_TYPE);
    const refresh_token = await localStorage.getItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN);

    // alert(res.message)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (error.response.config.url === URLS.AUTH.LOGIN) {
        localStorage.clear()
        if (!window.location.href.includes('auth-login') && !window.location.href.includes('admin-login'))
          window.location.href = `${process.env.PUBLIC_URL}/${parseInt(user_type) === profileType.Admin ? 'admin' : 'auth'}-login`
        // nav to logout
      }
      else {
        try {
          if (refresh_token)
            httpClient.post(
              URLS.AUTH.LOGIN,
              formBody(
                parseInt(user_type) === profileType.Admin ?
                  {
                    client_id: "P00cEFNeTza31alp0p44dYQZeVOt1oheb9tTmrTu",
                    client_secret: "j4R0Ftjps6Wi7xXUQ6dDD2JqTB9g0ZI59uN1isTvC08wCmLnEHjvh951aOoEvsTyPM1AEKjkz0q9IM50gwyG8tSg4UfC3AzeODhkLHwfgTg8wCwf6F5TcYPP9KzEYv6N",
                    grant_type: "refresh_token",
                    refresh_token
                  }
                  : {
                    client_id: "epaOFTViB0Fn3OJHCZHhqgVaVnpRogaeAJUevXpg",
                    client_secret: "aJNChIniyMtuLhz8sAwSTbovaN0vd2K7pVczVUqpA9alY4TVGSpOshS38XxfQJJI31IUeJJMWw9ShO9jTcQD9zGD0WDZ28P9XjaKoWbI0RhsRpI3wK360SiJQtixIQmr",
                    grant_type: "refresh_token",
                    refresh_token
                  }
              )
            )
              .then(res => {
                localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN]);
                localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN]);
                localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP, new Date().getTime() + res.data.expires_in * 1000 - 60000); // having 1 minute(60000 ms) as threshold
              })
              .catch(e => {
                if (e.response.status === 401) {
                  localStorage.clear()
                  if (!window.location.href.includes('auth-login') && !window.location.href.includes('admin-login'))
                    window.location.href = `${process.env.PUBLIC_URL}/${parseInt(user_type) === profileType.Admin ? 'admin' : 'auth'}-login`
                }
              })
          else {
            localStorage.clear()
            if (!window.location.href.includes('auth-login') && !window.location.href.includes('admin-login'))
              window.location.href = `${process.env.PUBLIC_URL}/${parseInt(user_type) === profileType.Admin ? 'admin' : 'auth'}-login`
          }
        } catch (error) {
        }
      }

      // call access_token API with refresh token
      // Then set the LOCAL_STORAGE_ITEMS.ACCESS_TOKEN item from API response
    }

    if (!error.response) {
      //when server is unreachable, handle this
      // error.response = {
      //   data: {
      //     error_code: 'CNP',
      //   },
      // };
    }
    return Promise.reject(error);
  }
);

export { httpClient };
