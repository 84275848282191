import ReconnectingWebsocket from "reconnectingwebsocket";
import { socketConfig } from "../config/socket.config";
import { consoleLog } from "../utils/Utils";

const rwsClient = {
  openRWS: async ({ route, onMessage = (msg) => consoleLog(msg) }) => {
    const host = socketConfig.getRoute({ name: route });

    if (!host) {
      consoleLog({ openWSL: `No such channel: ${route}` });
      return false;
    }

    const rws = new ReconnectingWebsocket(host, null, socketConfig.options());

    rws.onopen = function () {
      consoleLog("RWS opened.");
    };

    rws.onclose = function () {
      consoleLog("RWS closed.");
    };

    rws.onmessage = onMessage;

    return rws;
  },
  closeRWS: async (rws) => {
    if (rws) {
      await rws.close();
      return;
    }
  },
};

export { rwsClient };
