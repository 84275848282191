import React, { useState } from "react";
import { ZapierIntegrationConfig } from "../../../../../../config/zapierIntegration.config";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { Button, Icon } from "../../../../../../components/Component";
import ZapierAccrodion from "./ZapierAccrodion";

const AddEditZapierIntegration = () => {
  const [sorId, setSorId] = useState(ZapierIntegrationConfig.events.sorId);
  function close() {
    dispatchEvent(new Event(ZapierIntegrationConfig.events.sorToggle));
    dispatchEvent(new Event(ZapierIntegrationConfig.events.refresh));
  }
  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={true} haveOverlayToggle={true} editType="zapier">
        <div>
          <div>
            <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
            <div className="sor-header"></div>
            <div className="sor-body-header-footer-backbtn w-100">
              <div>
                <h5> Create a Zap</h5>
                <p>
                  Follow the steps to integrate MagnushAI and Zapier. Stuck? Check out our Help Center for details and
                  troubleshooting.
                </p>
              </div>

              <div className="mt-4">
                <ZapierAccrodion />
              </div>
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button color="primary" className="sor-action-button position-relative" onClick={close}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditZapierIntegration;
