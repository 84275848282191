import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/HeaderCopywriter";
// import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

import FileManagerProvider from "../pages/app/file-manager/components/Context";
import { useTheme } from "./provider/Theme";

const Layout = ({ title, ...props }) => {
  const theme = useTheme()

  return (
    <FileManagerProvider>
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppMain>
          {
            theme.sidebarVisibility && !theme.sidebarMobile ?
              <Sidebar fixed />
              : null
          }
          <AppWrap>
            <Header fixed />
            <Outlet />
            {/* <Footer /> */}
          </AppWrap>
        </AppMain>
      </AppRoot>
    </FileManagerProvider>
  );
};
export default Layout;
