export const AiConversationConfig = {
    events: {
      name: "ai-conversation",
      // refresh-index-data
      refresh: `rid-ai-conversation`,
      // slideover-right
      sorId: "ai-conversation",
      sorToggle: "sor-toggle-ai-conversation",
      // editing-data
      editingData: "edit-ai-conversation-data",
    },
  };
  