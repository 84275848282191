import React from "react";
import classNames from "classnames";

const Table = ({ striped, border, hover, responsive, header, ...props }) => {
  const tableClass = classNames({
    table: true,
    "table-bordered": border,
    "table-borderless": !border,
    "table-striped": striped,
    "table-hover": hover,
  });
  return (
    <div className={responsive ? "table-responsive table" : ""}>
      <table className={tableClass}>
        <thead className={`table-light`}>
          <tr>
            {header.map((item, idx) => {
              return (
                <th key={idx} width={item.width}>
                  {item.text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </div>
  );
};
Table.defaultProps = {
  header: [],
  data: [],
};
export default Table;
