import React, { useEffect, useState } from "react";
import { XTable, XTableRow, XTableRowItem } from "../../../../components/xtable";
import { CONVERSATION, URLS } from "../../../../constants";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { useSelector } from "react-redux";
import { getData, setChatMobileViewStatus, setSelectedChatInfo } from "../../../../redux";
import { getUTCAwareTime } from "../chatbot/composites/getUTCAwareTime";
import { shortifyText } from "../../../../utils/Utils";
import { Badge } from "reactstrap";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../../../components/Component";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const LogIndex = () => {
  const logItemTableHeaders = [
    { text: "Type", width: "7%", sort: null },
    { text: "Title", width: "10%", sort: null },
    { text: "Brain", width: "10%", sort: null },
    { text: "Chat", width: "10%", sort: null },
    { text: "Created At", width: "7%", sort: null },
    { text: "Description", width: "45%", sort: null },
  ];
  const logType = {
    error: "Error",
  };
  const logTypeColor = {
    error: "danger",
  };
  const [logItemData, setLogItemData] = useState([]);
  const resData = useSelector(getData);

  useEffect(() => {
    if (resData) {
      setLogItemData(resData);
    }
  }, [resData]);

  const dispatch = useDispatch();
  function chatItemClick(item) {
    dispatch(setSelectedChatInfo({ [CONVERSATION.STORE.SELECTED_CHAT_INFO]: item }));
    if (window.innerWidth < 860) {
      dispatch(setChatMobileViewStatus({ [CONVERSATION.STORE.MOBILE_VIEW]: true }));
    }
  }

  return (
    <React.Fragment>
      <Head title="Logs" />
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span></span>
          </div>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="page-title">Logs</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div>
          <XTable
            xtId="lead-item"
            headers={logItemTableHeaders}
            endpoint={`${URLS.CHATBOT.LOGS.INDEX}`}
            wrapperClass="lead-list-wrapper"
          >
            {logItemData &&
              logItemData.map((item) => {
                return (
                  <XTableRow key={item.id}>
                    <XTableRowItem>
                      <Badge className="badge-dim" color={logTypeColor[item.notification_type]}>
                        {logType[item.notification_type]}
                      </Badge>
                    </XTableRowItem>
                    <XTableRowItem>{item.title}</XTableRowItem>
                    <XTableRowItem>
                      {item.brain && (
                        <Link to={`${process.env.PUBLIC_URL}/chats/${item.brain.id}/train`} className="link">
                          <span> {item.brain.name}</span>
                        </Link>
                      )}
                    </XTableRowItem>
                    <XTableRowItem>
                      {item.chat && (
                        <Link to={`${process.env.PUBLIC_URL}/chats/${item.brain.id}/conversation`} className="link">
                          <span onClick={() => chatItemClick(item.chat)}>
                            {item.chat.visitor_name === "Visitor"
                              ? `#${shortifyText(item.chat.id, -5)}`
                              : `${item.chat.visitor_name}`}
                          </span>
                        </Link>
                      )}
                    </XTableRowItem>
                    <XTableRowItem>{getUTCAwareTime(item.created_at)}</XTableRowItem>
                    <XTableRowItem>
                      <div>{item.description}</div>
                    </XTableRowItem>
                  </XTableRow>
                );
              })}
          </XTable>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default LogIndex;
