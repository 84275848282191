import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { Col, Row } from "../../../../../components/Component";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Button, Label, Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import { formBody, httpClient } from "../../../../../services";
import { TOAST, URLS } from "../../../../../constants";
import { notify } from "../../../../../services/toastService";
import SettingsInput from "../../../../../components/input/SettingsInput";
import { getChatbotChatSettingsDataModel, getChatbotSubscriptionDataModel } from "../helper";
import UploadAvatar from "../../../../../components/input/UploadAvatar/UploadAvatar";
import SettingsSelectInput from "../../../../../components/input/SettingsSelectInput";
import YouChat from "../composites/chat/YouChat";
import MeChat from "../composites/chat/MeChat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../../../../layout/provider/Theme";
import { consoleLog } from "../../../../../utils/Utils";

const ViewChatbotBranding = () => {
  const [chatbotSettings, setChatbotSettings] = useState(getChatbotChatSettingsDataModel);
  const [previousColor, setPreviousColor] = useState("#ffffff");
  const [isThemeColorLoading, setThemeColorLoading] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [subscriptionPackage, setSubscriptionPackage] = useState(getChatbotSubscriptionDataModel);
  const [loading, setLoading] = useState(false);
  const { brainId } = useParams();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    httpClient.get(URLS.CHATBOT.SETTINGS.CHAT_SETTINGS(brainId)).then((res) => {
      setChatbotSettings(res.data);
      setPreviousColor(res.data.theme);
    });

    httpClient.get(URLS.AUTH.PROFILE).then((res) => {
      setSubscriptionPackage((preData) => {
        return {
          ...preData,
          branding_removal_enabled: res.data?.subscription?.subscription_plan?.branding_removal_enabled || false,
        };
      });
    });
  }, [brainId]);

  const updateChatSettingsFormData = (key, value) => {
    setChatbotSettings((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleChatSettingsInputChange = (event) => {
    const { name, value } = event.target;
    updateChatSettingsFormData(name, value);
  };

  const handleChatbotAvatarIcon = (event) => {
    const url = URLS.CHATBOT.SETTINGS.CHAT_SETTINGS(brainId);
    const saveFormData = new FormData();
    saveFormData.append("icon", event);
    httpClient
      .patch(url, saveFormData)
      .then(() => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("chat-settings-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => setLoading(false));
  };

  function onhandleChatSettingsSave(key, callBack) {
    if (loading) return;
    setLoading(true);
    const url = URLS.CHATBOT.SETTINGS.CHAT_SETTINGS(brainId);
    const saveFormData = new FormData();
    saveFormData.append(key, chatbotSettings[key]);
    httpClient
      .patch(url, saveFormData)
      .then(() => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        callBack();
      })
      .catch((err) => {
        consoleLog("chat-settings-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => setLoading(false));
  }

  function handleDisplayColorPicker() {
    setDisplayColorPicker(true);
  }
  function handleCloseColorPicker() {
    setChatbotSettings((preData) => {
      return {
        ...preData,
        theme: previousColor,
      };
    });

    setDisplayColorPicker(false);
  }
  async function handleSaveThemeColor() {
    setThemeColorLoading(true);
    await httpClient
      .patch(
        URLS.CHATBOT.SETTINGS.CHAT_SETTINGS(brainId),
        formBody({
          theme: chatbotSettings.theme,
        })
      )
      .then((res) => {
        setPreviousColor(res.data.theme);
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("handleSaveThemeColor-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setDisplayColorPicker(false);
        setThemeColorLoading(false);
      });
  }
  const theme = useTheme();

  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <div>
        <Row className="my-4 d-flex flex-row justify-between">
          <Col lg={6}>
            <div>
              <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
                Branding
              </p>
              <p className="text-xs text-gray-500 ">Shape the chatbot to your brand and make it feel like your own.</p>
            </div>
            <div
              style={{
                height: "70vh",
                overflow: "scroll",
              }}
            >
              <div className="mb-2">
                <SettingsInput
                  title={"Title"}
                  subTitle=""
                  input_name={"title"}
                  input_placeholder={"Assistant"}
                  input_value={chatbotSettings.title}
                  onChange={handleChatSettingsInputChange}
                  onSubmit={(callBack) => {
                    onhandleChatSettingsSave("title", callBack);
                  }}
                />
                <p className="text-xs text-gray-500 -mt-2">
                  This is the name that will be displayed in the header of the chatbot window.
                </p>
              </div>
              <div className="mb-2">
                <SettingsInput
                  title={"Placeholder Text"}
                  subTitle=""
                  input_name={"placeholder_text"}
                  input_placeholder={"Ex: Type your message..."}
                  input_value={chatbotSettings.placeholder_text}
                  onChange={handleChatSettingsInputChange}
                  onSubmit={(callBack) => {
                    onhandleChatSettingsSave("placeholder_text", callBack);
                  }}
                />
                <p className="text-xs text-gray-500 -mt-2">
                  This text will be displayed inside of the message input field.
                </p>
              </div>
              <div className="mb-2">
                <SettingsInput
                  title={"Greetings"}
                  subTitle=""
                  input_name={"welcome_message"}
                  input_placeholder={"Ex: How can I assist you today?"}
                  input_value={chatbotSettings.welcome_message}
                  onChange={handleChatSettingsInputChange}
                  onSubmit={(callBack) => {
                    onhandleChatSettingsSave("welcome_message", callBack);
                  }}
                />
                <p className="text-xs text-gray-500 -mt-2">
                  This will be the first message shown in the chat, welcoming the user.
                </p>
              </div>

              <div
                className="mb-2 position-relative"
                onMouseEnter={(_) => setShowAlert(true)}
                onMouseLeave={(_) => setShowAlert(false)}
              >
                <div>
                  <SettingsSelectInput
                    title={"Show branding"}
                    subTitle=""
                    input_name={"should_show_branding"}
                    input_placeholder={"Select"}
                    input_value={chatbotSettings.should_show_branding}
                    selectOptions={[
                      { title: "Yes", value: true },
                      { title: "No", value: false },
                    ]}
                    optionTextKey="title"
                    optionValueKey="value"
                    onChange={handleChatSettingsInputChange}
                    onSubmit={(callBack) => {
                      onhandleChatSettingsSave("should_show_branding", callBack);
                    }}
                  />
                  <p className="text-xs text-gray-500 -mt-2">Show the "Powered by MagnusAI" label</p>
                </div>
                {showAlert && !subscriptionPackage.branding_removal_enabled ? (
                  <div className="position-absolute start-0 h-100 w-100 top-0 alert-bg align-center justify-center">
                    <Button
                      color="primary"
                      onClick={(_) => {
                        navigate(`${process.env.PUBLIC_URL}/subscription`);
                      }}
                    >
                      Upgrade Plan
                    </Button>
                  </div>
                ) : null}
              </div>

              <div className="mb-2">
                <Label className="form-label">Icon</Label>
                <UploadAvatar
                  key={`avatar-${chatbotSettings.icon}`}
                  previousAvatar={chatbotSettings.icon}
                  onChange={handleChatbotAvatarIcon}
                />
              </div>

              <div>
                <div>
                  <label className="form-label">Theme</label>
                </div>
                <div className="relative">
                  <div className="color-swatch" onClick={handleDisplayColorPicker}>
                    <div className="color-placeholder" style={{ backgroundColor: chatbotSettings.theme }}></div>
                  </div>
                  {displayColorPicker && (
                    <div className="color-palettes">
                      <div className="color-palettes-box">
                        <HexColorPicker
                          color={chatbotSettings.theme}
                          onChange={(color) =>
                            setChatbotSettings((preData) => {
                              return {
                                ...preData,
                                theme: color,
                              };
                            })
                          }
                        />

                        <HexColorInput
                          color={chatbotSettings.theme}
                          onChange={(color) =>
                            setChatbotSettings((preData) => {
                              return {
                                ...preData,
                                theme: color,
                              };
                            })
                          }
                        />
                        <div className="flex justify-between">
                          <Button
                            outline
                            color="primary"
                            className="btn-dim w-50 me-1 flex items-center justify-center"
                            onClick={handleCloseColorPicker}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className="btn w-50 ms-1 flex items-center justify-center"
                            onClick={handleSaveThemeColor}
                          >
                            {isThemeColorLoading ? <Spinner size="sm" color="light" /> : "Save"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <div>
                  <SettingsSelectInput
                    title={"Show popover text"}
                    subTitle=""
                    input_name={"is_popover_text_enabled"}
                    input_placeholder={"Select"}
                    input_value={chatbotSettings.is_popover_text_enabled}
                    selectOptions={[
                      { title: "Yes", value: true },
                      { title: "No", value: false },
                    ]}
                    optionTextKey="title"
                    optionValueKey="value"
                    isLoading={loading}
                    onChange={handleChatSettingsInputChange}
                    onSubmit={(callBack) => {
                      onhandleChatSettingsSave("is_popover_text_enabled", callBack);
                    }}
                  />
                  <p className="text-xs text-gray-500 -mt-2"></p>
                </div>
              </div>
            </div>
          </Col>
          {theme.sidebarVisibility && !theme.sidebarMobile ? (
            <Col lg={6}>
              <div
                className="w-100 justify-center align-center rounded-lg"
                style={{ height: "80vh", marginTop: "-1.5rem", backgroundColor: "#fafafa" }}
              >
                <Col lg={8}>
                  <div
                    className="w-full border border-gray-500 rounded-lg bg-white shadow-lg"
                    style={{ maxHeight: "70vh" }}
                  >
                    <div
                      className="chat-header rounded-top flex"
                      style={{ backgroundColor: chatbotSettings.theme, color: "#ffffff" }}
                    >
                      <div className="w-10"></div>
                      <div className="w-80 flex justify-center items-center text-xl">
                        <p aria-multiline={false}>{chatbotSettings.title}</p>
                      </div>
                      <div className="w-10 flex justify-end items-center pr-5 ">
                        <div>
                          <FontAwesomeIcon className="cursor-pointer font-light" icon={faXmark} />
                        </div>
                      </div>
                    </div>
                    <div className="chat-body relative">
                      <YouChat
                        item={chatbotSettings.welcome_message}
                        iconColor={chatbotSettings.theme}
                        brandIcon={
                          chatbotSettings.icon
                            ? chatbotSettings.icon
                            : "https://otowebsite.s3.ap-southeast-1.amazonaws.com/images/magnusai.png"
                        }
                        bubbleColor={"#f6f8fb"}
                      ></YouChat>
                      <MeChat item={"Fly me to the moon!"} bubbleColor={chatbotSettings.theme}></MeChat>
                    </div>
                    <div className="chat-footer">
                      <div className="chat-footer-input-area ">
                        <textarea
                          placeholder={chatbotSettings.placeholder_text}
                          spellCheck="false"
                          required
                          value={chatbotSettings.placeholder_text}
                        ></textarea>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="32px" height="32px">
                            <g
                              fill={chatbotSettings.theme}
                              fillRule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              strokeLinecap="butt"
                              strokeLinejoin="miter"
                              strokeMiterlimit="10"
                              strokeDasharray=""
                              strokeDashoffset="0"
                              fontFamily="none"
                              fontWeight="none"
                              fontSize="none"
                              textAnchor="none"
                            >
                              <g transform="scale(8.53333,8.53333)">
                                <path d="M6,3c-1.10457,0 -2,0.89543 -2,2v6c0.00133,0.84895 0.53848,1.60455 1.33984,1.88477c0.212,0.07527 0.43519,0.11422 0.66016,0.11523c0.00716,0.00004 0.01432,0.00004 0.02148,0l11.97852,2l-11.97852,2.00195c-0.00716,-0.00069 -0.01432,-0.00134 -0.02148,-0.00195c-0.22562,0.00079 -0.44949,0.03975 -0.66211,0.11523c-0.8006,0.28088 -1.33685,1.03633 -1.33789,1.88477v6c0,1.10457 0.89543,2 2,2c0.34821,-0.00066 0.69022,-0.09222 0.99219,-0.26562h0.00195l20.39648,-10.8125l0.00195,-0.00391c0.36782,-0.157 0.60672,-0.51805 0.60742,-0.91797c0.00053,-0.40181 -0.23949,-0.76492 -0.60937,-0.92187l-20.39648,-10.8125c-0.30254,-0.17373 -0.64526,-0.26531 -0.99414,-0.26562z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      {chatbotSettings.should_show_branding && (
                        <div className="chat-footer-highlight-area text-center text-sm text-gray-500">
                          Powered By <span className="font-semibold">MagnusAI</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ViewChatbotBranding;
