export const SubscriptionPlanConfig = {
  events: {
    name: "subscription-plan",
    // refresh-index-data
    refresh: `rid-subscription-plan`,
    // slideover-right
    sorId: "subscription-plan",
    sorToggle: "sor-toggle-subscription-plan",
    // editing-data
    editingData: "edit-subscription-plan-data",
  },
};
