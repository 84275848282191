import { consoleLog } from "../../../../../../utils/Utils";

export const getLeadDataModel = {
  name: "",
  description: "",
  message: "",
  field_type: "other",
  brain: "",
};

export const getFieldTypes = [
  { text: "Email", value: "email" },
  { text: "Phone", value: "phone" },
  { text: "Name", value: "name" },
  { text: "Other", value: "other" },
];

export const getSerializabledLeadItemData = (rawData) => {
  const modifiedData = rawData.map((dataItem, index) => {
    if (dataItem.capture_data && dataItem?.capture_data?.length > 0) {
      const initData = {
        name: null,
        phone: null,
        email: null,
        other: [],
      };
      consoleLog("dataItem-yes", { ...initData });
      dataItem.capture_data.map((cData, index) => {
        if (cData.capture_field.field_type === "name") {
          initData.name = cData.answer;
        }
        if (cData.capture_field.field_type === "phone") {
          initData.phone = cData.answer;
        }
        if (cData.capture_field.field_type === "email") {
          initData.email = cData.answer;
        }
        if (cData.capture_field.field_type === "other") {
          initData.other = [...initData.other, { title: cData.capture_field.name, value: cData.answer }];
        }
      });
      return initData;
    } else {
      const initData = {
        name: null,
        phone: null,
        email: null,
        other: [],
      };
      consoleLog("dataItem-no", { ...initData });

      return initData;
    }
  });
  consoleLog("dataItem-modified", modifiedData);
  return modifiedData;
};
