import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon } from "../../../components/Component";
import { capitalizeName, findUpper, consoleLog, shortifyText } from "../../../utils/Utils";
import TimeAgo from "timeago-react";
import { MeChat, YouChat } from "./ChatPartials";
import { useSelector } from "react-redux";
import { getChatMobileViewStatus, getSelectedChatInfo, setChatMobileViewStatus } from "../../../redux";
import { httpClient } from "../../../services";
import { CONVERSATION, TOAST, URLS } from "../../../constants";

import { useDispatch } from "react-redux";
import { notify } from "../../../services/toastService";
import Loading from "../../../components/loading/Loading";
import { getUTCAwareTime } from "../../../utils/datetime/getUTCAwareTime";
import EditAiConversation from "./EditAiConversation";
import { AiConversationConfig } from "../../../config/aiConversation.config";

const ChatBody = () => {
  const [isLoading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [currentChatInfo, setCurrentChatInfo] = useState({ id: "", visitor_name: "", ip: "" });
  const selectedChatInfo = useSelector(getSelectedChatInfo);
  const [previousSelectedChatId, setPreviousSelectedChatId] = useState(null);
  const currentMobileViewStatus = useSelector(getChatMobileViewStatus);
  const dispatch = useDispatch();
  const [isScrollingTop, setScrollingTop] = useState(false);
  const [currentChatTotal, setChatTotal] = useState(0);
  const [currentChatLimit, setChatLimit] = useState(10);
  const [currentChatOffset, setChatOffset] = useState(0);

  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (selectedChatInfo && selectedChatInfo.visitor_name) {
      setCurrentChatInfo((prevState) => {
        return {
          ...prevState,
          id: selectedChatInfo.id,
          numeric_id: selectedChatInfo.visitor_id,
          visitor_name: selectedChatInfo.visitor_name,
          ip: selectedChatInfo.ip_address,
          updated_at: selectedChatInfo.updated_at,
        };
      });

      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatInfo]);

  function reset() {
    setChatHistory([]);
    setChatTotal(0);
    setChatOffset(0);
    setPreviousSelectedChatId(selectedChatInfo.id);
    setScrollingTop(false);
  }

  useEffect(() => {
    if (selectedChatInfo && selectedChatInfo.visitor_name) {
      if (selectedChatInfo.id === previousSelectedChatId) {
        fetchChatHistory(currentChatOffset);
      } else {
        fetchChatHistory(0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatInfo, currentChatOffset]);

  async function fetchChatHistory(chatOffset) {
    setLoading(true);
    await httpClient
      .get(
        `${URLS.CHATBOT.CONVERSATION.CHAT_HISTORY(selectedChatInfo.id)}&limit=${currentChatLimit}&offset=${chatOffset}`
      )
      .then((res) => {
        setChatTotal(res.data?.meta?.count?.total || 0);

        const resData = res.data.data?.chat_history || [];
        if (chatOffset === 0) {
          setChatHistory([...resData]);
        } else {
          setChatHistory((preData) => [...resData, ...preData]);
        }
      })
      .catch((err) => {
        consoleLog("fetch-chat-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      const scrollHeight = chatHistoryRef.current.scrollHeight;
      const height = chatHistoryRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      chatHistoryRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    if (!isScrollingTop) {
      scrollToBottom();
    }
  }, [chatHistory, isScrollingTop]);

  useEffect(() => {
    const handleScroll = () => {
      // Access the scroll position using scrollContainerRef.current.scrollTop
      const scrollPosition = chatHistoryRef.current.scrollTop;

      // Check if the scroll position is at the top
      if (scrollPosition === 0 && currentChatOffset < currentChatTotal) {
        setChatOffset(chatHistory.length / 2);
        setScrollingTop(true);
      }
    };
    // Attach the handleScroll function to the scroll event of the scrollable element
    if (chatHistoryRef.current) {
      chatHistoryRef.current.addEventListener("scroll", handleScroll);
    }

    // Remove the event listener when the component is unmounted
    return () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentChatTotal, currentChatOffset, chatHistory]);

  useEffect(() => {
    window.addEventListener(AiConversationConfig.events.refresh, () => {
      if (selectedChatInfo) {
        fetchChatHistory(0);
      }
    });
  }, [selectedChatInfo]);

  function setMobileView(status) {
    dispatch(setChatMobileViewStatus({ [CONVERSATION.STORE.MOBILE_VIEW]: status }));
  }

  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": currentMobileViewStatus,
  });

  return (
    <React.Fragment>
      <EditAiConversation />
      <div className={chatBodyClass}>
        <div className="nk-chat-head">
          <ul className="nk-chat-head-info">
            <li className="nk-chat-body-close" onClick={() => setMobileView(false)}>
              <a
                href="#hide-chat"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </li>
            <li className="nk-chat-head-user">
              <div className="user-card">
                <UserAvatar theme="purple" text={findUpper(capitalizeName(currentChatInfo.visitor_name))}></UserAvatar>
                <div className="user-info">
                  <div className="lead-text">
                    {currentChatInfo.visitor_name === "Visitor"
                      ? `${currentChatInfo.visitor_name} #${shortifyText(currentChatInfo.id, -5)}`
                      : currentChatInfo.visitor_name}
                  </div>
                  <div>
                    <span className="meta ">
                      <TimeAgo datetime={getUTCAwareTime(currentChatInfo.updated_at)} opts={{ minInterval: 60 }} />
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <SimpleBar className="nk-chat-panel" scrollableNodeProps={{ ref: chatHistoryRef }}>
          {isLoading && <Loading isLoading={isLoading} shape="bars" height={30} width={25} />}

          {chatHistory.map((item, idx) => {
            if (item.sender === "VISITOR") {
              return <MeChat key={`me-${item.id}`} item={item}></MeChat>;
            } else {
              return <YouChat key={`you-${item.id}`} item={item} meItem={chatHistory[idx - 1]}></YouChat>;
            }
          })}
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

export default ChatBody;
