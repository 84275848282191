import React, { useEffect, useState } from "react";
import { Button, Icon } from "../../../../../../components/Component";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { TrainConfig } from "../../../../../../config/train.config";
import Emitter from "../../../../../../services/eventEmitterService";
import { getTrainDocModel } from "./helper";
import { TOAST, URLS } from "../../../../../../constants";
import { useParams } from "react-router";
import { httpClient } from "../../../../../../services";
import { notify } from "../../../../../../services/toastService";
import { Spinner } from "reactstrap";
import { fetchData, store } from "../../../../../../redux";
import { consoleLog } from "../../../../../../utils/Utils";

const AddEditTrainDocument = () => {
  const [sorId, setSorId] = useState(TrainConfig.events.document.sorId);
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState(getTrainDocModel);
  const [isUploadFileClick, setUploadFileClick] = useState(false);
  const { brainId } = useParams();

  useEffect(() => {
    Emitter.once(TrainConfig.events.document.editingData, (data) => {
      if (data.id) {
        setEdit(true);
      } else {
        setEdit(false);
      }
      consoleLog("event-emitter", data);
    });

    return () => {
      consoleLog("event-emitter-destroy");
      Emitter.off(TrainConfig.events.document.editingData, {});
    };
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function close() {
    resetData();
    dispatchEvent(new Event(TrainConfig.events.document.sorToggle));
    consoleLog("close");
  }

  function resetData() {
    setFormData(getTrainDocModel);
    reset(getTrainDocModel);
  }

  async function onFormSubmit() {
    if (!isUploadFileClick) {
      setLoading(true);
      const trainDocUploadUrl = URLS.CHATBOT.TRAIN.UPLOAD_DOC;
      const trainDocUploadMethod = "POST";
      const trainDocUploadFormData = new FormData();
      trainDocUploadFormData.append("file_obj", formData.file_obj);
      trainDocUploadFormData.append("title", formData.title);
      trainDocUploadFormData.append("brain_id", brainId);

      await httpClient({ method: trainDocUploadMethod, url: trainDocUploadUrl, data: trainDocUploadFormData })
        .then((res) => {
          consoleLog("train-upload-doc-res", res);
          store.dispatch(fetchData());
          notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        })
        .catch((err) => {
          consoleLog("train-upload-doc-err", { err });
          notify(TOAST.TYPE.ERROR, {
            title: `${err.response.data.error.code} [${err.response.status}]`,
            message: `${err.response.data.error.message}`,
          });
        })
        .finally(() => {
          setLoading(false);
          close();
        });
    }
  }

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData(name, value);
  };

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    setFormData((prevData) => ({
      ...prevData,
      file_obj: acceptedFiles[0],
    }));

    setUploadFileClick(false);
  };

  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={false}>
        <div>
          <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
          <div className="sor-header">
            <h6>{isEdit ? "Edit" : "Add"} Document</h6>
          </div>
          <div className="sor-body-header-footer-backbtn ">
            <form id="chatbot-train-doc-form" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label">Title</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      {...register("title", {
                        required: "This field is required",
                      })}
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                    {errors.title && <span className="invalid">{errors.title.message}</span>}
                  </div>
                </div>
              </div>

              <div className="w-100 mb-2">
                <label className="form-label">Upload Document</label>
                <div onClick={() => setUploadFileClick(true)}>
                  <Dropzone onDrop={handleDropChange} accept={[".txt", ".pdf", ".csv"]}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dropzone upload-zone dz-clickable border-dashed border-w-2 rounded"
                        >
                          <input {...getInputProps()} />
                          {
                            <div className="dz-message">
                              <span className="dz-message-text">Drag and drop file</span>
                              <span className="dz-message-or">or</span>
                              <Button color="primary">SELECT</Button>
                            </div>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {formData.file_obj && (
                    <div className="dz-message">
                      <span className="dz-message-text text-sm font-bold">{formData.file_obj.name}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 my-2 bg-green-100 rounded-md p-2">
                <p className=" text-xs mb-1">
                  <strong>Notes</strong>
                </p>
                <ul>
                  <li className="list-item text-xs">Acceptable formats: Text-based PDF or TXT  or CSV in English</li>
                  <li className="list-item text-xs">Maximum file size: 20 MB</li>
                  <li className="list-item text-xs">Content restriction: Images will not be extracted</li>
                  <li className="list-item text-xs">
                    Unsupported features: Files with multiple text columns, encryption, or password protection
                  </li>
                </ul>
              </div>
              <Button color="primary" type="submit" className="d-none"></Button>
            </form>
          </div>

          <div className="sor-footer d-flex align-items-center justify-content-end">
            <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" className="sor-action-button position-relative" form="chatbot-train-doc-form">
              {isLoading ? <Spinner size="sm" color="light" /> : isEdit ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditTrainDocument;
