import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

const HelpCenter = () => {
  return (
    <React.Fragment>
      <Head title="Help center" />
      <Content>
        <iframe
          title="Help Center"
          src="https://notion-magnusai-help-center.vercel.app/"
          className="w-full h-screen"
          frameborder="0"
        ></iframe>
      </Content>
    </React.Fragment>
  );
};

export default HelpCenter;
