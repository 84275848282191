import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Logo from "../logo/Logo";
import User from "./dropdown/user/UserCopywriter";

import Menu from "../menu/MenuHeader";
import { copywritermenu } from "../menu/MenuData";

import { useTheme } from "../provider/Theme";
import { useSelector } from "react-redux";
import { AUTH } from "../../constants";
import { profileType } from "../../config/profile.config";

const Header = ({ fixed, className, ...props }) => {
  const [sticky, setSticky] = useState(false);

  const theme = useTheme();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    "has-fixed": sticky,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  useEffect(() => {
    let _item_offset = 30;
    if (window.scrollY > _item_offset) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [window.scrollY]);
  const { auth } = useSelector((state) => state.auth);

  return (
    <div className={headerClass}>
      <div className="container-xl wide-xl">
        <div className="nk-header-wrap">
          <div className={`nk-header-brand me-4 ${theme.sidebarVisibility ? "d-sm-none" : ""}`}>
            <Logo to={`${process.env.PUBLIC_URL}`} />
          </div>
          <div className="nk-header-menu is-light" style={{ marginLeft: "auto" }}>
            <div className="nk-header-menu-inner">
              <Menu
                menuData={copywritermenu.filter((i) =>
                  auth[AUTH.STORE.USER]?.user_type === profileType.Admin ? true : i.link !== "/subscription-management"
                )}
              />
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
