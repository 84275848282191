import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { Col, Row } from "../../../../../components/Component";

import { useParams } from "react-router";
import InstallationChatbotSnippet from "../../../../components/publish/InstallationChatbotSnippet";

const ViewChatbotPublish = () => {
  const { brainId } = useParams();

  return (
    <React.Fragment>
      <Head title="Publish"></Head>
      <Row className="mb-4">
        <Col lg={7}>
          <InstallationChatbotSnippet />
        </Col>
        <Col lg={5}>
          <div className=" ">
            <iframe
              title="widget"
              className="bg-gray-300 p-2 rounded h-450px w-100"
              src={`${process.env.REACT_APP_MAGAI_WIDGET_URL}/?brainId=${brainId}&user_business_type=vehicle-sharing&user_persona=visitor`}
            ></iframe>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ViewChatbotPublish;
