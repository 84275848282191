
export const SETTINGS_OPTIONS = [
  {
    id: 'api_key',
    text: 'API keys'
  },
  {
    id: 'workspace',
    text: 'Workspace'
  },
  {
    id: 'domain',
    text: 'Domain'
  },
]
