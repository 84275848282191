
import Markdown from 'markdown-to-jsx';
import { getUTCAwareTime } from '../getUTCAwareTime';
const YouChat = ({ item = '', iconColor, brandIcon }) => {

  return (
    <div className='chat-box  is-you'>
      <div className='mr-1 '>
        <img className='rounded-md' src={brandIcon} alt='magnusai-logo' width='30px' />
      </div>
      <div className='box-content'>
        <div className='bubble' style={{ backgroundColor: `${iconColor}12` }}>
          <Markdown>{item}</Markdown>
        </div>
        <ul className='flex gap-1 text-xs text-gray-600 mt-1'>
          <li>{getUTCAwareTime(new Date().toISOString())}</li>
        </ul>
      </div>
    </div>
  );
};

export default YouChat;
