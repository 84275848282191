import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { XTABLE } from "../../constants";
import { httpClient } from "../../services";

//ASYNC REQUEST
export const fetchData = createAsyncThunk("xtable/fetchData", async (qs, { getState }) => {
  const state = getState().xtable;
  const qsr = qs ? qs : state[XTABLE.STORE.QUERY];
  // consoleLog("qsr-", qsr);
  const url = state[XTABLE.STORE.ENDPOINT] + qsr;
  const response = await httpClient.get(url);
  return response.data;
});

//GETTER
export const getQueryString = ({ xtable }) => {
  let queryString = xtable[XTABLE.STORE.QSO.KEY][XTABLE.STORE.QSO.VALUE.PREPEND];

  let keys = Object.keys(xtable[XTABLE.STORE.QSC.KEY]);
  let values = Object.values(xtable[XTABLE.STORE.QSC.KEY]);

  // qsc
  for (let i = 0; i < keys.length; i++) {
    if (typeof values[i] === "object" || typeof values[i] === "undefined" || values[i] === "") {
      continue;
    }

    if (Array.isArray(values[i])) {
      // used for arrays
      for (let key in values[i]) {
        queryString += keys[i] + "=" + values[i][key] + "&";
      }
    } else {
      queryString += keys[i] + "=" + values[i] + "&";
    }
  }

  const qsr = queryString.slice(0, -1) + xtable[XTABLE.STORE.QSO.KEY][XTABLE.STORE.QSO.VALUE.APPEND];
  return qsr;
};
export const getQsc = ({ xtable }) => {
  return xtable[XTABLE.STORE.QSC.KEY];
};
export const getResetStatus = ({ xtable }) => {
  return xtable[XTABLE.STORE.RESET_STATUS];
};
export const getXtId = ({ xtable }) => {
  return xtable[XTABLE.STORE.XTID];
};

export const getRequestBusyStatus = ({ xtable }) => {
  return xtable[XTABLE.STORE.REQUEST.KEY][XTABLE.STORE.REQUEST.VALUE.BUSY];
};

export const getMeta = ({ xtable }) => {
  return xtable[XTABLE.STORE.RESPONSE.KEY][XTABLE.STORE.RESPONSE.VALUE.META];
};

export const getData = ({ xtable }) => {
  return xtable[XTABLE.STORE.RESPONSE.KEY][XTABLE.STORE.RESPONSE.VALUE.DATA];
};

export const getCurrentLimit = ({ xtable }) => {
  return xtable[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.LIMIT];
};

export const getCurrentPage = ({ xtable }) => {
  return xtable[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.PAGE];
};

export const getPaginationSummary = ({ xtable }) => {
  const limit = xtable[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.LIMIT];
  const offset = xtable[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.OFFSET];
  const total = xtable.res?.meta?.count?.total || 0;

  // do not exceed total items
  const fromIndex = offset + 1 < total ? offset + 1 : total;
  const toOffset = offset + limit < total ? offset + limit : total;

  return `Showing ${fromIndex} - ${toOffset} of ${total} items`;
};

export const xtableSlice = createSlice({
  name: "xtable",
  initialState: {
    [XTABLE.STORE.XTID]: "",
    [XTABLE.STORE.ENDPOINT]: "",
    // query string -> option
    [XTABLE.STORE.QSO.KEY]: {
      [XTABLE.STORE.QSO.VALUE.PREPEND]: "?",
      [XTABLE.STORE.QSO.VALUE.APPEND]: "",
    },
    // query strings -> common
    [XTABLE.STORE.QSC.KEY]: {
      [XTABLE.STORE.QSC.VALUE.PAGE]: 1,
      [XTABLE.STORE.QSC.VALUE.LIMIT]: 10,
      [XTABLE.STORE.QSC.VALUE.OFFSET]: 0,
      [XTABLE.STORE.QSC.VALUE.SEARCH]: null,
    },
    // request
    [XTABLE.STORE.REQUEST.KEY]: {
      [XTABLE.STORE.REQUEST.VALUE.BUSY]: false,
    },

    // response
    [XTABLE.STORE.RESPONSE.KEY]: {
      [XTABLE.STORE.RESPONSE.VALUE.META]: null,
      [XTABLE.STORE.RESPONSE.VALUE.DATA]: null,
    },
    [XTABLE.STORE.RESET_ON]: [],
    [XTABLE.STORE.RESET_STATUS]: false,
    [XTABLE.STORE.QUERY]: "",
  },
  reducers: {
    /**
     * @param {Object} state
     * @param {String} [XTABLE.STORE.XTID]
     */
    setXtId(state, { payload }) {
      state[XTABLE.STORE.XTID] = payload[XTABLE.STORE.XTID];
    },

    /**
     * @param {Object} state
     * @param {String} [XTABLE.STORE.ENDPOINT]
     */
    setEndpoint(state, { payload }) {
      state[XTABLE.STORE.ENDPOINT] = payload[XTABLE.STORE.ENDPOINT];
    },

    /**
     * @param {Object} state
     * @param {String} [XTABLE.STORE.QSO.VALUE.PREPEND]
     * @param {String} [XTABLE.STORE.QSO.VALUE.APPEND]
     */
    setQso(state, { payload }) {
      state[XTABLE.STORE.QSO.KEY][[XTABLE.STORE.QSO.VALUE.PREPEND]] = payload[XTABLE.STORE.QSO.VALUE.PREPEND];
      state[XTABLE.STORE.QSO.KEY][XTABLE.STORE.QSO.VALUE.APPEND] = payload[XTABLE.STORE.QSO.VALUE.APPEND];
    },

    /**
     * @param {Object} state
     * @param {Boolean} [XTABLE.STORE.REQUEST.VALUE.BUSY]
     */
    reqBusy(state, { payload }) {
      state[XTABLE.STORE.REQUEST.KEY][XTABLE.STORE.REQUEST.VALUE.BUSY] = payload[XTABLE.STORE.REQUEST.VALUE.BUSY];
    },

    /**
     * Resets qsc, req & res
     *
     * @param {Object} state
     * @param {String} [XTABLE.STORE.XTID]
     */
    reset(state, { payload }) {
      state[XTABLE.STORE.RESET_ON].push([payload[XTABLE.STORE.XTID], Date.now()]);
      state[XTABLE.STORE.RESET_STATUS] = true;
      // reset qsc [object]
      for (const key in state[XTABLE.STORE.QSC.KEY]) {
        if (key === XTABLE.STORE.QSC.VALUE.PAGE) {
          state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.PAGE] = 1;
          continue;
        }
        if (key === XTABLE.STORE.QSC.VALUE.LIMIT) {
          state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.LIMIT] = 10;
          continue;
        }
        if (key === XTABLE.STORE.QSC.VALUE.OFFSET) {
          state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.OFFSET] = 0;
          continue;
        }
        // set all other properties as null
        state[XTABLE.STORE.QSC.KEY][key] = null;
      }

      // request
      state[XTABLE.STORE.REQUEST.KEY] = {
        [XTABLE.STORE.REQUEST.VALUE.BUSY]: false,
      };

      // response
      state[XTABLE.STORE.RESPONSE.KEY] = {
        [XTABLE.STORE.RESPONSE.VALUE.DATA]: null,
        [XTABLE.STORE.RESPONSE.VALUE.META]: null,
      };
    },

    /**
     * @param {Object} state
     * @param {Number} [XTABLE.STORE.QSC.VALUE.PAGE]
     */
    updatePage(state, { payload }) {
      const offset =
        payload[XTABLE.STORE.QSC.VALUE.PAGE] < 1
          ? 0
          : (payload[XTABLE.STORE.QSC.VALUE.PAGE] - 1) * state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.LIMIT];

      state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.PAGE] = payload[XTABLE.STORE.QSC.VALUE.PAGE];
      state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.OFFSET] = offset;
    },

    /**
     * @param {Object} state
     * @param {Number} [XTABLE.STORE.QSC.VALUE.LIMIT]
     */
    updateLimit(state, { payload }) {
      state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.LIMIT] = payload[XTABLE.STORE.QSC.VALUE.LIMIT];
    },

    /**
     * @param {Object} state
     * @param {Number} [XTABLE.STORE.QSC.VALUE.OFFSET]
     */
    updateOffset(state, { payload }) {
      state[XTABLE.STORE.QSC.KEY][XTABLE.STORE.QSC.VALUE.OFFSET] = payload[XTABLE.STORE.QSC.VALUE.OFFSET];
    },

    updateQueryString(state, { payload }) {
      state[XTABLE.STORE.QUERY] = payload[XTABLE.STORE.QUERY];
    },
    updateElement(state, { payload }) {
      state[XTABLE.STORE.RESPONSE.KEY][XTABLE.STORE.RESPONSE.VALUE.DATA][payload.index][payload.key] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state[XTABLE.STORE.REQUEST.KEY][XTABLE.STORE.REQUEST.VALUE.BUSY] = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state[XTABLE.STORE.REQUEST.KEY][XTABLE.STORE.REQUEST.VALUE.BUSY] = false;

        state[XTABLE.STORE.RESPONSE.KEY][XTABLE.STORE.RESPONSE.VALUE.META] =
          action.payload[XTABLE.STORE.RESPONSE.VALUE.META];

        state[XTABLE.STORE.RESPONSE.KEY][XTABLE.STORE.RESPONSE.VALUE.DATA] =
          action.payload[XTABLE.STORE.RESPONSE.VALUE.DATA];
      })
      .addCase(fetchData.rejected, (state, action) => {
        state[XTABLE.STORE.REQUEST.KEY][XTABLE.STORE.REQUEST.VALUE.BUSY] = false;
      });
  },
});

export const {
  setXtId,
  setEndpoint,
  setQso,
  reqBusy,
  reset,
  updatePage,
  updateLimit,
  updateOffset,
  updateSearch,
  setResponse,
  updateQueryString,
  updateElement,
} = xtableSlice.actions;

export default xtableSlice.reducer;
