import React, { useEffect, useState } from "react";
import { Button, Icon, RSelect } from "../../../../../../components/Component";
import { useParams } from "react-router";
import { formBody, httpClient } from "../../../../../../services";
import { TOAST, URLS } from "../../../../../../constants";
import { fakeTableSource } from "./helper";
import { TrainConfig } from "../../../../../../config/train.config";
import { fetchData, store } from "../../../../../../redux";
import { notify } from "../../../../../../services/toastService";
import { consoleLog } from "../../../../../../utils/Utils";

const ExternalSourceAccrodion = () => {
  const { brainId } = useParams();
  const [step, setStep] = useState(1);
  const [externalSourceList, setExternalSourceList] = useState([]);

  const [externalSource, setExternalSource] = useState(0);
  const [externalRequiredData, setExternalRequiredData] = useState({});
  const [brainExternalId, setBrainExternalId] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [selectedTableRow, setSelectedTableRow] = useState(1000);

  useEffect(() => {
    httpClient
      .get(URLS.CHATBOT.TRAIN.EXTERNAL_SOURCE_LIST)
      .then((res) => {
        const sourceList = res.data.map((sourceData, sourceIndex) => {
          const source = {
            label: `${sourceData.source_name}`,
            value: `${sourceData.id}`,
          };
          return source;
        });

        res.data.map((sourceData, sourceIndex) => {
          const formData = {};
          formData[sourceData.id] = sourceData.required_credentials;
          setExternalRequiredData((preData) => {
            return { ...preData, ...formData };
          });
        });

        if (sourceList.length) {
          setExternalSourceList(sourceList);
        }
      })
      .catch((err) => {
        consoleLog("train-external-source-list-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }, []);

  const toggleStep = (step) => {
    if (step === 1) {
      setStep(step);
    }
    if (step === 2) {
      if (externalSource) {
        setStep(step);
      }
    }
    if (step === 3) {
      if (brainExternalId) {
        setStep(step);
      }
    }
  };

  const handleStep = (currentStep, forwardStep = 0) => {
    if (currentStep === 1) {
      if (externalSource) {
        setStep(forwardStep);
      }
    }

    if (currentStep === 2) {
      const formData = new FormData();
      externalRequiredData[externalSource].map((data) => {
        formData.append(data.post_field, data[[data.post_field]]);
      });

      formData.append("external_source", externalSource);
      formData.append("brain", brainId);

      httpClient
        .post(URLS.CHATBOT.TRAIN.ADD_EXTERNAL_SOURCE, formData)
        .then((res) => {
          setBrainExternalId(res.data.id);
          const fakeTableList = fakeTableSource.tables.map((tableName) => {
            return {
              label: tableName,
              value: tableName,
            };
          });
          setTableList(fakeTableList);

          setStep(forwardStep);
        })
        .catch((err) => {
          consoleLog("train-add-external-source-err", { err });
          notify(TOAST.TYPE.ERROR, {
            title: `${err.response.data.error.code} [${err.response.status}]`,
            message: `${err.response.data.error.message}`,
          });
        });
    }

    if (currentStep === 3) {
      httpClient
        .patch(
          URLS.CHATBOT.TRAIN.UPDATE_EXTERNAL_SOURCE(brainExternalId),
          formBody({ selected_table_name: selectedTableName, selected_row_count: selectedTableRow })
        )
        .then((res) => {
          dispatchEvent(new Event(TrainConfig.events.externalSource.sorToggle));
          handleReset();
          store.dispatch(fetchData());
        })
        .catch((err) => {
          consoleLog("train-update-external-source-err", { err });
          notify(TOAST.TYPE.ERROR, {
            title: `${err.response.data.error.code} [${err.response.status}]`,
            message: `${err.response.data.error.message}`,
          });
        });
    }
  };

  const handleReset = () => {
    externalRequiredData[externalSource].map((item, index) => {
      setExternalRequiredData((preData) => {
        preData[externalSource][index][item.post_field] = "";
        return { ...preData };
      });
    });
    setBrainExternalId(null);
    setSelectedTableName("");
    setSelectedTableRow(1000);
    setExternalSource(0);
    setStep(1);
  };
  return (
    <div>
      <div className="py-3" style={{ borderTop: "1px solid #dbdfea" }}>
        <div className="flex items-center justify-between" onClick={() => toggleStep(1)}>
          <div className="flex items-center gap-2">
            {step === 1 ? (
              <Icon name="chevron-down" className="text-2xl" />
            ) : (
              <Icon name="chevron-right" className="text-2xl" />
            )}

            <h6 className="title">Source</h6>
          </div>
          <div>
            {step === 1 && <Icon name="circle" className="text-2xl" />}
            {(step === 2 || step === 3) && <Icon name="check-circle" className="text-2xl text-green-700" />}
          </div>
        </div>

        {step === 1 && (
          <div className="my-3 ms-2">
            <div className="w-100 ">
              <div className="form-group">
                {/* <label className="form-label" htmlFor="external_source">
                  Select Database
                </label> */}

                <div className="form-control-wrap">
                  <RSelect
                    name={"external_source"}
                    options={externalSourceList}
                    value={externalSourceList.find((d) => d.value === externalSource)}
                    onChange={(e) => {
                      setExternalSource(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-3">
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleStep(1, 2);
                }}
                className=" flex justify-around items-center "
              >
                <span>Next</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="py-3" style={{ borderTop: "1px solid #dbdfea" }}>
        <div className="flex items-center justify-between" onClick={() => toggleStep(2)}>
          <div className="flex items-center gap-2">
            {step === 2 ? (
              <Icon name="chevron-down" className="text-2xl" />
            ) : (
              <Icon name="chevron-right" className="text-2xl" />
            )}
            <h6 className="title">Credential</h6>
          </div>
          <div>
            {step === 2 && <Icon name="circle" className="text-2xl" />}
            {step === 3 && <Icon name="check-circle" className="text-2xl text-green-700" />}
          </div>
        </div>

        {step === 2 && (
          <div>
            <div>
              <div className=" ms-3" key={`refresh-${step}`}>
                {/* <p>
                  <strong>Credential</strong>
                </p> */}

                {externalRequiredData[externalSource].map((item, index) => (
                  <div key={`erd-${index}`} className="my-3 ">
                    <div className="flex  items-center ">
                      <div className="form-control-wrap w-100 me-1">
                        <input
                          type="text"
                          placeholder="Key"
                          name="key"
                          className="form-control"
                          disabled
                          value={item.display_name}
                        />
                      </div>
                      <div className="form-control-wrap w-100 ms-1">
                        <input
                          type="text"
                          placeholder="Value"
                          name="value"
                          className="form-control"
                          value={externalRequiredData[externalSource][index][item.post_field]}
                          onChange={(e) => {
                            setExternalRequiredData((preData) => {
                              preData[externalSource][index][item.post_field] = e.target.value;
                              return { ...preData };
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-end mt-3">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStep(2, 3);
                  }}
                  className=" flex justify-around items-center "
                >
                  <span>Next</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="py-3" style={{ borderTop: "1px solid #dbdfea" }}>
        <div className="flex items-center justify-between" onClick={() => toggleStep(3)}>
          <div className="flex items-center gap-2">
            {step === 3 ? (
              <Icon name="chevron-down" className="text-2xl" />
            ) : (
              <Icon name="chevron-right" className="text-2xl" />
            )}
            <h6 className="title">Data</h6>
          </div>
          <div>
            {step === 3 && <Icon name="circle" className="text-2xl" />}
            {step === 4 && <Icon name="check-circle" className="text-2xl text-green-700" />}
          </div>
        </div>

        {step === 3 && (
          <div>
            <div>
              <div className=" ms-3" key={`refresh-${step}`}>
                <div className="my-3 ">
                  <div className="flex  items-center ">
                    <div className="form-control-wrap w-25 me-1">
                      <input
                        type="text"
                        placeholder="Key"
                        name="key"
                        className="form-control"
                        disabled
                        value="Table Name"
                      />
                    </div>
                    <div className="form-control-wrap w-75 ms-1">
                      <RSelect
                        name={"external_source"}
                        options={tableList}
                        value={tableList.find((d) => d.value === selectedTableName)}
                        onChange={(e) => {
                          setSelectedTableName(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-3 ">
                  <div className="flex  items-center ">
                    <div className="form-control-wrap w-25 me-1">
                      <input
                        type="text"
                        placeholder="Key"
                        name="key"
                        className="form-control"
                        disabled
                        value="Row Count"
                      />
                    </div>
                    <div className="form-control-wrap w-75 ms-1">
                      <input
                        type="text"
                        placeholder="Value"
                        name="value"
                        className="form-control"
                        value={selectedTableRow}
                        onChange={(e) => {
                          setSelectedTableRow(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end mt-3">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStep(3);
                  }}
                  className=" flex justify-around items-center "
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalSourceAccrodion;
