import React, { useEffect, useState } from "react";
import SlideOverX from "../../../../components/sor/SlideOverX";
import { ChatbotsConfig } from "../../../../config/chatbots.config";
import { Button, Icon } from "../../../../components/Component";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { getChatbotDataModel } from "./helper";
import { fetchData, getData, getWorkspaceData, store } from "../../../../redux";
import Emitter from "../../../../services/eventEmitterService";
import { AUTH, TOAST, URLS } from "../../../../constants";
import { httpClient } from "../../../../services";
import { notify } from "../../../../services/toastService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Switch from "react-switch";
import { consoleLog } from "../../../../utils/Utils";

const AddEditChatbot = () => {
  const resData = useSelector(getData);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [sorId, setSorId] = useState(ChatbotsConfig.events.sorId);
  const [chatbotId, setChatbotId] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState(getChatbotDataModel);
  const [submitting, setSubmitting] = useState(false);
  const workspaceId = useSelector(getWorkspaceData).id;
  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    Emitter.on(ChatbotsConfig.events.editingData, (data) => {
      consoleLog("event-emitter", data);

      if (data.id) {
        setEdit(true);
        setChatbotId(data.id);
        const existingData = {
          name: data.name,
          description: data.description,
          fetch_data_from_external_source_enabled: data.fetch_data_from_external_source_enabled,
        };
        setFormData({ ...existingData });
      } else {
        setEdit(false);
      }
    });
    return () => {
      Emitter.off(ChatbotsConfig.events.editingData, {});
    };
  }, []);
  function close() {
    if (isLoading) return;
    resetData();
    // reset(getChatbotDataModel);
    dispatchEvent(new Event(ChatbotsConfig.events.sorToggle));
  }
  function resetData() {
    setSubmitting(false);
    setFormData(getChatbotDataModel);
    setEdit(false);
  }
  function refreshPage() {
    store.dispatch(fetchData());
  }

  // const updateFormData = (key, value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [key]: value,
  //   }));
  // };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   updateFormData(name, value);
  // };

  function hasEmptyValue(obj) {
    // Iterate through all properties in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Check if the property value is an empty string, null, or undefined
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return true; // Return true if any such value is found
        }
      }
    }
    return false; // Return false if no such value is found
  }

  async function onFormSubmit() {
    setSubmitting(true);
    if (hasEmptyValue(formData)) return;

    if (isLoading) return;
    const chatbotUrl = isEdit ? URLS.CHATBOT.UPDATE(chatbotId) : URLS.CHATBOT.CREATE;
    const chatbotMethod = isEdit ? "PATCH" : "POST";
    const chatbotDataProxy = { ...formData, workspace: workspaceId };
    const chatbotFormData = new FormData();
    for (const key in chatbotDataProxy) {
      chatbotFormData.append(key, chatbotDataProxy[key]);
    }
    setLoading(true);
    await httpClient({ method: chatbotMethod, url: chatbotUrl, data: chatbotFormData })
      .then((res) => {
        consoleLog("chatbot-res", res);
        refreshPage();
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        close();
      })
      .catch((err) => {
        consoleLog({ chatbotErr: err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <React.Fragment>
      <SlideOverX
        id={sorId}
        isOverlayVisible={true}
        haveOverlayToggle={true}
        editType="subscription"
        onClose={resetData}
      >
        <div onMouseEnter={(_) => setShowAlert(true)} onMouseLeave={(_) => setShowAlert(false)}>
          <div>
            <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
            <div className="sor-header">
              <h5>{isEdit ? "Edit" : "Add"} Chatbot</h5>
            </div>
            <div className="sor-body-header-footer-backbtn w-100">
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Name *
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="form-control"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    {submitting && !formData.name.length && <span className="invalid">This Field is Required</span>}
                  </div>
                </div>
              </div>
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label">Description *</label>
                  <div className="form-control-wrap">
                    <textarea
                      rows="9"
                      placeholder="Enter description"
                      className="form-control no-resize"
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                    {submitting && !formData.description.length && (
                      <span className="invalid">This Field is Required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 py-2">
                <div className="form-group">
                  <label htmlFor="fetch_data_from_external_source_enabled" className="d-flex items-center gap-1">
                    <strong className="text-sm text-gray-900">Data fetch from external source</strong>

                    <Switch
                      id="fetch_data_from_external_source_enabled"
                      checked={formData.fetch_data_from_external_source_enabled}
                      onChange={(value) => {
                        setFormData((preData) => {
                          return { ...preData, fetch_data_from_external_source_enabled: value };
                        });
                      }}
                      onColor="#733AEA"
                      onHandleColor="#ffffff"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={38}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" className="sor-action-button position-relative" onClick={onFormSubmit}>
                {isLoading ? <Spinner size="sm" color="light" /> : isEdit ? "Update" : "Save"}
              </Button>
            </div>
            {showAlert &&
            resData?.length >=
              auth[AUTH.STORE.USER]?.subscription?.subscription_plan?.number_of_allowed_personal_chatbots ? (
              <div className="sor-body-header-footer-backbtn position-absolute start-0 h-100 w-100 top-0 alert-bg align-center justify-center">
                <Button
                  color="primary"
                  onClick={(_) => {
                    navigate(`${process.env.PUBLIC_URL}/subscription`);
                  }}
                >
                  Upgrade Plan
                </Button>
              </div>
            ) : null}
          </div>
          {/* } */}
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditChatbot;
