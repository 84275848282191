import React, { useEffect, useState } from "react";
import SlideOverX from "../../../../../../components/sor/SlideOverX";

import { Button, Icon, RSelect } from "../../../../../../components/Component";

import { getFieldTypes, getLeadDataModel } from "./helper";
import { Spinner } from "reactstrap";
import { LeadConfig } from "../../../../../../config/lead.config";
import Emitter from "../../../../../../services/eventEmitterService";
import { TOAST, URLS } from "../../../../../../constants";
import { httpClient } from "../../../../../../services";
import { notify } from "../../../../../../services/toastService";
import { useParams } from "react-router";
import { consoleLog } from "../../../../../../utils/Utils";

const AddEditLeadConfig = ({ onRefresh }) => {
  const { brainId } = useParams();
  const [sorId, setSorId] = useState(LeadConfig.events.sorId);
  const [isEdit, setEdit] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  const [allowSubmitStatus, setAllowSubmitStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fieldId, setFieldId] = useState(null);
  const [formData, setFormData] = useState(getLeadDataModel);
  const [fieldTypesOptions, setFieldTypesOptions] = useState(getFieldTypes);

  useEffect(() => {
    Emitter.on(LeadConfig.events.editingData, (data) => {
      if (data.id) {
        setEdit(true);
        setFieldId(data.id);
        const existingData = {
          name: data.name,
          description: data.description,
          message: data.message,
          field_type: data.field_type,
          brain: data.brain,
        };
        setFormData({ ...existingData });
      } else {
        setEdit(false);
        resetData();
      }
    });
    return () => {
      Emitter.off(LeadConfig.events.editingData, {});
    };
  }, []);

  useEffect(() => {
    if (formData.name.length && formData.message.length && formData.description.length) {
      setAllowSubmitStatus(true);
    }
  }, [formData]);

  function close() {
    if (isLoading) return;
    dispatchEvent(new Event(LeadConfig.events.sorToggle));
    resetData();
  }
  function resetData() {
    setsubmitting(false);
    setAllowSubmitStatus(false);
    setFormData({ ...getLeadDataModel, brain: brainId });
  }

  function refreshPage() {
    onRefresh();
  }

  async function onFormSubmit() {
    setsubmitting(true);
    if (isLoading || !allowSubmitStatus) return;

    const leadConfigUrl = isEdit ? URLS.CHATBOT.LEAD.LEAD_FIELD_UPDATE(fieldId) : URLS.CHATBOT.LEAD.LEAD_FIELD_CREATE;
    const leadConfigMethod = isEdit ? "PATCH" : "POST";
    const formDataProxy = { ...formData };
    const leadConfigFormData = new FormData();
    for (const key in formDataProxy) {
      leadConfigFormData.append(key, formDataProxy[key]);
    }

    setLoading(true);
    await httpClient({ method: leadConfigMethod, url: leadConfigUrl, data: leadConfigFormData })
      .then((res) => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        refreshPage();
      })
      .catch((err) => {
        consoleLog("lead-config-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }

  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={true} haveOverlayToggle={true} editType="subscription">
        <div>
          <div>
            <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
            <div className="sor-header">
              <h5>{isEdit ? "Edit" : "Add"} Lead Config</h5>
            </div>
            <div className="sor-body-header-footer-backbtn w-100">
              <div>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Name *
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="form-control"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      />
                      {submitting && !formData.name.length && <span className="invalid">This Field is Required</span>}
                    </div>
                  </div>
                </div>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label" htmlFor="field_type">
                      Input Type *
                    </label>

                    <div className="form-control-wrap">
                      <RSelect
                        name={"field_type"}
                        options={fieldTypesOptions}
                        getOptionLabel={(option) => option.text}
                        getOptionValue={(option) => option.value}
                        value={fieldTypesOptions.find((d) => d.value === formData.field_type)}
                        onChange={(e) => {
                          setFormData({ ...formData, field_type: e.value });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label">Message *</label>
                    <div className="form-control-wrap">
                      <textarea
                        style={{ minHeight: "56px" }}
                        placeholder="Enter message"
                        className="form-control no-resize h-20"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                      {submitting && !formData.message.length && (
                        <span className="invalid">This Field is Required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label">Description *</label>
                    <div className="form-control-wrap">
                      <textarea
                        style={{ minHeight: "56px" }}
                        placeholder="Enter description"
                        className="form-control no-resize h-20"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      />
                      {submitting && !formData.description.length && (
                        <span className="invalid">This Field is Required</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" className="sor-action-button position-relative" onClick={onFormSubmit}>
                {isLoading ? <Spinner size="sm" color="light" /> : isEdit ? "Update" : "Save"}
              </Button>
            </div>
          </div>
          {/* } */}
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditLeadConfig;
