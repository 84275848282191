import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { formBody, httpClient } from "../../services";
import { AUTH, LOCAL_STORAGE_ITEMS, TOAST, URLS } from "../../constants";
import { useDispatch } from "react-redux";
import { AUTH_SLICE_FIELDS, login, setAuthData, setWorkspaceData } from "../../redux";
import { notify } from "../../services/toastService";
import { consoleLog } from "../../utils/Utils";

const VerifyTokenToSignin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    httpClient
      .post(URLS.AUTH.VERIFY_EMAIL, formBody({ token: token, email: "masum.sde@gmail.com" }))
      .then((res) => {
        httpClient.get(URLS.AUTH.PROFILE).then((profileResp) => {
          dispatch(setAuthData({ key: AUTH.STORE.USER, value: profileResp.data }));
          localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_TYPE, profileResp.data.user_type);
        });

        localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN]);
        localStorage.setItem(
          LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP,
          new Date().getTime() + res.data.expires_in * 1000 - 60000
        ); // having 1 minute(60000 ms) as threshold
        localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN]);
        const flag = {
          [AUTH_SLICE_FIELDS.LOADED]: true,
          [AUTH_SLICE_FIELDS.LOGGED_IN]: true,
          [AUTH_SLICE_FIELDS.ACCESS_TOKEN]: res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN],
          [AUTH_SLICE_FIELDS.REFRESH_TOKEN]: res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN],
        };
        //NEED_REFACTOR
        httpClient
          .get(URLS.WORKSPACE.INDEX)
          .then((res) => {
            consoleLog("res-full", res);
            if (res.data.data[0]) {
              localStorage.setItem(
                LOCAL_STORAGE_ITEMS.WORKSPACE,
                res.data.data ? JSON.stringify(res.data.data[0]) : null
              );
              dispatch(setWorkspaceData({ id: res.data.data[0].id, name: res.data.data[0].name }));
              dispatch(login(flag));

              navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
            }
          })
          .catch((err) => {
            notify(TOAST.TYPE.ERROR, {
              title: `${err.response.data.error.code} [${err.response.status}]`,
              message: `${err.response.data.error.message}`,
            });
          });
        // dispatch(login(flag));
        // navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
      })
      .catch((err) => consoleLog("err-verify-email", { err }));
  }, [token]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <h3>Please wait until verifying your email.</h3>
    </div>
  );
};

export default VerifyTokenToSignin;
