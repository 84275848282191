import { getUTCAwareTime } from "../getUTCAwareTime";

const MeChat = ({ bubbleColor, item = '' }) => {
  return (
    <div className='chat-box is-me'>
      <div className='box-content'>
        <div className='bubble' style={{ backgroundColor: bubbleColor }} >
          {item}
        </div>
        <ul className='flex justify-end gap-1 text-xs text-gray-600 mt-1'>
          <li>{getUTCAwareTime(new Date().toISOString())}</li>
        </ul>
      </div>
    </div>
  );
};

export default MeChat;
