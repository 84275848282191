import React from "react";
import Head from "../../../layout/head/Head";
import ChatBody from "./ChatBody";
import { Icon } from "../../../components/Component";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";

import { ChatAsideBody } from "./ChatAsideBody";
import { useSelector } from "react-redux";
import { fetchChatListData, getChatMobileViewStatus, getChatProducerType, setChatProducerType } from "../../../redux";
import { CONVERSATION } from "../../../constants";
import { useDispatch } from "react-redux";
import { consoleLog } from "../../../utils/Utils";

const Chat = () => {
  const currentMobileViewStatus = useSelector(getChatMobileViewStatus);
  const currentChatProducerType = useSelector(getChatProducerType);
  const dispatch = useDispatch();
  function onFilterClick(typeId) {
    consoleLog("onFilterClick", typeId)
    dispatch(setChatProducerType({ [CONVERSATION.STORE.CHAT_PRODUCE_TYPE]: typeId }));
    dispatch(fetchChatListData());
  }
  return (
    <React.Fragment>
      <Head title="Chat"></Head>
      <div>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${currentMobileViewStatus ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div className="nk-chat-aside-user">
                <div className="title">Conversations</div>
              </div>
              <ul className="nk-chat-aside-tools g-2">
                <React.Fragment>
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                        <Icon name="filter-alt"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li onClick={() => onFilterClick(null)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === null ? "active" : ""}>All</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => onFilterClick(0)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 0 ? "active" : ""}>Widget</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => onFilterClick(1)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 1 ? "active" : ""}>Whatsapp</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => onFilterClick(2)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 2 ? "active" : ""}>Telegram</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => onFilterClick(3)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 3 ? "active" : ""}>Messenger</span>
                            </DropdownItem>
                          </li>

                          <li onClick={() => onFilterClick(4)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 4 ? "active" : ""}>Twilio</span>
                            </DropdownItem>
                          </li>
                          <li onClick={() => onFilterClick(5)}>
                            <DropdownItem
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <span className={currentChatProducerType === 5 ? "active" : ""}>Instagram</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </React.Fragment>
              </ul>
            </div>
            <ChatAsideBody />
          </div>
          <ChatBody />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Chat;
