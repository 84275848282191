import React, { useEffect, useState } from "react";
import SlideOverX from "../../../components/sor/SlideOverX";
import { Button, Icon } from "../../../components/Component";
import { AiConversationConfig } from "../../../config/aiConversation.config";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import Emitter from "../../../services/eventEmitterService";
import { consoleLog } from "../../../utils/Utils";
import { TOAST, URLS } from "../../../constants";
import { formBody, httpClient } from "../../../services";
import { notify } from "../../../services/toastService";

const EditAiConversation = () => {
  const [isLoading, setLoading] = useState(false);
  const [sorId, setSorId] = useState(AiConversationConfig.events.sorId);
  const [formData, setFormData] = useState({
    answer: "",
  });
  const [existingData, setExistingData] = useState({ conversationId: null, visitorQuestion: "", aiResponse: "" });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    Emitter.on(AiConversationConfig.events.editingData, (data) => {
      setFormData({ answer: "" });

      setTimeout(() => {
        const textarea = document.getElementById("answerTextarea");
        if (textarea) {
          textarea.focus();
        }
      }, 800);

      setExistingData({
        ...existingData,
        conversationId: data.vMsg.id,
        visitorQuestion: data.vMsg.chat[0],
        aiResponse: data.aiMsg.chat[0],
      });
    });
    return () => {
      Emitter.off(AiConversationConfig.events.editingData, {});
    };
  }, []);

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateFormData(name, value);
  };

  function close() {
    if (isLoading) return;
    setLoading(false);
    setFormData({ answer: "" });
    dispatchEvent(new Event(AiConversationConfig.events.sorToggle));
  }

  async function onFormSubmit() {
    if (isLoading) return;
    setLoading(true);
    httpClient
      .patch(
        URLS.CHATBOT.CONVERSATION.UPDATE_CONVERSATION(existingData.conversationId),
        formBody({ answer: formData.answer })
      )
      .then((res) => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        close();
        dispatchEvent(new Event(AiConversationConfig.events.refresh));
      })
      .catch((err) => {
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
        consoleLog({ conversationUpdateErr: { err } });
      });
  }

  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={true} haveOverlayToggle={true} editType="subscription">
        <div>
          <div>
            <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
            <div className="sor-header">
              <h5>Edit Response</h5>
            </div>
            <div className="sor-body-header-footer-backbtn w-100">
              <form id="ai-conversation-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label">What should be the real answer?</label>
                    <div className="form-control-wrap">
                      <textarea
                        rows="9"
                        id="answerTextarea"
                        placeholder="Enter your answer"
                        className="form-control no-resize"
                        {...register("answer", {
                          required: "This field is required",
                        })}
                        value={formData.answer}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label">Question</label>
                    <div className="form-control-wrap">
                      <textarea
                        rows="4"
                        placeholder="Question"
                        className="form-control no-resize"
                        value={existingData.visitorQuestion}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mb-2">
                  <div className="form-group">
                    <label className="form-label">Original Response</label>
                    <div className="form-control-wrap">
                      <textarea
                        rows="6"
                        placeholder="Original Response"
                        className="form-control no-resize"
                        value={existingData.aiResponse}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" className="sor-action-button position-relative" form="ai-conversation-edit-form">
                {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
              </Button>
            </div>
          </div>
          {/* } */}
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default EditAiConversation;
