export const LeadConfig = {
  events: {
    name: "lead",
    // refresh-index-data
    refresh: `rid-lead`,
    // slideover-right
    sorId: "lead",
    sorToggle: "sor-toggle-lead",
    // editing-data
    editingData: "edit-lead-data",
  },
};
