import React from "react";
import {  UserAvatar } from "../../../components/Component";
import { capitalizeName, findUpper, shortifyText } from "../../../utils/Utils";
import { useDispatch } from "react-redux";
import { CONVERSATION } from "../../../constants";
import { getSelectedChatInfo, setChatMobileViewStatus, setSelectedChatInfo } from "../../../redux";
import { getUTCAwareTime } from "../../../utils/datetime/getUTCAwareTime";
import classNames from "classnames";
import { useSelector } from "react-redux";
import TimeAgo from "timeago-react";
import Markdown from 'markdown-to-jsx';
import { AiConversationConfig } from "../../../config/aiConversation.config";
import Emitter from "../../../services/eventEmitterService";
export const MeChat = ({ item }) => {
  return (
    <div className="chat is-me">
      <div className="chat-content">
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={`me-${idx}`}>
                <React.Fragment>
                  <div className={`chat-msg bg-${`purple`}`}>{msg}</div>
                </React.Fragment>
              </div>
            );
          })}
        </div>
        <ul className="chat-meta">
          <li>Human</li>
          <li> {getUTCAwareTime(item.date)}</li>
        </ul>
      </div>
    </div>
  );
};

export const YouChat = ({ item,meItem }) => {
  function updateAiConversation(aiMsg,vMsg){
    Emitter.emit(AiConversationConfig.events.editingData, {aiMsg:aiMsg,vMsg:vMsg});
    dispatchEvent(new Event(AiConversationConfig.events.sorToggle));
  }
  return (
    <div className="chat is-you">
      <div className="chat-avatar">
        <UserAvatar theme={`purple`} text={`AI`}>
          <span className="status dot dot-lg dot-success"></span>
        </UserAvatar>
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={`you-${idx}`}>
                <div className="chat-msg">
                <Markdown>{msg}</Markdown></div>
              </div>
            );
          })}
        </div>
        <ul className="chat-meta">
          <li>AI</li>
          <li>{getUTCAwareTime(item.date)}</li>
          <li onClick={(ev) => {
          ev.preventDefault();
          updateAiConversation(item,meItem);
        }} className="cursor-pointer">Edit</li>
        </ul>
      </div>
    </div>
  );
};
//test
export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item}</span>
      </div>
    </div>
  );
};

export const ChatItem = ({ item, imgSrc, imgTitle }) => {
  const dispatch = useDispatch();
  function chatItemClick(item) {
    dispatch(setSelectedChatInfo({ [CONVERSATION.STORE.SELECTED_CHAT_INFO]: item }));
    if (window.innerWidth < 860) {
      dispatch(setChatMobileViewStatus({ [CONVERSATION.STORE.MOBILE_VIEW]: true }));
    }
  }

  const selectedChatInfo = useSelector(getSelectedChatInfo);

  const chatItemClass = classNames({
    "chat-selected": selectedChatInfo && selectedChatInfo.id === item.id,
  });

  return (
    <li className={`chat-item ${chatItemClass} ${item.unread ? "is-unread" : ""}`}>
      <a
        className="chat-link"
        href="#chat-link"
        onClick={(ev) => {
          ev.preventDefault();
          chatItemClick(item);
        }}
      >
        <UserAvatar theme="purple" text={findUpper(capitalizeName(item.visitor_name))} className="chat-media">
          {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span> */}
        </UserAvatar>
        <div className="chat-info">
          <div>
            <div className="chat-from">
              <div className="name">
                {item.visitor_name==='Visitor'?`${item.visitor_name} #${shortifyText(item.id, -5)}`:item.visitor_name}                
              </div>
            </div>
            <div>
              <span className="meta ">
                <TimeAgo datetime={getUTCAwareTime(item.updated_at)} opts={{ minInterval: 60 }} />
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            className="rounded-top me-1"
            style={{
              height: 20,
              width: 20,
            }}
            src={imgSrc}
            title={imgTitle}
            alt="chat-producer-type"
          />
        </div>
      </a>
      {/* <div className="chat-actions">
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li onClick={() => propAction(item.id, "fav", item.fav ? false : true)}>
                <DropdownItem
                  tag="a"
                  href="#unread"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Mark as {item.fav ? `Unfavorite` : "Favorite"}
                </DropdownItem>
              </li>

              <li onClick={() => propAction(item.id, "unread", item.unread ? false : true)}>
                <DropdownItem
                  tag="a"
                  href="#unread"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Mark as {item.unread ? `Read` : "Unread"}
                </DropdownItem>
              </li>
              <li onClick={() => propAction(item.id, "archive", item.archive ? false : true)}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Mark as {item.archive ? `Unarchive` : "Archive"}
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div> */}
    </li>
  );
};
