import React, { useRef, useState } from "react";
import { Button, Label, Spinner } from "reactstrap";
import { RSelect } from "../Component";
// import { Icon } from "../Component";

const SettingsSelectInput = ({
  title,
  input_name,
  optionTextKey,
  optionValueKey,
  input_placeholder,
  input_value,
  selectOptions,
  subTitle = (
    <p className="text-muted sub-text-sm">
      Click{" "}
      <span className="text-primary fw-medium">
        <u>here</u>
      </span>{" "}
      to get your API key.
    </p>
  ),
  onChange,
  onClose = (_) => {},
  onSubmit = (_) => {},
  ...props
}) => {
  const [editing, setediting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  return (
    <div className="form-group ">
      <Label htmlFor="default-0" className="form-label">
        {title}
      </Label>
      {subTitle ? subTitle : false}

      <div className="d-flex align-items-center justify-content-between">
        <div className="form-control-wrap w-100">
          <RSelect
            name={input_name}
            placeholder={input_placeholder}
            options={selectOptions}
            getOptionLabel={(option) => option[optionTextKey]}
            getOptionValue={(option) => option[optionValueKey]}
            value={selectOptions.find((d) => d.value === input_value)}
            isDisabled={!editing}
            onChange={(e) => {
              const event = {
                target: {
                  name: input_name,
                  value: e.value,
                },
              };
              onChange(event);
            }}
          />
        </div>

        {!editing ? (
          <strong
            onClick={(_) => {
              setediting(true);
            }}
            style={{
              color: "#733aea",
            }}
            className="ms-2 pe-1 link cursor-pointer"
          >
            Edit
          </strong>
        ) : null}
      </div>
      {editing ? (
        <div className="sor-footer d-flex align-items-center justify-content-start" style={{ marginTop: 10 }}>
          <Button
            outline
            color="primary"
            className="btn-dim sor-action-button me-2"
            onClick={(_) => {
              onClose();
              setediting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(_) => {
              setLoading(true);
              onSubmit((_) => {
                setediting(false);
                setLoading(false);
              });
            }}
            color="primary"
            className="sor-action-button position-relative"
            form="chatbot-addedit-form"
          >
            {isLoading ? <Spinner size="sm" color="light" /> : "Save"}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default SettingsSelectInput;
