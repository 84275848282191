import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_SUBSCRIPTION, SUBSCRIPTION } from "../../constants/subscription.constant";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    [SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION]: INITIAL_SUBSCRIPTION,
    [SUBSCRIPTION.STORE.SUBSCRIPTION_LIST]: [],
    [SUBSCRIPTION.STORE.INVOICES]: [],

  },
  reducers: {
    setsubscriptionData(state, { payload }) {
      state[payload.key] = payload.value;
    },
  },
});

export const { setsubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
