import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import CopyPayment from "../pages/panel/copywriter/Payment";
import CopyPricingPlans from "../pages/panel/copywriter/PricingPlans";
import CopyProfile from "../pages/panel/copywriter/Profile";

import Chatbot from "../pages/panel/copywriter/chatbot/Chatbot";
import ViewChatbotIntegration from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotIntegration";
import ViewChatbotPublish from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotPublish";
import ViewChatbotTrain from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotTrain";
import ViewChatbotConversation from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotConversation";

import Settings from "../pages/panel/copywriter/Settings";
import HelpCenter from "../pages/panel/copywriter/HelpCenter";

import PrivateOutlet from "../pages/auth/PrivateOutlet";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";

import LayoutNoSidebar from "../layout/Index-nosidebar";
import LayoutCopywriter from "../layout/Index-copywriter";
import ChatbotList from "../pages/panel/copywriter/chatbot/ChatbotList";
import AdminOutlet from "../pages/auth/AdminOutlet";
import VerifyTokenToSignin from "../pages/auth/VerifyTokenToSignin";
import AdminLogin from "../pages/auth/AdminLogin";
import ViewChatbotSettings from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotSettings";
import ViewChatbotLead from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotLead";
import { useTheme, useThemeUpdate } from "../layout/provider/Theme";
import ViewChatbotBranding from "../pages/panel/copywriter/chatbot/details-tab/ViewChatbotBranding";
import LogIndex from "../pages/panel/copywriter/logs/LogIndex";

const Pages = () => {
  const location = useLocation();
  const themeUpdate = useThemeUpdate();
  const theme = useTheme()

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname.includes('chats/') || location.pathname.includes('profile') || location.pathname.includes('settings')) {
      if (!theme.sidebarVisibility)
        themeUpdate.sidebarShow()
    }
    else themeUpdate.sidebarHide()
  }, [location, theme.sidebarVisibility]);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<PrivateOutlet />}>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutCopywriter />}>
          <Route index element={<Navigate to="/chats" />} />
          <Route path="chats" element={<ChatbotList />}></Route>
          <Route path="subscription" element={<CopyPricingPlans />}></Route>
          <Route path="subscription-management" element={<AdminOutlet />}></Route>
          <Route path="profile" element={<CopyProfile />}></Route>
          <Route path="settings" element={<Settings />}></Route>
          <Route path="logs" element={<LogIndex/>}></Route>
          <Route path="help-center" element={<HelpCenter />}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<PrivateOutlet />}>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutCopywriter />}>
          <Route path="chats/:brainId/" element={<Chatbot />}>
            <Route path="train" element={<ViewChatbotTrain />}></Route>
            <Route path="conversation" element={<ViewChatbotConversation />}></Route>
            <Route path="publish" element={<ViewChatbotPublish />}></Route>
            <Route path="integrate" element={<ViewChatbotIntegration />}></Route>
            <Route path="lead" element={<ViewChatbotLead />}></Route>
            <Route path="branding" element={<ViewChatbotBranding />}></Route>           
            <Route path="settings" element={<ViewChatbotSettings />}></Route>
          </Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<PrivateOutlet />}>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
          <Route path="payment" element={<CopyPayment />}></Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route path="auth-login" element={<Login />}></Route>
        <Route path="admin-login" element={<AdminLogin />}></Route>
        <Route path="callback" element={<VerifyTokenToSignin />}></Route>

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>

        <Route path="invoice-print/:invoiceId" element={<InvoicePrint />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
