/* Instruments */
import { authSlice, conversationSlice, subscriptionSlice, workspaceSlice, xtableSlice } from "./slices";
import { commonSlice } from "./slices/common.store";

export const reducer = {
  auth: authSlice.reducer,
  xtable: xtableSlice.reducer,
  workspace: workspaceSlice.reducer,
  conversation: conversationSlice.reducer,
  common: commonSlice.reducer,
  subscription: subscriptionSlice.reducer,
};
