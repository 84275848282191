import React, { useEffect, useState } from "react";
import { Button, Icon } from "../../../../../../components/Component";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { TrainConfig } from "../../../../../../config/train.config";
import ExternalSourceAccrodion from "./ExternalSourceAccrodion";

const AddEditExternalSource = () => {
  const [sorId, setSorId] = useState(TrainConfig.events.externalSource.sorId);

  function close() {
    dispatchEvent(new Event(TrainConfig.events.externalSource.sorToggle));
  }
  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={true} haveOverlayToggle={true} editType="zapier">
        <div>
          <div>
            <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
            <div className="sor-header"></div>
            <div className="sor-body-header-footer-backbtn w-100">
              <div>
                <h5> Create a Data Source</h5>
                <p>
                  Follow the steps to integrate MagnushAI and Data source. Stuck? Check out our Help Center for details
                  and troubleshooting.
                </p>
              </div>

              <div className="mt-4">
                <ExternalSourceAccrodion />
              </div>
            </div>
            <div className="sor-footer d-flex align-items-center justify-content-end">
              <Button color="primary" className="sor-action-button position-relative" onClick={close}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditExternalSource;
