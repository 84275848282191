import React from "react";
import { Bars, Oval } from "react-loader-spinner";

const Loading = ({ height = 50, width = 50, shape = "oval", isLoading = false }) => {
  return (
    <div className="loading-overlay">
      <div className="loading-overlay-background" />
      {shape === "oval" && (
        <Oval
          height={height}
          width={width}
          color="#0F172A"
          wrapperStyle={{}}
          wrapperClass="loading-overlay-icon"
          visible={isLoading}
          ariaLabel="oval-loading"
          secondaryColor="#94A3B8"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      )}

      {shape === "bars" && (
        <Bars
          height={height}
          width={width}
          color="#0F172A"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="loading-overlay-icon"
          visible={isLoading}
        />
      )}
    </div>
  );
};

export default Loading;
