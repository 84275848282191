export const copywritermenu = [
  // {
  //   text: "Dashboard",
  //   icon: "presentation",
  //   link: "/dashboard",
  // },
  {
    text: "Chatbots",
    icon: "chat",
    link: "/chats",
  },
  // {
  //   text: "Documents",
  //   icon: "folder-list",
  //   subMenu: [
  //     {
  //       text: "Saved",
  //       link: "/document-saved",
  //     },
  //     {
  //       text: "Drafts",
  //       link: "/document-drafts",
  //     },
  //   ],
  // },
  // {
  //   text: "Editor",
  //   icon: "edit",
  //   link: "/document-editor",
  // },
  // {
  //   text: "Templates",
  //   icon: "layers",
  //   link: "/templates",
  // },
  // {
  //   text: "History",
  //   icon: "clock",
  //   link: "/history",
  // },
  {
    text: "Subscriptions",
    icon: "sign-usdc",
    link: "/subscription",
  },
  {
    text: "Manage Subscriptions",
    icon: "sign-usdc",
    link: "/subscription-management",
  },
  {
    text: "Profile",
    icon: "user",
    link: "/profile?tab=profile",
  },
  {
    text: "Settings",
    icon: "setting",
    link: "/settings?tab=api_key",
  },
  {
    text: "Logs",
    icon: "view-panel-fill",
    link: "/logs",
  },
  {
    text: "Help Center",
    icon: "help",
    link: "/help-center",
  },
  // {
  //   text: "Payments",
  //   icon: "wallet",
  //   newTab: true,
  //   link: "/payment",
  // },
];

export const copywriterheadermenu = [
  // {
  //   text: "Documents",
  //   subMenu: [
  //     {
  //       text: "Saved",
  //       link: "/document-saved",
  //     },
  //     {
  //       text: "Drafts",
  //       link: "/document-drafts",
  //     },
  //   ],
  // },
  // {
  //   text: "Editor",
  //   link: "/document-editor",
  // },
  // {
  //   text: "Templates",
  //   link: "/templates",
  // },
  // {
  //   text: "Basic",
  //   link: "/",
  // },
];
