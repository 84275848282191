
export const CHATBOT_OPTIONS = [
  {
    id: 'train',
    text: 'Knowledge'
  },
  {
    id: 'publish',
    text: 'Set Up'
  },
  {
    id: 'lead',
    text: 'Lead'
  },
  {
    id: 'conversation',
    text: 'Conversation'
  },
  {
    id: 'integrate',
    text: 'Integrate'
  },
  {
    id: 'branding',
    text: 'Branding'
  },
  {
    id: 'settings',
    text: 'Settings'
  },
]
