export const getSubscriptionDataModel = {
  name: "", //string
  number_of_allowed_published_chatbots: '', //number
  number_of_allowed_personal_chatbots: '', //number
  number_of_allowed_messages_per_chatbot: '', //number
  number_of_allowed_users_per_chatbot: '', //number
  number_of_allowed_file_uploads_per_month: '', //number
  number_of_allowed_workspaces: '', //number
  train_with_website_crawling_enabled: null, //boolean
  chat_widget_customization_enabled: null, //boolean
  branding_removal_enabled: null, //boolean
  custom_domain_and_white_labeling_enabled: null, //boolean
  support_priority_type: null, //choice-value number
  email_and_chat_support_enabled: null, //boolean
};
export const getPricingDataModel = {
  monthly_amount: '', //number
  yearly_amount: '', //number
};

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
