import { toast } from "react-toastify";
import { Icon } from "../components/Component";
import { TOAST } from "../constants";

const CustomToast = ({ title, message }) => {
  return (
    <div className="">
      <h5>{title}</h5>
      <p>{message}</p>
    </div>
  );
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

export const messageToast = (
  type = TOAST.TYPE.DEFAULT,
  { title = "Title", message = "Message", position = TOAST.POSITION.BOTTOM_RIGHT, time = TOAST.SUCCESS_TIME }
) => {
  if (type === TOAST.TYPE.SUCCESS) {
    toast.success(<CustomToast title={title} message={message} />, {
      position: `${position}`,
      closeButton: <CloseButton />,
      autoClose: time,
    });
  } else if (type === TOAST.TYPE.ERROR) {
    toast.error(<CustomToast title={title} message={message} />, {
      position: `${position}`,
      closeButton: <CloseButton />,
      autoClose: time,
    });
  } else if (type === TOAST.TYPE.INFO) {
    toast.info(<CustomToast title={title} message={message} />, {
      position: `${position}`,
      closeButton: <CloseButton />,
      autoClose: time,
    });
  } else {
    toast(<CustomToast />, {
      position: `${position}`,
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
    });
  }
};

export const notify = (
  type = TOAST.TYPE.DEFAULT,
  { title, message, position = TOAST.POSITION.TOP_CENTER, time = TOAST.SUCCESS_TIME }
) => {
  if (type === TOAST.TYPE.SUCCESS) {
    toast.success(`${title}`, {
      position: position,
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: false,
      theme: "colored",
    });
  } else if (type === TOAST.TYPE.ERROR) {
    toast.error(
      <div>
        {title && <div>{title}</div>}
        {message && <div>{message}</div>}
      </div>,
      {
        position: position,
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: false,
        theme: "colored",
      }
    );
  }
};
