import React from "react";
import "./switch.css";

const Switch = ({ isOn, handleToggle, onColor }) => {
  return (

      <label className="react-switch">
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          type="checkbox"
        />
        <div className="react-switch-button align-center justify-center" >
          <span
            className="swicth-container"
          >{
              isOn ?
                "Yearly"
                : "Monthly"
            }</span>
        </div>
        <div className="react-switch-labels">
          <span
            className="swicth-txt"

          >Monthly</span>
          <span
            className="swicth-txt"
          >Yearly</span>
        </div>
      </label>
  );
};

export default Switch;
