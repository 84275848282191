import React, { useEffect, useState } from "react";
import { Button, Icon } from "../../../../../../components/Component";

import { useForm } from "react-hook-form";
import SlideOverX from "../../../../../../components/sor/SlideOverX";
import { TrainConfig } from "../../../../../../config/train.config";
import Emitter from "../../../../../../services/eventEmitterService";
import { consoleLog } from "../../../../../../utils/Utils";

const AddEditTrainText = () => {
  const [sorId, setSorId] = useState(TrainConfig.events.text.sorId);
  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState({ fileName: "", description: "" });

  useEffect(() => {
    Emitter.once(TrainConfig.events.text.editingData, (data) => {
      if (data.id) {
        setEdit(true);
        setFormData({ fileName: data.fileName, description: data.description });
      } else {
        setEdit(false);
      }
      consoleLog("event-emitter", data);
    });

    return () => {
      consoleLog("event-emitter-destroy");
      Emitter.off(TrainConfig.events.text.editingData, {});
    };
  }, []);

  function close() {
    resetData();
    dispatchEvent(new Event(TrainConfig.events.text.sorToggle));
    consoleLog("close");
  }

  function resetData() {
    setFormData({ fileName: "", description: "" });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onFormSubmit(form) {
    consoleLog("onFormSubmit", form);
    // const { title } = form;

    // let submittedData = {
    //   fileName: title,
    //   file: files.length > 0 ? files[0].preview : null,
    // };
    // // setData([submittedData, ...data]);
    // setFiles([]);
    // // resetForm();
  }

  return (
    <React.Fragment>
      <SlideOverX id={sorId} isOverlayVisible={false}>
        <div>
          <Icon name="arrow-to-right" className="fs-5 cursor-pointer" onClick={close} />
          <div className="sor-header">
            <h6>{isEdit ? "Edit" : "Add"} Text</h6>
          </div>
          <div className="sor-body-header-footer-backbtn ">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="form-control"
                      {...register("fileName", {
                        required: "This field is required",
                      })}
                      value={formData.fileName}
                      onChange={(e) => setFormData({ ...formData, fileName: e.target.value })}
                    />
                    {errors.fileName && <span className="invalid">{errors.fileName.message}</span>}
                  </div>
                </div>
              </div>
              <div className="w-100 mb-2">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea
                      rows="9"
                      placeholder="Enter description"
                      className="form-control no-resize"
                      {...register("description", {
                        required: "This field is required",
                      })}
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                    {errors.description && <span className="invalid">{errors.description.message}</span>}
                  </div>
                </div>
              </div>
              <div className="w-100 my-2 bg-green-100 rounded-md p-2">
                <p className=" text-xs mb-1">
                  <strong>Notes</strong>
                </p>
                <ul>
                  <li className="list-item text-xs">Acceptable formats: Text-based PDFs or TXT  in English</li>
                  <li className="list-item text-xs">Maximum file size: 20MB</li>
                  <li className="list-item text-xs">Content restriction: Images will not be extracted</li>
                  <li className="list-item text-xs">
                    Unsupported features: Files with multiple text columns, encryption, or password protection
                  </li>
                </ul>
              </div>
              <Button color="primary" type="submit" className="d-none"></Button>
            </form>
          </div>
          <div className="sor-footer d-flex align-items-center justify-content-end">
            <Button outline color="primary" className="btn-dim sor-action-button me-2" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" className="sor-action-button" type="submit">
              {isEdit ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </SlideOverX>
    </React.Fragment>
  );
};

export default AddEditTrainText;
