import React, { useEffect, useState } from "react";
import { consoleLog } from "../../utils/Utils";

const SlideOverX = ({
  id = "sor",
  fullWidth = false,
  editType = "default",
  isOverlayVisible = true,
  haveOverlayToggle = false,
  xDirection = "right", //ex: right or left
  onClose = (_) => {},
  ...props
}) => {
  const [togglingEvent, setTogglingEvent] = useState(`sor-toggle-${id}`);
  const [isOpen, setOpen] = useState(false);
  const [sorWidthSize, setSorWidthSize] = useState("");

  function toggle() {
    setOpen((p) => !p);
  }

  function overlayToggle() {
    if (haveOverlayToggle) {
      onClose();
      toggle();
    }
  }
  function handleEvents() {
    window.addEventListener(togglingEvent, () => {
      toggle();
      consoleLog(togglingEvent + " listened-toggle");
    });
  }

  useEffect(() => {
    if (editType === "subscription") {
      if (fullWidth) {
        setSorWidthSize("w-100");
      } else {
        setSorWidthSize("sor-w-30");
      }
    } else if (editType === "zapier") {
      if (fullWidth) {
        setSorWidthSize("w-100");
      } else {
        setSorWidthSize("sor-w-40");
      }
    } else if (editType === "upload-web") {
      if (fullWidth) {
        setSorWidthSize("w-100");
      } else {
        setSorWidthSize("sor-w-40");
      }
    } else if (editType === "default") {
      if (fullWidth) {
        setSorWidthSize("w-100");
      } else {
        setSorWidthSize("sor-w-25");
      }
    }
  }, [fullWidth, editType]);

  useEffect(() => {
    handleEvents();
  }, []);

  return (
    <React.Fragment>
      <div
        className={`sor  border  sor-toggle-slide shadow ${
          isOpen ? "sor-content-active " : ""
        } ${sorWidthSize} sor-slide-${xDirection}`}
      >
        <div>{props.children}</div>
      </div>
      {isOpen && isOverlayVisible && <div className="sor-toggle-overlay" onClick={overlayToggle}></div>}
    </React.Fragment>
  );
};

export default SlideOverX;
