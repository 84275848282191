import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NoDataImg from "../../assets/images/placeholder/no-data-undraw.svg";
import {
  fetchData,
  getCurrentLimit,
  getCurrentPage,
  getData,
  getMeta,
  getPaginationSummary,
  getQueryString,
  getRequestBusyStatus,
  reset,
  setEndpoint,
  setQso,
  setXtId,
  updateLimit,
  updatePage,
  updateQueryString,
} from "../../redux";
import { XTABLE } from "../../constants";
import { useSelector } from "react-redux";
import Loading from "../loading/Loading";
import { PaginationComponent, RSelect } from "../Component";

const dataLimitOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
];

export const XTable = ({
  xtId = "xtable",
  endpoint = "",
  qso = { [XTABLE.STORE.QSO.VALUE.PREPEND]: "?", [XTABLE.STORE.QSO.VALUE.APPEND]: "" },
  showPagination = true,
  headers = [],
  wrapperClass = "xtable-wrapper",
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    //REDUX_ACTIONS
    // dispatch(reset({ [XTABLE.STORE.XTID]: xtId }));
    dispatch(setXtId({ [XTABLE.STORE.XTID]: xtId }));
    dispatch(setEndpoint({ [XTABLE.STORE.ENDPOINT]: endpoint }));
    dispatch(setQso(qso));

    return () => {
      dispatch(reset({ [XTABLE.STORE.XTID]: xtId }));
    };
  }, []);

  //GET_QUERY_STRING
  const currentQueryString = useSelector(getQueryString);
  //FETCH_DATA
  useEffect(() => {
    dispatch(updateQueryString({ [XTABLE.STORE.QUERY]: currentQueryString }));
    dispatch(fetchData());
  }, [currentQueryString]);

  //REDUX_GETTERS
  const reqStatus = useSelector(getRequestBusyStatus);
  const resData = useSelector(getData);
  const resMeta = useSelector(getMeta);
  const currentPaginationSummary = useSelector(getPaginationSummary);
  const currentLimit = useSelector(getCurrentLimit);
  const currentPage = useSelector(getCurrentPage);

  const haveNoData = !reqStatus && (!resData || resData.length === 0);

  //DATA
  const [selectedLimitOption, setLimitOption] = useState({ label: `${currentLimit}`, value: currentLimit });
  useEffect(() => {
    setLimitOption({ label: `${currentLimit}`, value: currentLimit });
  }, [currentLimit]);

  //METHOD
  const setSelectedDataLimitOption = (e) => {
    setLimitOption(e);
    dispatch(updateLimit({ [XTABLE.STORE.QSC.VALUE.LIMIT]: e.value }));
  };

  const paginate = (pageNumber) => dispatch(updatePage({ [XTABLE.STORE.QSC.VALUE.PAGE]: pageNumber }));

  return (
    <React.Fragment>
      <div id={`${xtId}-container`} className="xtable-container">
        {/* TABLE */}
        <section id={`${xtId}-wrapper`} className={wrapperClass}>
          <table id={`${xtId}-table`} className="xtable">
            <thead className="xtable-header">
              <tr className="xtable-header-row">
                {headers.map((header, headerIndex) => {
                  return (
                    <th
                      key={`xtable-header-row-item-${headerIndex}`}
                      className="xtable-header-row-item"
                      width={header.width}
                    >
                      {header.text}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {resData && <tbody className="xtable-body">{props.children}</tbody>}
          </table>
          {reqStatus && <Loading height={65} width={65} isLoading={reqStatus} />}

          {haveNoData && (
            <div className="xtable-placeholder">
              <img src={NoDataImg} className="xtable-placeholder-image" alt="No Data" />
              <div className="xtable-placeholder-text">Oops! No Data Found</div>
            </div>
          )}
        </section>

        {/* BOTTOM */}
        {showPagination && !haveNoData && (
          <section className="xtable-bottom mt-2">
            <div className="xtable-bottom-start ">
              <span className="me-2">Show</span>
              <div style={{ width: "80px", height: "40px" }}>
                <RSelect value={selectedLimitOption} onChange={setSelectedDataLimitOption} options={dataLimitOptions} />
              </div>
              <span className="ms-2">Entries</span>
            </div>
            <div className="xtable-bottom-center">{currentPaginationSummary}</div>
            <div className="xtable-bottom-end">
              <PaginationComponent
                itemPerPage={selectedLimitOption.value}
                totalItems={resMeta ? resMeta.count?.total : 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </section>
        )}
      </div>
    </React.Fragment>
  );
};
