export const INITIAL_SUBSCRIPTION = {
  "id": 0,
  "support_priority_type_display_text": "Same day",
  "is_default": false,
  "created_at": new Date().toISOString(),
  "updated_at": new Date().toISOString(),
  "version": 0,
  "name": "",
  "number_of_allowed_published_chatbots": 0,
  "number_of_allowed_personal_chatbots": 0,
  "number_of_allowed_messages_per_chatbot": 0,
  "number_of_allowed_users_per_chatbot": 0,
  "train_with_website_crawling_enabled": false,
  "number_of_allowed_file_uploads_per_month": 0,
  "chat_widget_customization_enabled": false,
  "number_of_allowed_workspaces": 0,
  "branding_removal_enabled": false,
  "custom_domain_and_white_labeling_enabled": false,
  "support_priority_type": 0,
  "email_and_chat_support_enabled": false,
  "stripe_product_id": "",
  "created_by": "",
  "updated_by": ""
}


export const SUBSCRIPTION = {
  STORE: {
    DEFAULT_SUBSCRIPTION: 'default_subscription',
    SUBSCRIPTION_LIST: 'subscription_list',
    INVOICES: 'invoices'
  }
}
