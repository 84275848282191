export const getChatbotDataModel = {
  name: null, //string
  description: null, //string
  fetch_data_from_external_source_enabled:false //boolean
};

export const getChatbotChatSettingsDataModel = {
  branding_removal_enabled: null,
};
export const getChatbotSubscriptionDataModel = {
  theme: "#ffffff",
  title: "Assistant",
  icon: null,
  placeholder_text: null,
  welcome_message: null,
  should_show_branding: true,
};

export const getChatbotAISettingsDataModel = { base_prompt: "", restrictions: "", model: "", magnus_ai_api_key: "" };
