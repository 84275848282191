import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Icon, PreviewCard } from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AUTH_SLICE_FIELDS, login, setAuthData } from "../../redux";
import { formBody, httpClient } from "../../services";
import { AUTH, LOCAL_STORAGE_ITEMS, URLS } from "../../constants";

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);

  const [errorVal, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = (formData) => {
    setLoading(true);
    httpClient
      .post(
        URLS.AUTH.LOGIN,
        formBody({
          grant_type: "password",
          username: formData.username,
          password: formData.password,
        }),
        {
          auth: {
            username: `${process.env.REACT_APP_CLIENT_ID}`,
            password: `${process.env.REACT_APP_CLIENT_SECRET}`,
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        httpClient.get(URLS.AUTH.PROFILE).then((profileResp) => {
          dispatch(setAuthData({ key: AUTH.STORE.USER, value: profileResp.data }));
          localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_TYPE, profileResp.data.user_type);
        });

        localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN]);
        localStorage.setItem(
          LOCAL_STORAGE_ITEMS.ACCESS_TOKEN_TIMESTAMP,
          new Date().getTime() + res.data.expires_in * 1000 - 60000
        ); // having 1 minute(60000 ms) as threshold
        localStorage.setItem(LOCAL_STORAGE_ITEMS.REFRESH_TOKEN, res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN]);
        const flag = {
          [AUTH_SLICE_FIELDS.LOADED]: true,
          [AUTH_SLICE_FIELDS.LOGGED_IN]: true,
          [AUTH_SLICE_FIELDS.ACCESS_TOKEN]: res.data[LOCAL_STORAGE_ITEMS.ACCESS_TOKEN],
          [AUTH_SLICE_FIELDS.REFRESH_TOKEN]: res.data[LOCAL_STORAGE_ITEMS.REFRESH_TOKEN],
        };
        dispatch(login(flag));
        navigate(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
      })
      .catch((e) => {
        setTimeout(() => {
          setError(e.response.data.error_description);
          setLoading(false);
        }, 1000);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Head title="Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>

        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle className="text-center" tag="h4">
                Sign-In
              </BlockTitle>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> Unable to login with credentials{" "}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Username
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register("username", { required: "This field is required" })}
                  placeholder="Enter your username"
                  className="form-control-lg form-control"
                />
                {errors.username && <span className="invalid">{errors.username.message}</span>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("password", { required: "This field is required" })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.password && <span className="invalid">{errors.password.message}</span>}
              </div>
            </div>
            <div className="form-group">
              <Button size="lg" className="btn-block" type="submit" color="primary">
                {loading ? <Spinner size="sm" color="light" /> : "Continue"}
              </Button>
            </div>
          </Form>
        </PreviewCard>
      </Block>
    </>
  );
};
export default AdminLogin;
