import { createSlice } from "@reduxjs/toolkit";

export const AUTH_SLICE_FIELDS = {
  "LOADED": "loaded",
  "LOGGED_IN": "loggedIn",
  "ACCESS_TOKEN": "accessToken",
  "REFRESH_TOKEN": "refreshToken",
  "USER": "user",
}

export const INITIAL_USER = {
  name: "",
  address: "",
  phone: "",
  email: ""
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {
      [AUTH_SLICE_FIELDS.LOADED]: false,
      [AUTH_SLICE_FIELDS.LOGGED_IN]: false,
      [[AUTH_SLICE_FIELDS.ACCESS_TOKEN]]: null,
      [AUTH_SLICE_FIELDS.REFRESH_TOKEN]: null,
      [AUTH_SLICE_FIELDS.USER]: INITIAL_USER,
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.auth.user = action.payload;
    },
    login: (state, action) => {
      state.auth.loaded = action.payload.loaded;
      state.auth.loggedIn = action.payload.loggedIn;
      state.auth.accessToken = action.payload.accessToken;
      state.auth.refreshToken = action.payload.refreshToken;
    },
    loadedLoggedIn: (state, action) => {
      state.auth.loaded = action.payload.loaded;
      state.auth.loggedIn = action.payload.loggedIn;
    },
    logout: (state, action) => {
      state.auth = {
        loaded: false,
        loggedIn: false,
        accessToken: null,
        refreshToken: null,
        user: INITIAL_USER,
      };
    },
    setAuthData: (state, action) => {
      state.auth[action.payload.key] = action.payload.value;
    },
  },
});

export const { setUser, login, loadedLoggedIn, logout, setAuthData } =
  authSlice.actions;

export default authSlice.reducer;
