export const TrainConfig = {
  api: {},
  events: {
    common: {
      name: "train",
      // refresh-index-data
      refresh: `rid-train`,
    },
    document: {
      // slideover-right
      sorId: "train-doc",
      sorToggle: "sor-toggle-train-doc",
      // editing-data
      editingData: "edit-train-doc-data",
    },
    text: {
      // slideover-right
      sorId: "train-text",
      sorToggle: "sor-toggle-train-text",
      // editing-data
      editingData: "edit-train-text-data",
    },
    web: {
      // slideover-right
      sorId: "train-web",
      sorToggle: "sor-toggle-train-web",
      // editing-data
      editingData: "edit-train-web-data",
    },
    externalSource: {
      // slideover-right
      sorId: "train-external-source",
      sorToggle: "sor-toggle-train-external-source",
      // editing-data
      editingData: "edit-train-external-source-data",
      //refresh
      refresh: `rid-external-source`,
    },
  },
};
