import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { Icon } from "../../../../../components/Component";
import { useParams } from "react-router";
import { httpClient } from "../../../../../services";
import { TOAST, URLS } from "../../../../../constants";
import { notify } from "../../../../../services/toastService";
import { getChatbotAISettingsDataModel } from "../helper";
import SettingsTextareaInput from "../../../../../components/input/SettingsTextareaInput";
import SettingsSelectInput from "../../../../../components/input/SettingsSelectInput";
import CopyToClipboard from "react-copy-to-clipboard";
import { consoleLog } from "../../../../../utils/Utils";

const ViewChatbotSettings = () => {
  const [brainSettings, setBrainSettings] = useState(getChatbotAISettingsDataModel);
  const [aiModelList, setAiModelList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { brainId } = useParams();

  useEffect(() => {
    httpClient.get(URLS.CHATBOT.SETTINGS.CHAT_SETTINGS(brainId)).then((res) => {});

    httpClient.get(URLS.CHATBOT.SETTINGS.AI_MODEL_LIST).then((res) => {
      consoleLog("AI_MODEL_LIST", res.data);
      setAiModelList(res.data);
    });

    httpClient.get(URLS.CHATBOT.DETAILS(brainId)).then((res) => {
      const brainData = {
        base_prompt: res.data.base_prompt,
        restrictions: res.data.restrictions,
        model: res.data.model,
        magnus_ai_api_key: res.data.magnus_ai_api_key,
      };
      setBrainSettings(brainData);
    });
  }, [brainId]);

  const updateAISettingsFormData = (key, value) => {
    consoleLog("updateAISettingsFormData", key, value);
    setBrainSettings((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleAISettingsInputChange = (event) => {
    const { name, value } = event.target;
    updateAISettingsFormData(name, value);
  };

  function onhandleAISettingsSave(key, callBack) {
    if (loading) return;
    setLoading(true);
    const url = URLS.CHATBOT.UPDATE(brainId);
    const saveFormData = new FormData();
    saveFormData.append(key, brainSettings[key]);
    httpClient
      .patch(url, saveFormData)
      .then(() => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
        callBack();
      })
      .catch((err) => {
        consoleLog("chat-settings-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => setLoading(false));
  }

  function onhandleApiKeyChange() {
    httpClient
      .patch(URLS.CHATBOT.SETTINGS.REFRESH_API_KEY(brainId))
      .then((res) => {
        setBrainSettings((preData) => {
          return { ...preData, magnus_ai_api_key: res.data.magnus_ai_api_key };
        });
        notify(TOAST.TYPE.SUCCESS, { title: "Changed" });
      })
      .catch((err) => {
        consoleLog("api-key-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }
  function onhandleCopy() {
    notify(TOAST.TYPE.SUCCESS, { title: "Copied" });
  }
  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <div>
        <div>
          <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
            AI Configuration
          </p>
          <p className="text-xs text-gray-500 -mt-1">Setup your chatbot with AI credential</p>
        </div>
        <div className="my-4 ">
          <div className="mb-2">
            <SettingsSelectInput
              title={"Model"}
              subTitle=""
              input_name={"model"}
              input_placeholder={"Select a Model"}
              input_value={brainSettings.model}
              selectOptions={aiModelList}
              optionTextKey="title"
              optionValueKey="value"
              isLoading={loading}
              onChange={handleAISettingsInputChange}
              onSubmit={(callBack) => {
                onhandleAISettingsSave("model", callBack);
              }}
            />
            <p className="text-xs text-gray-500 -mt-2">
              {" "}
              GPT-4 is the smartest, but also the most expensive. GPT-4 Turbo is a great balance between 3.5 and 4, but
              has rate limits while in Preview.
            </p>
          </div>
          <div className="mb-2">
            <SettingsTextareaInput
              title={"Base Prompt"}
              subTitle=""
              input_name={"base_prompt"}
              input_placeholder={"Enter base prompt"}
              input_value={brainSettings.base_prompt}
              onChange={handleAISettingsInputChange}
              onSubmit={(callBack) => {
                onhandleAISettingsSave("base_prompt", callBack);
              }}
            />
            <p className="text-xs text-gray-500 -mt-2">
              {" "}
              This is the base prompt that provides context to the AI model. Use it to describe what your chatbot can do
              and how it can help the user.
            </p>
          </div>
          <div className="mb-2">
            <SettingsTextareaInput
              title={"Restrictions"}
              subTitle=""
              input_name={"restrictions"}
              input_placeholder={"Enter Restrictions"}
              input_value={brainSettings.restrictions}
              onChange={handleAISettingsInputChange}
              onSubmit={(callBack) => {
                onhandleAISettingsSave("restrictions", callBack);
              }}
            />
            <p className="text-xs text-gray-500 -mt-2"> Streamlining Communication Through Concise Responses</p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
            API Key
          </p>
        </div>

        <div className="mt-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-control-wrap w-100">
              <span
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className={`form-icon  form-icon-right`}
              >
                <Icon name="copy" className="cursor-pointer hover:text-gray-700"></Icon>
              </span>
              <CopyToClipboard text={brainSettings.magnus_ai_api_key}>
                <span
                  className={`form-icon  form-icon-right`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    onhandleCopy();
                  }}
                >
                  <Icon name="copy" className="cursor-pointer hover:text-gray-700"></Icon>
                </span>
              </CopyToClipboard>
              <input
                type="text"
                className={` form-control is-hidden`}
                value={brainSettings.magnus_ai_api_key}
                disabled
              />
            </div>
            <strong
              onClick={(ev) => {
                ev.preventDefault();
                onhandleApiKeyChange();
              }}
              style={{
                color: "#733aea",
              }}
              className="ms-2 pe-1 link cursor-pointer"
            >
              Change
            </strong>
          </div>
        </div>
      </div>
      <p className="text-xs text-gray-500 mt-2">
        The API key provided below allows integration of your MagnusAI account with other services, such as Zapier.
      </p>
    </React.Fragment>
  );
};

export default ViewChatbotSettings;
