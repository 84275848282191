
export const PROFILE_OPTIONS = [
  {
    id: 'profile',
    text: 'Profile'
  },
  {
    id: 'billing',
    text: 'Payment & Billing'
  },
]
