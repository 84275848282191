import React, { useEffect, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { Button, Icon } from "../../../../../components/Component";
import { XTable, XTableRow, XTableRowItem } from "../../../../../components/xtable";
import { TOAST, URLS, XTABLE } from "../../../../../constants";
import { useParams } from "react-router";
import { getData } from "../../../../../redux";
import { useSelector } from "react-redux";

import Emitter from "../../../../../services/eventEmitterService";

import AddEditLeadConfig from "../components/lead/AddEditLeadConfig";
import { LeadConfig } from "../../../../../config/lead.config";
import { httpClient } from "../../../../../services";
import { notify } from "../../../../../services/toastService";
import { Card } from "reactstrap";
import { getSerializabledLeadItemData } from "../components/lead/helper";
import { consoleLog } from "../../../../../utils/Utils";

const ViewChatbotLead = () => {
  const { brainId } = useParams();
  const [leadConfigData, setLeadConfigData] = useState([]);
  const [leadItemData, setLeadItemData] = useState([]);
  useEffect(() => {
    fetchLeadConfigList();
  }, [brainId]);
  const resData = useSelector(getData);
  const leadItemTableHeaders = [
    { text: "Name", width: "20%", sort: null },
    { text: "Email", width: "30%", sort: null },
    { text: "Phone", width: "30%", sort: null },
    { text: "Others", width: "20%", sort: null },
  ];

  useEffect(() => {
    if (resData) {
      consoleLog("resData", getSerializabledLeadItemData(resData));
      setLeadItemData(getSerializabledLeadItemData(resData));
    }
  }, [resData]);

  function fetchLeadConfigList() {
    httpClient.get(URLS.CHATBOT.LEAD.LEAD_FIELD_LIST(brainId)).then((res) => {
      setLeadConfigData(res.data.data);
    });
  }

  function addLeadConfig() {
    Emitter.emit(LeadConfig.events.editingData, {});
    dispatchEvent(new Event(LeadConfig.events.sorToggle));
  }
  function editLeadConfig(item) {
    Emitter.emit(LeadConfig.events.editingData, item);
    dispatchEvent(new Event(LeadConfig.events.sorToggle));
  }
  function deleteLeadConfig(item) {
    httpClient
      .delete(URLS.CHATBOT.LEAD.LEAD_FIELD_DELETE(item.id))
      .then(() => {
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("lead-config-delete-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        fetchLeadConfigList();
      });
  }
  return (
    <React.Fragment>
      <AddEditLeadConfig onRefresh={fetchLeadConfigList} />
      <Head title="Lead"></Head>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
            Lead Configuration
          </p>

          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              addLeadConfig();
            }}
            className=" flex justify-around items-center"
          >
            <Icon name="plus"></Icon> <span>Add Custom Field</span>
          </Button>
        </div>
        <div className="mt-4">
          <Card>
            <table className="table table-middle mb-0">
              <thead className="table-light">
                <tr>
                  <th className="tb-col tb-col-md">
                    <div className="fs-13px text-base">Input Name</div>
                  </th>
                  <th className="tb-col tb-col-md">
                    <div className="fs-13px text-base">Message</div>
                  </th>
                  <th className="tb-col tb-col-md">
                    <div className="fs-13px text-base">Description</div>
                  </th>
                  <th className="tb-col tb-col-md">
                    <div className="fs-13px text-base">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {leadConfigData.map((item, index) => (
                  <tr key={`leadConfig-${index}`}>
                    <td className="tb-col tb-col-bg">
                      <strong>{item.name}</strong>
                    </td>
                    <td className="tb-col tb-col-bg">
                      <strong>{item.message}</strong>
                    </td>
                    <td className="tb-col tb-col-bg">
                      <strong>{item.description}</strong>
                    </td>

                    <td className="tb-col tb-col-bg">
                      <strong
                        onClick={(_) => {
                          editLeadConfig(item);
                        }}
                        style={{
                          color: "#733aea",
                        }}
                        className="pe-1 link cursor-pointer"
                      >
                        Edit
                      </strong>
                      <strong
                        onClick={(_) => {
                          deleteLeadConfig(item);
                        }}
                        style={{
                          color: "#733aea",
                        }}
                        className="ms-2 pe-1 link cursor-pointer"
                      >
                        Delete
                      </strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
      <div className="mt-5">
        <div className="mb-4">
          <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
            Lead Lists
          </p>
        </div>
        <div>
          <XTable
            xtId="lead-item"
            headers={leadItemTableHeaders}
            endpoint={`${URLS.CHATBOT.LEAD.LEAD_CAPTURE_DATA_LIST(brainId)}`}
            qso={{ [XTABLE.STORE.QSO.VALUE.PREPEND]: "&", [XTABLE.STORE.QSO.VALUE.APPEND]: "" }}
            wrapperClass="lead-list-wrapper"
          >
            {leadItemData &&
              leadItemData.map((item) => {
                return (
                  <XTableRow key={item.id}>
                    <XTableRowItem>{item.name}</XTableRowItem>
                    <XTableRowItem>{item.email}</XTableRowItem>
                    <XTableRowItem>{item.phone}</XTableRowItem>
                    <XTableRowItem>
                      {item.other.length
                        ? item.other.map((oData, oDataIndex) => (
                            <p key={`oData-${oDataIndex}`}>
                              {oData.title} : {oData.value}
                            </p>
                          ))
                        : null}
                    </XTableRowItem>
                  </XTableRow>
                );
              })}
          </XTable>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewChatbotLead;
