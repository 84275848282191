import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/d-sm.jpg";
import User4 from "../../../images/avatar/a-sm.jpg";

export const chatUserData = [
  {
    id: 1,
    user: "Illiash Hossain",
    active: true,
    theme: "primary",
  },
  {
    id: 2,
    user: "Abu Bin Ishtiak",
    active: true,
    theme: "blue",
  },
  {
    id: 3,
    user: "George Phillips",
    active: true,
    image: User,
    theme: "pink",
  },
  {
    id: 4,
    name: "Larry Hughes",
    image: User2,
    active: true,
    theme: "purple",
  },
  {
    id: 5,
    name: "Tammy Wilson",
    theme: "purple",
    active: true,
  },

  {
    id: 7,
    user: "Emile Clarke",
    active: true,
    image: User4,
  },
  {
    id: 8,
    user: "Shakel Krosser",
    theme: "info",
    active: true,
  },
  {
    id: 9,
    user: "Kumar Kamal",
    theme: "info",
    active: true,
  },
];

export const chatData = [
  {
    id: 1,
    name: "Illiash Hossain",
    nickname: "",
    theme: "purple",
    chatTheme: "purple",
    fav: false,
    active: "30m",
    date: "Now",
    unread: false,
    archive: false,
    delivered: true,
    convo: [
      // {
      //   id: "chat_1",
      //   chat: ["Hello!", "I found an issues when try to purchase the product."],
      //   date: "29 Apr, 2020 4:28 PM",
      // },
      // {
      //   id: "chat_2",
      //   me: true,
      //   chat: ["Thanks for inform. We just solved the issues. Please check now."],
      //   date: "29 Apr, 2020 4:12 PM",
      // },
      // {
      //   id: "chat_3",
      //   chat: ["This is really cool.", "It’s perfect. Thanks for letting me know."],
      //   date: "29 Apr, 2020 4:28 PM",
      // },
      // {
      //   meta: {
      //     metaID: "meta_1",
      //     metaText: "12 May, 2020",
      //   },
      // },
      // {
      //   id: "chat_4",
      //   chat: ["Hey, I am facing problem as i can not login into application. Can you help me to reset my password?"],
      //   date: "3:49 PM",
      // },
      // {
      //   id: "chat_5",
      //   me: true,
      //   date: "3:55 PM",
      //   chat: ["Definately. We are happy to help you."],
      // },
      // {
      //   id: "chat_6",
      //   date: "3:55 PM",
      //   chat: ["Thank you! Let me know when it done."],
      // },
      // {
      //   id: "chat_7",
      //   date: "3:55 PM",
      //   me: true,
      //   now: true,
      //   chat: [
      //     "We just reset your account. Please check your email for verification.",
      //     "Please confirm if your got email",
      //   ],
      // },
    ],
  },
  {
    id: 2,
    name: "Abu Bin Ishtiak",
    nickname: "",
    theme: "blue",
    chatTheme: "purple",
    date: "4.39 Am",
    active: "60m",
    unread: true,
    archive: false,
    fav: true,
    delivered: true,
    convo: [
      {
        id: "chat_1",
        chat: ["Hi I am Ishtiak, can you help me with something?"],
        date: "4:49 AM",
      },
      {
        id: "chat_2",
        me: true,
        chat: ["Thanks for inform. We just solved the issues. Please check now."],
        date: "4:12 PM",
      },
      {
        id: "chat_3",
        chat: ["This is really cool.", "It’s perfect. Thanks for letting me know."],
        date: "4:28 PM",
      },
    ],
  },
  {
    id: 3,
    name: "George Philips",
    nickname: "",
    image: User,
    chatTheme: "purple",
    fav: false,
    date: "6 Apr",
    unread: false,
    archive: false,
    active: true,
    delivered: true,
    convo: [
      {
        id: "chat_1",
        chat: ["Have you seens the claim from Rose?"],
        date: "6 Apr",
      },
      {
        id: "chat_3",
        me: true,
        chat: ["No I haven't. I will look into it", "It’s perfect. Thanks for letting me know."],
        date: "4:28 PM",
      },
    ],
  },
  {
    id: 4,
    name: "Larry Hughes",
    nickname: "",
    image: User2,
    fav: false,
    chatTheme: "purple",
    unread: false,
    archive: true,
    date: "19 Apr",
    active: true,
    delivered: "unread",
    convo: [
      {
        id: "chat_1",
        chat: ["Hi Frank! How is you doing?"],
        date: "3 Apr",
      },
      {
        id: "chat_3",
        me: true,
        chat: ["Hey, I have been great. It has been a long time.", "How have you been doing?."],
        date: "29 Apr, 2020 4:28 PM",
      },
      {
        id: "chat_4",
        chat: ["Great.", "How's is work going?. Are you going abroad?"],
        date: "29 Apr, 2020 4:28 PM",
      },
    ],
  },
  {
    id: 5,
    name: "Tammy Wilson",
    nickname: "",
    theme: "pink",
    chatTheme: "purple",
    active: true,
    fav: false,
    unread: false,
    archive: true,
    date: "2 May",
    delivered: "sent",
    convo: [
      {
        id: "chat_1",
        me: true,
        chat: ["I just bought a new computer but i am having some problem"],
        date: "27 Mar",
      },
    ],
  },

  {
    id: 7,
    name: "Emile Clarke",
    nickname: "",
    active: true,
    image: User4,
    chatTheme: "purple",
    unread: false,
    archive: false,
    date: "Now",
    fav: false,
    convo: [],
  },
  {
    id: 8,
    name: "Shakel Krosser",
    nickname: "",
    theme: "danger",
    chatTheme: "purple",
    active: true,
    unread: false,
    archive: false,
    date: "Now",
    fav: false,
    convo: [],
  },
  {
    id: 9,
    name: "Kumar Kamal",
    nickname: "",
    theme: "purple",
    chatTheme: "purple",
    date: "Now",
    unread: false,
    archive: false,
    active: true,
    fav: false,
    convo: [],
  },
  {
    id: 10,
    name: "Aaron Nunez",
    nickname: "",
    active: true,
    image: User3,
    chatTheme: "purple",
    unread: false,
    archive: false,
    date: "Now",
    fav: false,
    convo: [],
  },
  {
    id: 11,
    name: "Case Wolf",
    nickname: "",
    theme: "secondary",
    chatTheme: "purple",
    active: true,
    unread: false,
    archive: false,
    date: "Now",
    fav: false,
    convo: [],
  },
  {
    id: 12,
    name: "Amilia Luna",
    nickname: "",
    theme: "info",
    chatTheme: "purple",
    date: "Now",
    unread: false,
    archive: false,
    active: true,
    fav: false,
    convo: [],
  },
];

const sortedDataFunc = (array) => {
  chatData.sort(function (a, b) {
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  });
  return chatData.filter((item) => array.includes(item.name.split("")[0].toUpperCase()) && !item.group);
};

const sortedDataNotFavFunc = (array) => {
  chatData.sort(function (a, b) {
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  });
  return chatData.filter((item) => array.includes(item.name.split("")[0].toUpperCase()) && item.fav === false);
};

export const contacts = [
  {
    id: 1,
    title: "A",
    contacts: sortedDataFunc(["A"]),
  },
  {
    id: 2,
    title: "B",
    contacts: sortedDataFunc(["B"]),
  },
  {
    id: 3,
    title: "C",
    contacts: sortedDataFunc(["C"]),
  },
  {
    id: 3,
    title: "D",
    contacts: sortedDataFunc(["D"]),
  },
  {
    id: 4,
    title: "E-k",
    contacts: sortedDataFunc(["E", "F", "G", "H", "I", "J", "K"]),
  },
  {
    id: 5,
    title: "L-T",
    contacts: sortedDataFunc(["L", "M", "N", "O", "P", "Q", "R", "S", "T"]),
  },
  {
    id: 6,
    title: "U-Z",
    contacts: sortedDataFunc(["U", "V", "W", "X", "Y", "Z"]),
  },
];

export const addUserData = [
  {
    id: 50,
    role: "User",
    name: "Alissa Kate",
    theme: "purple",
  },
  {
    id: 51,
    role: "User",
    name: "Jasper Jordan",
    theme: "orange",
  },
  {
    id: 52,
    role: "User",
    name: "Winter Rays",
    theme: "pink",
  },
];

export const nonFavContacts = [
  {
    id: 1,
    title: "A",
    contacts: sortedDataNotFavFunc(["A"]),
  },
  {
    id: 2,
    title: "B",
    contacts: sortedDataNotFavFunc(["B"]),
  },
  {
    id: 3,
    title: "C",
    contacts: sortedDataNotFavFunc(["C"]),
  },
  {
    id: 3,
    title: "D",
    contacts: sortedDataNotFavFunc(["D"]),
  },
  {
    id: 4,
    title: "E-k",
    contacts: sortedDataNotFavFunc(["E", "F", "G", "H", "I", "J", "K"]),
  },
  {
    id: 5,
    title: "L-T",
    contacts: sortedDataNotFavFunc(["L", "M", "N", "O", "P", "Q", "R", "S", "T"]),
  },
  {
    id: 6,
    title: "U-Z",
    contacts: sortedDataNotFavFunc(["U", "V", "W", "X", "Y", "Z"]),
  },
];
