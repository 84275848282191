import { Navigate } from "react-router-dom";
import SubscriptionManagement from "../panel/copywriter/subscription/SubscriptionManagement";
import { AUTH, LOCAL_STORAGE_ITEMS } from "../../constants";
import { profileType } from "../../config/profile.config";
import { useSelector } from "react-redux";
export default function AdminOutlet() {
  const { auth } = useSelector(state => state.auth)
  const user_type_local = parseInt(localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_TYPE))

  return user_type_local === profileType.Admin || auth[AUTH.STORE.USER]?.user_type === profileType.Admin ? <SubscriptionManagement /> : <Navigate to={`${process.env.PUBLIC_URL}/404-modern`} />;
}
