export const ChatbotsConfig = {
  events: {
    name: "chatbots",
    // refresh-index-data
    refresh: `rid-chatbots`,
    // slideover-right
    sorId: "chatbots",
    sorToggle: "sor-toggle-chatbots",
    // editing-data
    editingData: "edit-chatbots-data",
  },
};
