import dayjs from "dayjs";

export const getUTCAwareTime = (
  time,
  { format = "DD MMM, YY hh:mm:ss a", fallbackText = "--", relative = false, forceUtc = true } = {}
) => {
  // add Z if not present
  if (forceUtc && time && !time.includes("Z")) {
    time += "Z";
  }

  const d = dayjs(time);
  if (!d.isValid()) return fallbackText;

  if (relative) return d.local().fromNow();

  if (format) return d.format(format);

  return d.toISOString();
};
