export const ZapierIntegrationConfig = {
  events: {
    name: "zapier",
    // refresh-index-data
    refresh: `rid-zapier`,
    // slideover-right
    sorId: "zapier",
    sorToggle: "sor-toggle-zapier",
    // editing-data
    editingData: "edit-zapier-data",
  },
};
