import React from "react";
import Chat from "../../../../../pages/app/chat/ChatContainer";
const ViewChatbotConversation = () => {
  return (
    <React.Fragment>
      <Chat />
    </React.Fragment>
  );
};

export default ViewChatbotConversation;
