import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { Card } from "reactstrap";

import { Block } from "../../../../components/Component";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { httpClient } from "../../../../services";
import { CONVERSATION, URLS } from "../../../../constants";
import { useDispatch } from "react-redux";
import { updateConversationData } from "../../../../redux";
import { IntegrationsConfig } from "../../../../config/integrations.config";
import AddEditChatbot from "./AddEditChatbot";
import { consoleLog } from "../../../../utils/Utils";

const Chatbot = () => {
  const { brainId } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(IntegrationsConfig.dataFormats).map((item, index) =>
      httpClient
        .get(URLS.CHATBOT.INTEGRATION_SETTINGS(brainId, item))
        .then((res) => {
          dispatch(
            updateConversationData({
              type: CONVERSATION.STORE.INTEGRATION_DATA(item),
              data: res.data,
            })
          );
        })
        .catch((e) => consoleLog(e, "======er"))
    );
  }, [lastSegment]);

  return (
    <React.Fragment>
      <Head title="Blank"></Head>
      <Content>
        <Block>
          <Card>
            <div className="card-inner" style={{ maxHeight: "85vh", overflow: "scroll" }}>
              <AddEditChatbot />
              <Outlet />
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Chatbot;
