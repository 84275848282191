import { URLS } from "../constants/urls.constant";

export const socketConfig = {
  options: () => ({
    debug: true,
    reconnectInterval: 3000,
    maxReconnectInterval: 30000,
    maxReconnectAttempts: 5,
    timeoutInterval: 5400,
    automaticOpen: true,
  }),
  baseUrl: URLS.ROOT.WS_URL,
  route: {
    documentStatus: "document-status/",
  },
  getRoute({ name }) {
    const baseUrl = URLS.ROOT.WS_URL;

    let route = baseUrl + name;

    return route || false;
  },
};
