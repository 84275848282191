import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Logo from "../logo/Logo";
import { MenuItem } from "../menu/Menu";

import { useTheme } from '../provider/Theme';
import { CHATBOT_OPTIONS } from "../../constants/chatbots.constant";
import { useLocation, useParams } from "react-router";
import { PROFILE_OPTIONS } from "../../constants/profile.constant";
import { SETTINGS_OPTIONS } from "../../constants/settings.constant";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon, LinkList } from "../../components/Component";
import { useSelector } from "react-redux";
import { getWorkspaceData } from "../../redux";
import { URLS } from "../../constants";
import Emitter from "../../services/eventEmitterService";
import { ChatbotsConfig } from "../../config/chatbots.config";
import { httpClient } from "../../services";
import { consoleLog } from "../../utils/Utils";

const Sidebar = ({ fixed, className }) => {
  const theme = useTheme();
  const [resData, setresData] = useState([])
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const workspaceId = useSelector(getWorkspaceData).id;
  const { brainId: brain_id } = useParams();

  useEffect(() => {
    if (workspaceId)
      httpClient
        .get(URLS.CHATBOT.INDEX(workspaceId))
        .then(res => setresData(res.data.data))
        .catch(e =>  consoleLog(e.response))
  }, [workspaceId])

  function add() {
    Emitter.emit(ChatbotsConfig.events.editingData, {});
    dispatchEvent(new Event(ChatbotsConfig.events.sorToggle));
  }

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className,
  });

  const location = useLocation();


  return (
    <>
      <div className={classes} style={{ left: 0, width: 200, paddingTop: 10 }}>
        <div className="nk-sidebar-element nk-sidebar-head" style={{ marginBottom: 40, marginLeft: -10 }}>
          <div className="nk-sidebar-brand">
            <Logo />
          </div>
          {/* <div className="nk-menu-trigger me-n2">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none" icon="arrow-left" click={themeUpdate.sidebarVisibility} />
          </div> */}
        </div>
        {
          location.pathname.includes('chats/') ?
            <Dropdown isOpen={isOpen} className="nk-sidebar-profile" toggle={toggle}>
              <DropdownToggle
                tag="a"
                href="#toggle"
                className="dropdown-toggle d-flex align-items-center w-100 justify-between border border-light round-lg ps-2 pe-1 py-0 mx-3 mb-2"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {
                  resData?.length ?
                    <>
                      <div>
                        <h6 className="text-wrap">{resData.filter(i => i.id === brain_id)[0].name}</h6>
                      </div>
                      <div
                        className="mt-1"
                      >
                        <Icon name="unfold-more fs-3" />
                      </div>
                    </>
                    : <div
                      style={{ height: 35 }} //maintain empty space inside toggle section
                    />
                }
              </DropdownToggle>
              <DropdownMenu end style={{ maxWidth: 180, left: 0 }}>
                <div className="dropdown-inner bg-white d-none d-md-block">
                  <LinkList>
                    {
                      resData && resData.map((item, index) =>
                        <li
                          className="link-list link-list-opt dropdown-link"
                          onClick={() => {
                            consoleLog(window.location.pathname.slice(44))
                            if (!location.pathname.includes(item.id)) {
                              window.location.pathname = `/chats/${item.id}/${window.location.pathname.slice(44)}`
                            }
                            // toggle();
                          }}
                          key={index}
                        >
                          <strong
                            className="d-flex flex-row"
                          >
                            {
                              brain_id === item.id ?
                                <Icon name="check" className="pe-1 fs-6" />
                                : <div style={{ width: 27 }} />
                            }
                            <span>{item.name}</span>
                          </strong>
                        </li>
                      )
                    }
                    <li
                      className="link-list link-list-opt dropdown-link link-bdr-t"
                      onClick={() => {
                        toggle();
                        add()
                      }}
                    >
                      <strong>
                        <Icon name="plus-circle" className="pe-1 fs-6" /> <span>New Chatbot</span>
                      </strong>
                    </li>
                  </LinkList>
                </div>
              </DropdownMenu>
            </Dropdown>
            : null
        }

        <ul className="nk-menu">
          {
            location.pathname.includes('chats/') ? CHATBOT_OPTIONS
              .map((item, index) =>
                <MenuItem
                  link={`/chats/${brain_id}/${item.id}`}
                  text={item.text}
                  key={index}
                  newTab={false}
                  sidebarToggle={_ => { }}
                  mobileView={false}
                />
              )
              : location.pathname.includes('profile') ?
                PROFILE_OPTIONS
                  .map((item, index) =>
                    <MenuItem
                      link={`/profile?tab=${item.id}`}
                      text={item.text}
                      key={index}
                      newTab={false}
                      sidebarToggle={_ => { }}
                      mobileView={false}
                    />
                  )
                : location.pathname.includes('settings') ?
                  SETTINGS_OPTIONS
                    .map((item, index) =>
                      <MenuItem
                        text={item.text}
                        link={`/settings?tab=${item.id}`}
                        key={index}
                        newTab={false}
                        sidebarToggle={_ => { }}
                        mobileView={false}
                      />
                    )
                  : null
          }
        </ul>
      </div>
      {/* {theme.sidebarVisibility && <div
        onClick={themeUpdate.sidebarVisibility}
        className="nk-sidebar-overlay"></div>} */}
    </>
  );
};
export default Sidebar;
