import React, { useEffect, useRef, useState } from "react";
import Head from "../../../../../layout/head/Head";
import { Col, Icon, Row } from "../../../../../components/Component";
import TrainUploadItem from "../components/train/TrainUploadItem";
import AddEditTrainDocument from "../components/train/AddEditTrainDocument";
import AddEditTrainText from "../components/train/AddEditTrainText";
import AddEditTrainWeb from "../components/train/AddEditTrainWeb";
import AddEditExternalSource from "../components/train/AddEditExternalSource";
import { XTable, XTableRow, XTableRowItem } from "../../../../../components/xtable";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { fetchData, getData, store, updateElement } from "../../../../../redux";
import { TOAST, URLS, XTABLE } from "../../../../../constants";
import { Badge } from "reactstrap";
import TimeAgo from "timeago-react";
import { getUTCAwareTime } from "../../../../../utils/datetime/getUTCAwareTime";
import { formBody, httpClient } from "../../../../../services";
import { notify } from "../../../../../services/toastService";
import { rwsClient } from "../../../../../services/rwsClient";
import { socketConfig } from "../../../../../config/socket.config";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { consoleLog } from "../../../../../utils/Utils";

const ViewChatbotTrain = () => {
  const { brainId } = useParams();
  const [rws, setRws] = useState(null);
  const resData = useSelector(getData);
  const resDataRef = useRef(null);

  const [isFetchDataFromExternalSource, setDataFromExternalSourceStatus] = useState(null);
  const [trainedContentTableHeaders, settrainedContentTableHeaders] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFetchDataFromExternalSource === true) {
      settrainedContentTableHeaders([
        { text: "Database Name", width: "19%", sort: null },
        { text: "Host", width: "19%", sort: null },
        { text: "Port", width: "10%", sort: null },
        { text: "Table Name", width: "28%", sort: null },
        { text: "Row Count", width: "19%", sort: null },
        { text: "", width: "5%", sort: null },
      ]);
    }

    if (isFetchDataFromExternalSource === false) {
      settrainedContentTableHeaders([
        { text: "Source", width: "60%", sort: null },
        { text: "Type", width: "7%", sort: null },
        { text: "Last trained at", width: "13%", sort: null },
        { text: "Status", width: "10%", sort: null },
        { text: "", width: "5%", sort: null },
      ]);
    }
  }, [isFetchDataFromExternalSource]);

  const badgeStatus = {
    completed: "Trained",
    pending: "Pending",
    failed: "Failed",
  };
  const badgeStatusColor = {
    completed: "success",
    pending: "warning",
    failed: "danger",
  };
  const badgeFileTypes = {
    pdf: "PDF",
    html: "HTML",
    txt: "TXT",
    csv: "CSV",
  };
  const badgeFileTypesColor = {
    pdf: "primary",
    html: "info",
    txt: "secondary",
    csv: "success",
  };

  useEffect(() => {
    openSocketListener();
    return () => {
      closeSocketListener();
    };
  }, []);

  useEffect(() => {
    if (brainId) {
      httpClient.get(URLS.CHATBOT.DETAILS(brainId)).then((res) => {
        setDataFromExternalSourceStatus(res.data.fetch_data_from_external_source_enabled);
      });
    }
  }, [brainId]);

  useEffect(() => {
    resDataRef.current = resData;
  }, [resData]);

  async function openSocketListener() {
    await rwsClient
      .openRWS({
        route: socketConfig.route.documentStatus,
        onMessage: async ({ data }) => {
          const parsedRawData = JSON.parse(data);
          const parsedMessageData = JSON.parse(parsedRawData.message);
          consoleLog("openListener-data", parsedMessageData.document);
          updateObjectById(parsedMessageData.document.id, {
            progressStatus: parsedMessageData.document.progressStatus,
          });
        },
      })
      .then(async (rws) => {
        setRws(rws);
        consoleLog("openListener-rws", rws);
      })
      .catch((err) => {
        consoleLog("openListener-err", { err });
      });
  }

  function closeSocketListener() {
    rwsClient.closeRWS(rws).then(() => {
      setRws(null);
    });
  }

  function updateObjectById(id, newData) {
    const index = findIndexById(resDataRef.current, id);
    if (index > -1) {
      dispatch(updateElement({ index: index, key: "progressStatus", value: newData.progressStatus }));
    }
  }

  function findIndexById(arrayOfObjects, targetId) {
    const index = arrayOfObjects.findIndex((obj) => obj.id === targetId);
    return index;
  }

  function getTrainedSource(type, data) {
    if (type === "file") {
      return data.uploadedFileName;
    }
    if (type === "web") {
      return data.web_url;
    }
  }

  function handleDeleteContent(data) {
    httpClient
      .delete(URLS.CHATBOT.TRAIN.DELETE_UPLOAD_CONTENT(data.id))
      .then((res) => {
        consoleLog("train-upload-delete-res", res);
        store.dispatch(fetchData());
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("train-upload-delete-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }

  function handleDeleteSource(data) {
    httpClient
      .delete(URLS.CHATBOT.TRAIN.DELETE_EXTERNAL_SOURCE(data.id))
      .then((res) => {
        consoleLog("train-external-source-delete-res", res);
        store.dispatch(fetchData());
        notify(TOAST.TYPE.SUCCESS, { title: "Success" });
      })
      .catch((err) => {
        consoleLog("train-external-source-delete-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      })
      .finally(() => {
        store.dispatch(fetchData());
      });
  }

  function handleSyncContent(data) {
    if (data.progressStatus === "pending") return;
    httpClient
      .post(URLS.CHATBOT.TRAIN.DOC_SYNC, formBody({ document_id: data.id }))
      .then((res) => {})
      .catch((err) => {
        consoleLog("doc-sync-delete-err", { err });
        notify(TOAST.TYPE.ERROR, {
          title: `${err.response.data.error.code} [${err.response.status}]`,
          message: `${err.response.data.error.message}`,
        });
      });
  }
  return (
    <React.Fragment>
      <Head title="Train"></Head>
      <div>
        <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
          Knowledge
        </p>
        <p className="text-xs text-gray-500"> Add knowledge sources that your chatbot can use to answer questions.</p>
        <AddEditTrainDocument />
        <AddEditTrainText />
        <AddEditTrainWeb />
        <AddEditExternalSource />
        <div>
          {isFetchDataFromExternalSource ? (
            <section>
              <Row>
                <Col lg={12} className="mb-4">
                  <TrainUploadItem
                    iconClass="server"
                    cardTitle="From External Data Source"
                    cardText="Database"
                    type="external-source"
                  />
                </Col>
              </Row>
            </section>
          ) : (
            isFetchDataFromExternalSource !== null && (
              <section>
                <Row>
                  <Col lg={12} className="mb-4">
                    <TrainUploadItem
                      iconClass="upload"
                      cardTitle="From Document"
                      cardText="Files supported: TXT, PDF, CSV"
                      type="document"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mb-4">
                    <TrainUploadItem
                      iconClass="edit-alt"
                      cardTitle="From Text"
                      cardText="Type or paste your content"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mb-4">
                    <TrainUploadItem
                      iconClass="download-cloud"
                      cardTitle="From Website"
                      cardText="Type or paste your website for scraping"
                      type="web"
                    />
                  </Col>
                </Row>
              </section>
            )
          )}
        </div>
      </div>
      <div className="mt-5">
        <div className="mb-4">
          <p className=" mb-0.5" style={{ fontSize: "1.375rem", color: "#364a63" }}>
            Trained Content
          </p>
          <p className="text-xs text-gray-500">
            The sources of information that your chatbot can use to answer questions.
          </p>
        </div>
        <div>
          {isFetchDataFromExternalSource !== null && (
            <XTable
              xtId="trained-content"
              headers={trainedContentTableHeaders}
              endpoint={
                isFetchDataFromExternalSource
                  ? `${URLS.CHATBOT.TRAIN.EXTERNAL_SOURCE_INDEX}?brain=${brainId}`
                  : `${URLS.CHATBOT.TRAIN.INDEX}?brain_id=${brainId}`
              }
              qso={{ [XTABLE.STORE.QSO.VALUE.PREPEND]: "&", [XTABLE.STORE.QSO.VALUE.APPEND]: "" }}
              wrapperClass="lead-list-wrapper"
            >
              {resData &&
                resData.map((item) => {
                  return isFetchDataFromExternalSource ? (
                    <XTableRow key={item.id}>
                      <XTableRowItem>{item.database_name}</XTableRowItem>
                      <XTableRowItem>{item.host}</XTableRowItem>
                      <XTableRowItem>{item.port}</XTableRowItem>
                      <XTableRowItem>{item.selected_table_name}</XTableRowItem>
                      <XTableRowItem>{item.selected_row_count}</XTableRowItem>
                      <XTableRowItem>
                        <div className="flex gap-1 items-center justify-end">
                          <strong
                            style={{
                              color: "#733aea",
                            }}
                            className="link cursor-pointer fs-10"
                          >
                            <Icon
                              name="trash"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleDeleteSource(item);
                              }}
                            />
                          </strong>
                        </div>
                      </XTableRowItem>
                    </XTableRow>
                  ) : (
                    <XTableRow key={item.id}>
                      <XTableRowItem>
                        <p>
                          <strong>{item.title}</strong>
                        </p>
                        <p className="text-xs text-gray-400">{getTrainedSource(item.document_type, item)}</p>
                      </XTableRowItem>
                      <XTableRowItem>
                        <Badge color={`outline-${badgeFileTypesColor[item.type]}`}>{badgeFileTypes[item.type]}</Badge>
                      </XTableRowItem>
                      <XTableRowItem>
                        <TimeAgo datetime={getUTCAwareTime(item.createdAt)} opts={{ minInterval: 60 }} />
                      </XTableRowItem>
                      <XTableRowItem>
                        <Badge className="badge-dim" color={badgeStatusColor[item.progressStatus]}>
                          {badgeStatus[item.progressStatus]}
                        </Badge>
                      </XTableRowItem>

                      <XTableRowItem>
                        <div className="flex gap-1 items-center justify-end">
                          {item.type === "html" && (
                            <FontAwesomeIcon
                              icon={faRotate}
                              size="base"
                              className={`  ${
                                item.progressStatus === "pending"
                                  ? "cursor-not-allowed text-gray-400"
                                  : "cursor-pointer text-accent"
                              }`}
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleSyncContent(item);
                              }}
                              spin={item.progressStatus === "pending" ? true : false}
                            />
                          )}
                          <strong
                            style={{
                              color: "#733aea",
                            }}
                            className="link cursor-pointer fs-10"
                          >
                            <Icon
                              name="trash"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleDeleteContent(item);
                              }}
                            />
                          </strong>
                        </div>
                      </XTableRowItem>
                    </XTableRow>
                  );
                })}
            </XTable>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewChatbotTrain;
