import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, BlockDes, Col, Row } from "../../../components/Component";
import { Badge, Card } from "reactstrap";
import Switch from "../../../components/Switch/Switch";
import { httpClient } from "../../../services/httpClient";
import { AUTH, LOCAL_STORAGE_ITEMS, URLS } from "../../../constants";
import { useDispatch } from "react-redux";
import { setsubscriptionData } from "../../../redux/slices/subscription.store";
import { SUBSCRIPTION } from "../../../constants/subscription.constant";
import { useSelector } from "react-redux";
import { formBody } from "../../../services/convertFormData";
import { setAuthData } from "../../../redux/slices/auth.store";
import { consoleLog } from "../../../utils/Utils";

const PricingPlans = () => {
    const [isMonthly, setIsMonthly] = useState(true)
    const dispatch = useDispatch()

    const { subscription, auth: { auth } } = useSelector(state => state)

    const subscribeToPlan = (subscription_plan_pricing) => {
        httpClient.post(URLS.SUBSCRIPTION_PLAN.SUBSCRIBE_TO_PLAN, formBody({
            subscription_plan_pricing: subscription_plan_pricing.id,
            success_url: window.location.href
        }))
            .then(res => {
                window.location.replace(res.data.checkout_url)
            })
            .catch(e => consoleLog(e.response) )
    }

    useEffect(() => {
        httpClient.get(URLS.SUBSCRIPTION_PLAN.GET_DEFAULT_PLAN)
            .then(res => {
                dispatch(setsubscriptionData({ key: SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION, value: res.data }))
            })
            .catch(e => { })
    }, [])

    useEffect(_ => {
        httpClient.get(URLS.AUTH.PROFILE)
            .then(res => {
                dispatch(setAuthData({ key: AUTH.STORE.USER, value: res.data }))
                localStorage.setItem(LOCAL_STORAGE_ITEMS.USER_TYPE, res.data.user_type)
            })
    }, [])

    useEffect(() => {
        httpClient.get(URLS.SUBSCRIPTION_PLAN.SUBSCRIPTION_LIST_FILTERED(isMonthly ? 0 : 1))
            .then(res => {
                dispatch(setsubscriptionData({ key: SUBSCRIPTION.STORE.SUBSCRIPTION_LIST, value: res.data.data }))
            })
            .catch(e => { })
    }, [isMonthly])
    return (
        <React.Fragment>
            <Head title="Pricing Plans"></Head>
            <Content>
                <BlockHead size="sm">
                    <div className="nk-block-head-sub"><span></span></div>
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle className="nk-block-title page-title">Pricing Plans</BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Switch
                    isOn={!isMonthly}
                    handleToggle={_ => setIsMonthly(m => !m)}
                />
                <Block>
                    <Row className="g-gs">
                        <Col sm="6" lg="4">
                            <Card className="pricing">
                                <div className="p-4">
                                    <div className="pricing-title">
                                        <h4 className="card-title title">{subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].name}</h4>
                                        {/* <p className="sub-text">Limited Words.</p> */}
                                    </div>
                                    <div className="pricing-price">
                                        <h3 className="display-5">Free <span className="caption-text text-base fw-normal"> / forever</span></h3>
                                    </div>
                                </div>
                                <div className="px-4">
                                    <button className="btn btn-outline-light btn-block">
                                        {
                                            auth[AUTH.STORE.USER]?.subscription?.subscription_plan.is_default ?
                                                "Current Plan"
                                                : "Downgrade"
                                        }
                                    </button>
                                </div>
                                <div className="p-4">
                                    <ul className="pricing-features">
                                        {
                                            Object.keys(subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION]).filter(item => item.startsWith('number_of_')).map(d =>
                                                <li key={d}><em className="icon text-primary ni ni-check-circle"></em><span>{subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION][d]} {d.split('number_of_')[1].replaceAll('_', ' ')}</span></li>

                                            )
                                        }
                                        <li ><em className="icon text-primary ni ni-check-circle"></em><span>{subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].support_priority_type_display_text} Support</span></li>
                                        {
                                            subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].train_with_website_crawling_enabled ?
                                                <li ><em className="icon text-primary ni ni-check-circle"></em><span>Website Crawling Training</span></li>
                                                : null
                                        }
                                        {
                                            subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].chat_widget_customization_enabled ?
                                                <li ><em className="icon text-primary ni ni-check-circle"></em><span>Chat Widget Customization</span></li>
                                                : null
                                        }
                                        {
                                            subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].branding_removal_enabled ?
                                                <li ><em className="icon text-primary ni ni-check-circle"></em><span>Branding Removal</span></li>
                                                : null
                                        }
                                        {
                                            subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].custom_domain_and_white_labeling_enabled ?
                                                <li ><em className="icon text-primary ni ni-check-circle"></em><span>Custom Domain and White-Labeling</span></li>
                                                : null
                                        }
                                        {
                                            subscription[SUBSCRIPTION.STORE.DEFAULT_SUBSCRIPTION].email_and_chat_support_enabled ?
                                                <li ><em className="icon text-primary ni ni-check-circle"></em><span>Email and Chat Support</span></li>
                                                : null
                                        }
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        {
                            subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].map((subItem, index) =>
                                <Col key={index} sm="6" lg="4">
                                    <Card className="pricing">
                                        <div className="p-4">
                                            <div className="pricing-title">
                                                <div
                                                    className="d-flex flex-row align-center"
                                                >
                                                    <h4 className="card-title title">{subItem.subscription_plan.name}</h4>
                                                    <Badge pill color="primary" style={{
                                                        marginBottom: 5,
                                                        marginLeft: 20
                                                    }} className="badge-dim">
                                                        {
                                                            subItem.percentage_discount
                                                        }% discount
                                                    </Badge>
                                                </div>
                                                {/* <p className="sub-text">Unlimited Words.</p> */}
                                            </div>
                                            <div className="pricing-price">
                                                <h3 className="display-5">
                                                    <span style={{ color: 'GrayText', marginRight: 10 }} className="fs-22px del">${parseInt(subItem.amount)}</span>

                                                    ${parseInt(subItem.discounted_amount)} <span className="caption-text text-base fw-normal"> / {
                                                        isMonthly ?
                                                            "month" : 'year'
                                                    }</span></h3>
                                            </div>
                                        </div>
                                        <div className="px-4">
                                            <button
                                                onClick={_ => subscribeToPlan(subItem)}
                                                className={
                                                    auth[AUTH.STORE.USER]?.subscription?.subscription_plan.is_default || subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) < 0 || subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) > index || (subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) === index && auth[AUTH.STORE.USER]?.subscription?.subscription_plan.billing_interval == subItem.billing_interval) ?
                                                        "btn btn-outline-light btn-block"
                                                        : "btn btn-primary btn-block"
                                                }
                                            >{
                                                    //1. if user is under default/free plan or 2. user's subscription plan id not found in the list or 3. current subscription plan item is lower than the type of user's subscribed plan
                                                    auth[AUTH.STORE.USER]?.subscription?.subscription_plan.is_default || subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) < 0 || subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) < index ?
                                                        "Upgrade Now"
                                                        // current item's id is same as user's subscribed plan id
                                                        : subscription[SUBSCRIPTION.STORE.SUBSCRIPTION_LIST].findIndex(item => item.subscription_plan.id === auth[AUTH.STORE.USER]?.subscription?.subscription_plan.id) === index ?
                                                            // user is subscribed to current item and user's billing interval is same as current item's billing interval
                                                            auth[AUTH.STORE.USER]?.subscription?.subscription_plan.billing_interval === subItem.billing_interval ?
                                                                "Current Plan"
                                                                // user is subscribed to current but user's billing interval is NOT same as current item's billing interval
                                                                : subItem.billing_interval ? "Switch to yearly" : "Switch to monthly"
                                                            : "Downgrade"
                                                }</button>
                                        </div>
                                        <div className="p-4">
                                            <ul className="pricing-features">
                                                {
                                                    Object.keys(subItem.subscription_plan).filter(item => item.startsWith('number_of_')).map(d =>
                                                        <li key={d}><em className="icon text-primary ni ni-check-circle-fill"></em><span>{subItem.subscription_plan[d]} {d.split('number_of_')[1].replaceAll('_', ' ')}</span></li>
                                                    )
                                                }
                                                <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>{subItem.subscription_plan.support_priority_type_display_text} Support</span></li>
                                                {
                                                    subItem.subscription_plan.train_with_website_crawling_enabled ?
                                                        <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>Website Crawling Training</span></li>
                                                        : null
                                                }
                                                {
                                                    subItem.subscription_plan.chat_widget_customization_enabled ?
                                                        <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>Chat Widget Customization</span></li>
                                                        : null
                                                }
                                                {
                                                    subItem.subscription_plan.branding_removal_enabled ?
                                                        <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>Branding Removal</span></li>
                                                        : null
                                                }
                                                {
                                                    subItem.subscription_plan.custom_domain_and_white_labeling_enabled ?
                                                        <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>Custom Domain and White-Labeling</span></li>
                                                        : null
                                                }
                                                {
                                                    subItem.subscription_plan.email_and_chat_support_enabled ?
                                                        <li ><em className="icon text-primary ni ni-check-circle-fill"></em><span>Email and Chat Support</span></li>
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        }
                        {/* <Col sm="6" lg="4">
                            <Card className="pricing">
                                <div className="p-4">
                                    <div className="pricing-title">
                                        <h4 className="card-title title">Professional</h4>
                                        <p className="sub-text">Unlimited Words.</p>
                                    </div>
                                    <div className="pricing-price">
                                        <h3 className="display-5">$48 <span className="caption-text text-base fw-normal"> / month</span></h3>
                                    </div>
                                </div>
                                <div className="px-4">
                                    <button className="btn btn-primary btn-block">Upgrade Now</button>
                                </div>
                                <div className="p-4">
                                    <ul className="pricing-features">
                                        <li><em className="icon text-primary ni ni-check-circle-fill"></em><span>Unlimited words generation</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle-fill"></em><span>Access to all templates for free</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle-fill"></em><span>No project limitation</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle-fill"></em><span>30 days of AI generation history</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle-fill"></em><span>Wordpress plugin integration</span></li>
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="6" lg="4">
                            <Card className="pricing">
                                <div className="p-4">
                                    <div className="pricing-title">
                                        <h4 className="card-title title">Enterprise</h4>
                                        <p className="sub-text">Customized Plan.</p>
                                    </div>
                                    <div className="pricing-price">
                                        <h3 className="display-5">$225 <span className="caption-text text-base fw-normal"> / month</span></h3>
                                    </div>
                                </div>
                                <div className="px-4">
                                    <button className="btn btn-outline-light btn-block">Upgrade Now</button>
                                </div>
                                <div className="p-4">
                                    <ul className="pricing-features">
                                        <li><em className="icon text-primary ni ni-check-circle"></em><span>Dedicated Account Manager</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle"></em><span>Custom Tools</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle"></em><span>Advanced Integrations</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle"></em><span>Premium Technical Support</span></li>
                                        <li><em className="icon text-primary ni ni-check-circle"></em><span>Many More Special Features</span></li>
                                    </ul>
                                </div>
                            </Card>
                        </Col> */}
                    </Row>
                    {/* <div className="mt-5">
                        <h5>Want to learn more about our pricing &amp; plans?</h5>
                        <p>Read our <a href="{{URL}}pricing-plans.html">Plans</a>, <a href="#">Billing &amp; Payment</a>, <a href="#">FAQs</a> to learn more about our service.</p>
                    </div> */}
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default PricingPlans;
